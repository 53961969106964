import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { courseEndpoint } from 'utils/api';
import StarIcon from '@material-ui/icons/Star';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editDraftBundle } from '../../redux/actions/bundles/fetchBundleActions';
import { editDraftCourse } from '../../redux/actions/courses/fetchCourseActions';
import placeholder from '../../assets/images/placeholder.png';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    marginTop: 40,
    marginLeft: 20,
  },
  paper: {
    maxHeight: 69,
    minHeight: 69,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: 10,
    color: '#4B4B4B',
    boxShadow: '0px 3px 10px #00000017',
  },
  paperNullReviews: {
    height: '140px',
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: 10,
    color: '#4B4B4B',
    boxShadow: '0px 3px 10px #00000017',
    marginTop: 10,
  },
  nullReviews: {
    color: '#124E6193',
    minHeight: '170px',
    marginTop: 40,
    textAlign: 'center',
  },
  title: {
    fontSize: 17,
    color: '#033342',
    paddingBottom: 10,
  },
  titleDrafts: {
    paddingTop: 20,
    fontSize: 17,
    paddingBottom: 10,
    color: '#033342',
  },
  starIcon: {
    fontSize: 12,
  },
  avgRating: {
    float: 'right',
    marginLeft: theme.spacing(2),
    fontSize: 15,
    display: 'inline',
    // backgroundColor: '#E6F1F4',
    color: '#087090',
    // background: '#E6F1F4 0% 0% no-repeat padding-box',
    borderRadius: 10,
    width: 'fit-content',
    padding: '2px 8px',
  },
  textEdit: {
    float: 'right',
    textDecoration: 'underline',
    color: '#087090',
    fontWeight: 'bold',
    fontSize: 11,
    backgroundColor: 'white',
  },
  review: {
    fontSize: 15,
    color: '#4B4B4B',
  },
  courseTitle: {
    fontSize: 14,
    color: '#087090!important',
  },
  category: {
    fontSize: 12,
    color: '#4B4B4B',
    paddingTop: 4,
  },
  img: {
    width: 60,
    height: 60,
    borderRadius: 10,
  },
}));

export default function Reviews() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [courses, setCourses] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [draftCourse, setDraftCourse] = useState([]);
  //const [draftBundle, setDraftBundle] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const courseData = await (await courseEndpoint.get('course/')).data.data;
      const ratingData = await (await courseEndpoint.get('course-rating/')).data.data;
      const draftCourse = await (await courseEndpoint.get('course/draft')).data.data;
      //const draftBundle = await (await courseEndpoint.get('bundle/draft')).data.data;

      setDraftCourse(draftCourse);
      //setDraftBundle(draftBundle)
      setCourses(courseData);
      setRatings(ratingData);
    }
    fetchData();
  }, []);

  const editedCourse = (id) => {
    dispatch(editDraftCourse(id));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Latest Reviews
      </Typography>
      {ratings.length > 0 ? (
        ratings.slice(0, 3).map((rating, i) => (
          <Paper key={i} className={classes.paper} elevation={0}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs zeroMinWidth>
                <Typography noWrap className={classes.courseTitle}>
                  {courses.map((course, i) => {
                    if (rating.course === course.id) {
                      return <span key={i}>{course.title}</span>;
                    }
                  })}
                </Typography>
                <Typography noWrap className={classes.review}>
                  {rating.text}
                  <span className={classes.avgRating}>
                    <StarIcon className={classes.starIcon} />
                    {rating.rating}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ))
      ) : (
        <Paper className={classes.paperNullReviews} elevation={0}>
          <Typography variant="h4" className={classes.nullReviews}>
            No Reviews
          </Typography>
        </Paper>
      )}
      <Typography variant="h3" className={classes.titleDrafts}>
        Recent Draft
      </Typography>
      {draftCourse.length > 0 ? (
        <Paper className={classes.paper} elevation={0}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs>
              <img
                alt=""
                className={classes.img}
                src={
                  draftCourse.length > 0 && draftCourse[0].promo_image !== null
                    ? draftCourse[0].promo_image
                    : placeholder
                }
              />
            </Grid>
            <Grid item xs>
              <Typography className={classes.courseTitle}>
                {draftCourse.length > 0 ? draftCourse[0].title : null}
              </Typography>
              <Typography className={classes.category}>
                {' '}
                {draftCourse.length > 0 ? draftCourse[0].title : null}
                <Button
                  className={classes.textEdit}
                  onClick={() => {
                    editedCourse(draftCourse[0].id);
                  }}
                >
                  Continue editing
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper className={classes.paperNullReviews} elevation={0}>
          <Grid item xs>
            <Typography variant="h4" className={classes.nullReviews}>
              No Drafts
            </Typography>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
