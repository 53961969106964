import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  link: {
    fontWeight: '600',
    color: '#087090',
    textDecoration: 'none',
    marginTop: theme.spacing(5),
  },
  divider: {
    marginBottom: theme.spacing(5),
  },
}));

const ViewAllLink = ({link}) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.root} variant="body2" align="right">
        <Link className={classes.link} color="inherit" to={link}>
          View All
        </Link>
      </Typography>
      <div className={classes.divider}>
        <Divider />
      </div>
    </div>
  );
};

export default ViewAllLink;
