import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { creatorEndpoint } from 'utils/api';
import { deleteCreator, editCreator } from 'redux/actions/creators/fetchCreatorActions';
import { useDispatch } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DeleteConfirmation from 'components/Modals/ConfirmModal';

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        fontFamily: 'Poppins',
        paper: {
          boxShadow: '0px 3px 10px #00000017',
          borderRadius: '20px',
          marginTop: 10,
          paddingLeft: 20,
          paddingRight: 10,
          fontFamily: 'Poppins',
        },
      },
      MuiToolbar: {
        regular: {
          // backgroundColor: '#F5F7F7'
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: '1px solid #ECEFEF',
          padding: 8,
        },
        body: {
          whiteSpace: 'nowrap',
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          display: 'none',
        },
        fixedLeft: {
          display: 'none',
        },
      },
      MUIDataTableHeadCell: {
        data: {
          color: '#4B4B4B',
          opacity: 0.65,
          fontSize: 14,
          fontWeight: 'bold',
          fontFamily: 'Poppins',
        },
        fixedHeader: {
          // backgroundColor: '#F5F7F7',
          border: 'none',
        },
      },
    },
  });

export default function CreatorsList() {
  const [creators, setCreators] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const getCreators = async () => {
    await creatorEndpoint
      .get('content-creator/')
      .then((response) => {
        const allCreators = response.data.data;
        setCreators(allCreators);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  const editedCreator = (id) => {
    dispatch(editCreator(id));
  };

  useEffect(() => {
    getCreators();
  }, []);

  const columns = ['ID', 'Username', 'First Name', 'Last Name', 'Details', 'Email', 'Actions'];

  const options = {
    filter: true,
    filterType: 'dropdown',
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [id, setId] = useState('');

  const submitDelete = () => {
    dispatch(deleteCreator(id));
    setDisplayConfirmationModal(false);
    history.push('/creators');
  };
  const showDeleteModal = (id) => {
    setId(id);
    setDisplayConfirmationModal(true);
  };
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          creators={creators}
          data={creators.map((creator) => {
            const { id, user, details } = creator;
            return [
              id,
              user.username,
              user.first_name,
              user.last_name,
              details.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ''),
              user.email,
              <>
                <Button>
                  <FaEdit
                    color="#087090"
                    size={18}
                    onClick={() => {
                      editedCreator(id);
                    }}
                  />
                </Button>
                <Button>
                  <RiDeleteBin6Fill color="#EE9292" size={18} onClick={() => showDeleteModal(id)} />
                </Button>
              </>,
            ];
          })}
          columns={columns}
          options={options}
        />
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
}
