import {
    FETCH_PROMOS_START,
    FETCH_PROMOS_SUCCESS,
    FETCH_PROMOS_FAILURE,
  } from 'redux/types';

  export default (state = null, action) => {
    switch (action.type) {
      case FETCH_PROMOS_START:
        return {
          ...state,
          isLoading: true,
        };
      case FETCH_PROMOS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          promos: action,
        };
      case FETCH_PROMOS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };

