import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
  },
  title: {
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
}));


const SimpleWidget = (props) => {
  const { children, title } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card} elevated={1}>
      <Typography className={classes.title} variant="h2" gutterBottom>
        {title}
      </Typography>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
};

export default SimpleWidget;
