import { SET_CURRENT_USER } from 'redux/types';


export const logoutAction = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  dispatch({
    type: SET_CURRENT_USER,
    payload: null,
    account: null,
    tokenExp: null,
  });

};
