import {
    FETCH_ADMINS_START,
    FETCH_ADMINS_SUCCESS,
    FETCH_ADMINS_FAILURE,
  } from 'redux/types';

  export default (state = null, action) => {
    switch (action.type) {
      case FETCH_ADMINS_START:
        return {
          ...state,
          isLoading: true,
        };
      case FETCH_ADMINS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          admins: action,
        };
      case FETCH_ADMINS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };

