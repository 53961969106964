import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../courseFormStyle';


const LevelsHeading = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid className={classes.levelListHeadings} item sm={11}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Typography>
              Title
            </Typography>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography>
              Files
            </Typography>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography>
              Price
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LevelsHeading;
