import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AiFillExclamationCircle } from 'react-icons/ai';

export default function DeleteConfirmation({ showModal, hideModal, confirmModal, id }) {
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={showModal}
        onClose={hideModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <AiFillExclamationCircle />
          Are you sure you want to permanently remove this item?
        </DialogTitle>
        <DialogActions>
          <Button onClick={hideModal} color="primary">
            Cancel
          </Button>
          <Button onClick={() => confirmModal(id)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
