import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  formSubtitle: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 30,
  },
  btn: {
    padding: 10,
    float: 'right',
    borderRadius: 10,
    width: 161,
    height: 45,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      marginTop: 5,
    },
  },
}));

const Header = ({ title, buttonText, to }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.formSubtitle} variant="h4">
      {title}
      <span>
        <Button size="large" className={classes.btn} component={Link} to={to}>
          {buttonText}
        </Button>
      </span>
    </Typography>
  );
};

export default Header;
