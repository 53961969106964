import React, { useState } from 'react';
import { Grid, Typography, Radio } from '@material-ui/core';
import useStyles from '../courseFormStyle';
import CustomTextField from './CustomTextField';
import Content from './Content';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const LevelDetails = (props) => {
  const classes = useStyles();

  const {
    levels,
    level,
    handleDetailsChange,
    content,
    handleContentChange,
    edit,
    open,
    setContent,
    errorInpDuration,
    errorShDescription,
    errorLevelPrice,
    setChangedReps,
    reps,
  } = props;
  const [addDiscount, setAddDiscount] = useState('no');
  console.log('level ==<', level);

  const handleChangeEditorLD = (event, editor, level) => {
    const data = editor.getData();
    console.log('DATA', data);
    console.log(level);
    handleDetailsChange(data, 'description', level);
  };

  console.log('EIP', errorInpDuration);

  return (
    <div>
      {open && (
        <Grid container spacing={2}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Typography className={classes.title}>Level Details</Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              <CustomTextField
                value={level.duration}
                onChange={(event) => handleDetailsChange(event.target.value, 'duration', level)}
                placeholder="Input Duration"
                error={level.duration === '' ? errorInpDuration : false}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <CustomTextField
                value={level['price']}
                onChange={(event) => handleDetailsChange(event.target.value, 'price', level)}
                placeholder="Level Price"
                type="number"
                error={level.price === null ? errorLevelPrice : false}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <Typography className={classes.labels1} variant="body1">
                Add a discount
              </Typography>
              Yes
              <Radio
                checked={addDiscount === 'yes'}
                onChange={(v) => setAddDiscount(v.target.value)}
                value="yes"
                inputProps={{ 'aria-label': 'yes' }}
                checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
              />
              No
              <Radio
                checked={addDiscount === 'no'}
                onChange={(v) => setAddDiscount(v.target.value)}
                value="no"
                inputProps={{ 'aria-label': 'no' }}
                checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              {addDiscount === 'yes' ? (
                <CustomTextField
                  value={level['discount']}
                  onChange={(event) => handleDetailsChange(event.target.value, 'discount', level)}
                  placeholder="Discount Percentage"
                  type="number"
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {level.description === '' && errorShDescription ? (
                <div>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                        writer.setStyle(
                          'border-color',
                          'red',
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    config={{ placeholder: 'Short Description' }}
                    data={level['description']}
                    onChange={(e, editor) => handleChangeEditorLD(e, editor, level)}
                  />
                </div>
              ) : (
                <CKEditor
                  editor={ClassicEditor}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle('height', '150px', editor.editing.view.document.getRoot());
                    });
                  }}
                  config={{ placeholder: 'Short Description' }}
                  data={level['description']}
                  onChange={(e, editor) => handleChangeEditorLD(e, editor, level)}
                />
              )}
              {/* <CustomTextField
                value={level['description']}
                onChange={(event) => handleDetailsChange(event.target.value, 'description', level)}
                style={{ width: '100%', maxWidth: 400 }}
                placeholder="Short Description"
                error={level.description === '' ? errorShDescription : false}
              /> */}
            </Grid>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Typography className={classes.title}>Add Lesson Files</Typography>
          </Grid>

          <Content
            reps={reps}
            levels={levels}
            level={level}
            edit={edit}
            content={content}
            setContent={setContent}
            handleContentChange={handleContentChange}
            setChangedReps={setChangedReps}
          />
        </Grid>
      )}
    </div>
  );
};

export default LevelDetails;
