import * as Yup from 'yup';

export const email = Yup.string('Please enter your email.')
  .email('Invalid email entered.')
  .required('Email is required.');

export const password = Yup.string('Enter your password.')
  .min(8, 'Password should be a minimum of 8 characters length.')
  .required('Password is required.');

export const stringIsRequired = (subject) => {
  return Yup.string(`Please enter ${subject}.`)
    .typeError('Invalid text entered.')
    .required(`${subject} is required.`);
};

export const anyIsRequired = (subject) => {
  return Yup.string().required(`${subject} is required.`);
};

export const numberIsRequired = (subject) => {
  return Yup.number(`Please enter ${subject}.`)
    .typeError('Only numbers are allowed.')
    .required(`${subject} is required.`);
};
