const typography = {
  fontFamily: 'Poppins',
  h1: {
    fontSize: '35px',
    fontWeight: 'bold'
  },
  h2: {
    fontSize: '25px',
    color: '#033342',
    fontWeight: 'bold'
  },
  h3: {
    fontSize: '20px',
  },
  h4: {
    fontSize: '20px',
  },
  h5: {
    fontSize: '18px',
  },
  h6: {
    fontSize: '16px',
  },
  body1: {
    fontSize: '18px',
  },
  body2: {
    fontSize: '14px',
  },
  button: {
    textTransform: 'initial',
  }
};


export default typography;
