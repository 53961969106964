import { SELECT_CATEGORY }  from 'redux/types';

const INITIAL_STATE = {
  category: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_CATEGORY:
      return { ...state, category: action.payload };
    default:
      return state;
  }
};
