import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { makeStyles, Button, Grid, Typography } from '@material-ui/core';
import { TextInput } from 'utils/form/components';
import * as validations from 'utils/form/validations';
import * as Yup from 'yup';
import { customerEndpoint } from 'utils/api';
import Alert from '@material-ui/lab/Alert';
import avatar from '../../assets/images/avatar.jpeg';
import generator from 'generate-password';

import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(18),
    marginRight: theme.spacing(18),
    [theme.breakpoints.down('md')]: {
      margin: 30,
    },
  },
  register: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: 12,
    borderRadius: 10,
  },
  formTitle: {
    marginBottom: theme.spacing(3),
    color: '#033342',
    fontSize: 25,
  },
  placeholderImg: {
    width: 'auto',
    height: 170,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background: '#BCBCBC',
    borderRadius: 10,
    marginTop: 8,
  },
  label: {
    backgroundColor: '#F5F7F7',
    color: 'black',
    cursor: 'pointer',
    marginTop: 10,
    padding: 7,
    borderRadius: 6,
    maxWidth: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileChosen: {
    marginLeft: 15,
    backgroundColor: 'white',
    color: '#73ABBC',
    width: '110px',
    maxWidth: '110px',
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      width: '140px',
      maxWidth: '140px',
      padding: 0,
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-block',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90px',
    },
  },
  saveBtn: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: 13,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: '10px',
    background: '#087090',
    marginRight: 10,
    whiteSpace: 'nowrap',
    width: '100%',
  },
  avatar: {
    borderRadius: '50%',
    width: '78px',
    height: '80px',
    marginBottom: '-17px',
  },
  wrapLabelImg: {
    border: '20px',
    borderRadius: '6px',
    padding: '14px',
    background: 'white',
    marginTop: '10px',
    boxShadow: '0px 3px 10px #00000017',
    [theme.breakpoints.down('lg')]: {
      padding: 11,
    },
  },
  captionImg: {
    paddingTop: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: 30,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 80,
    },
  },
  imgPreview: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const MemberForm = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [alert, setAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [imgName, setImgName] = useState('');
  const [file, setFile] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [error, setError] = useState(false);

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImgName(file.name);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const initialValues = {
    username: props.location.prePopulate ? props.location.prePopulate.user.username : '',
    password: props.location.prePopulate ? props.location.prePopulate.user.password : '',
    firstName: props.location.prePopulate ? props.location.prePopulate.user.first_name : '',
    lastName: props.location.prePopulate ? props.location.prePopulate.user.last_name : '',
    email: props.location.prePopulate ? props.location.prePopulate.user.email : '',
    profile_pic: props.location.prePopulate ? props.location.prePopulate.profile_pic : '',
  };

  const validationSchema = Yup.object({
    username: validations.stringIsRequired('Username'),
    // password: validations.stringIsRequired('Password'),
    firstName: validations.stringIsRequired('First name'),
    lastName: validations.stringIsRequired('Last name'),
    email: validations.email,
  });

  useEffect(() => {
    if (props.location.prePopulate) {
      if (
        props.location.prePopulate.profile_pic !== null &&
        props.location.prePopulate.profile_pic !== ''
      ) {
        setImgName(props.location.prePopulate.profile_pic.split('profile-pic/')[1]);
      }
      setIsEditing(true);
    } else {
    }
  }, [props.location.prePopulate]);

  const handleSubmit = async (values) => {
    if (props.location.prePopulate) {
      try {
        const formData = new FormData();
        formData.append('profile_pic', file);
        formData.append('first_name', values.firstName);
        formData.append('last_name', values.lastName);
        formData.append('password', values.password);
        formData.append('email', values.email);
        formData.append('username', values.username);
        if (props.location.prePopulate) {
          await customerEndpoint
            .patch(`customer/${props.location.prePopulate.id}/`, formData)
            .then((res) => {
              if (res.status === 200) {
                setAlert(true);
                setTimeout(history.push('/Dashboard'), 15000);
              }
            });
        } else {
          await customerEndpoint.post('customer/', formData).then((res) => {
            if (res.status === 201) {
              setAlert(true);
              setTimeout(history.push('/Dashboard'), 15000);
            }
          });
        }
      } catch (error) {
        console.log(error);
        setErrorMsg(error.response.statusText);
        setError(true);
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} className={classes.form}>
          {error ? <Alert severity="error">{errorMsg}</Alert> : null}
          {alert ? (
            <Alert severity="success">
              {isEditing ? 'Member edited successfully' : 'Member registered successfully'}
            </Alert>
          ) : null}
          <Typography className={classes.formTitle} variant="h2">
            {isEditing ? 'Edit Member' : 'New Member'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12}>
              <Field component={TextInput} name="username" placeholder="Username" />
            </Grid>
            <Grid item sm={2} xs={12}>
              <Field component={TextInput} name="firstName" placeholder="First Name" />
            </Grid>
            <Grid item sm={2} xs={12}>
              <Field component={TextInput} name="lastName" placeholder="Last Name" />
            </Grid>
            <Grid item sm={3} xs={12}>
              <div className={classes.imgPreview}>
                {imagePreviewUrl ? (
                  <img alt="preview" className={classes.avatar} src={imagePreviewUrl} />
                ) : (
                  <Grid item sm={1} xs={2}>
                    <img
                      src={initialValues.profile_pic ? initialValues.profile_pic : avatar}
                      alt="placeholder"
                      className={classes.avatar}
                    />
                  </Grid>
                )}
                <Typography variant="body2" className={classes.captionImg}>
                  Recommended photo size 120 x 120 px. Maximum 10 MB file size.
                </Typography>
              </div>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Field component={TextInput} name="email" placeholder="Email" />
            </Grid>
            <Grid item sm={2} xs={12}>
              <Button
                className={classes.saveBtn}
                onClick={() =>
                  props.setFieldValue(
                    'password',
                    generator.generate({
                      length: Math.floor(Math.random() * (10, 14)),
                      numbers: true,
                      maxLength: 10,
                    })
                  )
                }
              >
                Generate password
              </Button>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Field component={TextInput} name="password" type="password" />
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className={classes.wrapLabelImg}>
                <label className={classes.label}>
                  <input
                    type="file"
                    id="actual-btn"
                    accept="image/*"
                    hidden
                    onChange={(e) => handleImageChange(e)}
                  />
                  Choose picture
                </label>
                <span className={classes.fileChosen} title={imgName}>
                  {imgName !== '' ? imgName : 'No Image Selected'}
                </span>
              </div>
            </Grid>
            <Grid item sm={12} xs={12} align="right">
              <Grid item lg={12} md={12} xs={12}>
                {props.values.submitError ? (
                  <Typography color="error">{props.value.submitError}</Typography>
                ) : null}
              </Grid>
              <Button className={classes.register} type="submit">
                {!isEditing ? 'Save Member' : 'Edit Member'}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default MemberForm;
