import {
  FETCH_PROMOS_START,
  FETCH_PROMOS_SUCCESS,
  FETCH_PROMOS_FAILURE,
  EDIT_PROMO,
  PROMO_SELECTED,
  DELETE_PROMO,
} from 'redux/types';
import { courseEndpoint } from 'utils/api';
import history from '../../../utils/history';

const fetchPromosStart = () => {
  return {
    type: FETCH_PROMOS_START,
  };
};

const fetchPromosSuccess = (promos) => {
  return {
    type: FETCH_PROMOS_SUCCESS,
    promos,
  };
};

const fetchPromoDetails = () => {
  const response = courseEndpoint.get('promo-code/');
  return response;
};

const fetchPromosFailure = (error) => {
  return {
    type: FETCH_PROMOS_FAILURE,
    error,
  };
};

export const fetchPromos = () => (dispatch) => {
  dispatch(fetchPromosStart());
  return fetchPromoDetails().then(
    (response) => dispatch(fetchPromosSuccess(response.data.data),console.log(response)),
    (error) => dispatch(fetchPromosFailure(error))
  );
};

export const selectPromo = (id) => {
  return {
    type: PROMO_SELECTED,
    id: id,
  };
};

export const deletePromo = (id) => {
  return async (dispatch) => {
    const response = await courseEndpoint.delete(`promo-code/${id}/`);
    dispatch({ type: DELETE_PROMO, payload: response.data });
    history.push('/Dashboard');
  };
};

export const editPromo = (id) => {
  return async (dispatch) => {
    const response = await courseEndpoint.get(`promo-code/${id}/`);
    dispatch({ type: EDIT_PROMO, payload: response.data });
    history.push({
      pathname: 'create-promo',
      prePopulate: response.data.data,
      populate: true,
    });
  };
};
