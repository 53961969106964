import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import { TextInput, NumberInput, DateInput } from 'utils/form/components';
import * as validations from 'utils/form/validations';
import * as Yup from 'yup';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

import { courseEndpoint } from 'utils/api';
import useStyles from './promoStyle';
import voucher_codes from 'voucher-code-generator';
import { Multiselect } from 'multiselect-react-dropdown';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import Select from 'react-select';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import deleteIcon from '../../assets/deleteIcon.png';
import nopreview from '../../assets/images/nopreview.png';

import { useHistory } from 'react-router';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderStyle: 'none',
    boxShadow: 'none',
  }),
};

const PromoForm = (props) => {
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [file, setFile] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [imgName, setImgName] = useState('');
  const [courses, setCourses] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [singleOption, setSingleOption] = useState('');
  const [checked, setChecked] = useState(false);
  const [checkedDate, setCheckedDate] = useState(false);

  const history = useHistory();

  const handleChangeUnlimited = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeNoEndDate = (event) => {
    setCheckedDate(event.target.checked);
  };

  const onSelect = (selectedList, selectedItem) => {
    if (props.location.prePopulate) {
      setSelectedValues(selectedList);
    }
    setSelectedValues(selectedList);
    setSingleOption(selectedItem);
    console.log(selectedItem, 'itemselected');
    console.log(selectedList, 'listSelected');
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(() => selectedList);
    console.log(removedItem, '==========>>removedItem');
  };
  console.log(selectedValues, '<<======================listremoved');

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImgName(file.name);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const getBundles = async () => {
    await courseEndpoint
      .get('bundle/')
      .then((response) => {
        const bundles = response.data.data.map((obj) => ({ ...obj, group: 'Bundles' }));
        setBundles(bundles);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  const getCourses = async () => {
    await courseEndpoint
      .get('course/')
      .then((response) => {
        const courses = response.data.data.map((obj) => ({ ...obj, group: 'Courses' }));
        setCourses(courses);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  useEffect(() => {
    getBundles();
    getCourses();
    if (props.location.prePopulate) {
      if (props.location.prePopulate.applicable_courses.length > 0) {
        const courses = props.location.prePopulate.applicable_courses.map((obj) => ({
          ...obj,
          group: 'Courses',
        }));
        setSelectedValues(
          courses.map((bundle) => {
            return {
              key: bundle.title,
              category: bundle.category,
              cat: bundle.group,
              promo_image: bundle.promo_image,
              id: bundle.id,
              price: bundle.price,
            };
          })
        );
      }
      if (props.location.prePopulate.applicable_bundles.length > 0) {
        const bundles = props.location.prePopulate.applicable_bundles.map((obj) => ({
          ...obj,
          group: 'Bundles',
        }));
        setSelectedValues(
          bundles.map((bundle) => {
            return {
              key: bundle.title,
              category: bundle.category,
              cat: bundle.group,
              promo_image: bundle.promo_image,
              id: bundle.id,
              price: bundle.price,
            };
          })
        );
      }
    }
    {
      // console.log(props.location.prePopulate);
    }
  }, []);

  const classes = useStyles();

  const initialValues = {
    title: props.location.prePopulate ? props.location.prePopulate.title : '',
    code: props.location.prePopulate ? props.location.prePopulate.code : '',
    start_date: props.location.prePopulate ? props.location.prePopulate.start_date : '',
    end_date: props.location.prePopulate ? props.location.prePopulate.end_date : '',
    redemption_limit: props.location.prePopulate ? props.location.prePopulate.redemption_limit : '',
    applicable_bundles: props.location.prePopulate
      ? props.location.prePopulate.applicable_bundles
      : [],
    applicable_courses: props.location.prePopulate
      ? props.location.prePopulate.applicable_courses
      : [],
    promo_image: props.location.prePopulate ? props.location.prePopulate.promo_image : null,
  };

  const validationSchema = Yup.object({
    title: validations.stringIsRequired('Title'),
    code: validations.stringIsRequired('Promo-code'),
    // course: validations.numberIsRequired('Course'),
    // description: validations.stringIsRequired('Description'),
  });

  const handleSubmit = async (values) => {
    if (props.location.prePopulate) {
      try {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('code', values.code);
        formData.append('start_date', values.start_date);
        // if (checkedDate === true) {
        //   formData.append('end_date', null);
        // } else {
        //   formData.append('end_date', values.end_date);
        // }
        formData.append('end_date', values.end_date);
        selectedValues.map((value) => {
          if (value.cat === 'Courses') {
            formData.append('applicable_courses', value.id);
          }
        });
        selectedValues.map((value) => {
          if (value.cat === 'Bundles') {
            formData.append('applicable_bundles', value.id);
          }
        });
        if (selectedOption && selectedOption.group === 'Bundles') {
          formData.append('applicable_bundles', [selectedOption.value]);
        }
        if (selectedOption && selectedOption.group === 'Courses') {
          formData.append('applicable_courses', [selectedOption.value]);
        }
        if (checked === true) {
          formData.append('redemption_limit', -1);
        } else {
          formData.append('redemption_limit', values.redemption_limit);
        }
        await courseEndpoint
          .patch(`promo-code/${props.location.prePopulate.id}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              setAlert(true);
              setTimeout(history.push('/Dashboard'), 15000);
            }
          });
      } catch (error) {
        setErrorMsg(error.response.statusText);
        setError(true);

        console.log(error.response.statusText);
      }
    } else {
      try {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('code', values.code);
        formData.append('start_date', values.start_date);
        // if (checkedDate === true) {
        //   formData.append('end_date', null);
        // } else {
        //   formData.append('end_date', values.end_date);
        // }
        formData.append('end_date', values.end_date);
        selectedValues.map((value) => {
          if (value.cat === 'Courses') {
            formData.append('applicable_courses', value.id);
          }
        });
        selectedValues.map((value) => {
          if (value.cat === 'Bundles') {
            formData.append('applicable_bundles', value.id);
          }
        });
        if (selectedOption && selectedOption.group === 'Bundles') {
          formData.append('applicable_bundles', [selectedOption.value]);
        }
        if (selectedOption && selectedOption.group === 'Courses') {
          formData.append('applicable_courses', [selectedOption.value]);
        }
        if (checked === true) {
          formData.append('redemption_limit', -1);
        } else {
          formData.append('redemption_limit', values.redemption_limit);
        }
        await courseEndpoint.post('promo-code/', formData).then((res) => {
          if (res.status === 201) {
            setAlert(true);
            setTimeout(history.push('/Dashboard'), 15000);
          }
        });
      } catch (error) {
        setErrorMsg(error.response.statusText);
        setError(true);
      }
      console.log(error.response.statusText);
    }
  };

  const handleChangeCourseOptions = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} className={classes.form}>
          {error ? <Alert severity="error">{errorMsg}</Alert> : null}
          {alert ? (
            <Alert severity="success">
              {!isEditing
                ? 'Promo code has been created successfully'
                : 'Promo Code edited successfully'}
            </Alert>
          ) : null}

          <Typography className={classes.goBack} variant="body1" component={Link} to="/content">
            <IoIosArrowBack /> Back to content
          </Typography>
          <Typography className={classes.formTitle} variant="h1">
            Create New Promo Code
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography className={classes.details} variant="body1">
                Promotion Name
              </Typography>
              <Field name="title" component={TextInput} placeholder="Input Promotion Name" />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="body2" className={classes.caption}>
                Users won&apos;t see this name, but it&apos;s how you&apos;ll track its performance.
                Each Promo coupon needs a unique name, and can only contain letters and numbers.
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid item md={12} xs={12}></Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.details} variant="body1">
                Coupon Code
                <Button
                  className={classes.generateCoupon}
                  onClick={() =>
                    props.setFieldValue(
                      'code',
                      voucher_codes
                        .generate({
                          count: 1,
                          length: 15,
                          // charset: "0123456789"
                        })
                        .toString()
                    )
                  }
                >
                  {' '}
                  Generate Random
                </Button>
              </Typography>
              <Field name="code" component={TextInput} placeholder="Input Coupon Code Name" />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography variant="body2" className={classes.caption}>
                Customers can enter this code for the discount, but also get a customer URL to
                share, so they don&apos;t have to. Each Code must be unique, and can only contain
                letters and numbers.
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid item md={12} xs={12} />
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid item md={12} xs={12}>
                <Typography className={classes.details} variant="body1">
                  Start Date
                </Typography>
                <Field component={DateInput} name="start_date" placeholder="From Data" />
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid item md={12} xs={12}>
                <Typography>End Date</Typography>
                <Field component={DateInput} name="end_date" placeholder="From Data" />
                <Typography className={classes.caption2}>
                  <Checkbox
                    onChange={handleChangeNoEndDate}
                    size="small"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    icon={<span className={clsx(classes.icon)} />}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  />
                  No end date
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Grid item md={12} xs={12}>
                <Typography className={classes.details} variant="body1">
                  Code Redemption Limit
                </Typography>
                <Field
                  name="redemption_limit"
                  component={NumberInput}
                  placeholder="Times of usage"
                />
                <Typography className={classes.caption2}>
                  <Checkbox
                    onChange={handleChangeUnlimited}
                    size="small"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    icon={<span className={clsx(classes.icon)} />}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  />
                  Unlimited
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className="imgPreview">
                {imagePreviewUrl ? (
                  <img
                    alt="preview"
                    className={classes.placeholderImgBundle}
                    src={imagePreviewUrl}
                  />
                ) : (
                  <div>
                    <img
                      src={initialValues.promo_image ? initialValues.promo_image : nopreview}
                      alt="placeholder"
                      className={classes.placeholderImgPromo}
                    />
                  </div>
                )}
                <div className={classes.labelWrapper}>
                  <label className={classes.label}>
                    <input
                      type="file"
                      accept="image/*"
                      id="actual-btn"
                      hidden
                      onChange={(e) => handleImageChange(e)}
                    />
                    Choose picture
                  </label>
                  <span className={classes.fileChosen}>
                    {imgName !== '' ? imgName : 'No Image Selected'}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item md={5} xs={12}>
              <Typography className={classes.details} variant="body1">
                Select Specific content
              </Typography>
              <Select
                className={classes.inputSelect}
                classNamePrefix="react-select"
                isSearchable
                styles={customStyles}
                value={selectedOption}
                menuPlacement="auto"
                maxMenuHeight={100}
                placeholder="Search for Course or Bundle"
                onChange={handleChangeCourseOptions}
                options={courses
                  .map((obj) => ({ ...obj, group: 'Courses' }))
                  .concat(bundles.map((obj) => ({ ...obj, group: 'Bundles' })))
                  // .filter((course) => course.category.id == props.values.category)
                  .map((course) => {
                    return { value: course.id, group: course.group, label: course.title };
                  })}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography className={classes.or}>or</Typography>
            </Grid>
            <Grid item md={5} xs={12}>
              <Typography className={classes.details} variant="body1">
                &nbsp;
              </Typography>

              <Multiselect
                groupBy="cat"
                options={courses.concat(bundles).map((bundle) => {
                  return {
                    key: bundle.title,
                    category: bundle.category,
                    cat: bundle.group,
                    promo_image: bundle.promo_image,
                    id: bundle.id,
                    price: bundle.price,
                  };
                })}
                displayValue="key"
                selectedValues={selectedValues}
                placeholder="Choose Multiple Courses or Bundles"
                showCheckbox={true}
                onSelect={onSelect}
                onRemove={onRemove}
                style={{
                  searchBox: {
                    // To change search box element look
                    boxShadow: '0px 3px 10px #00000017',
                    background: 'white',
                    border: 'none',
                    fontSize: '17px',
                    minHeight: '47px',
                    maxHeight: '47px',
                    marginTop: 10,
                  },
                  inputField: {
                    // To change input field position or margin
                    margin: '8px',
                    color: '#4B4B4B',
                    fontSize: 14,
                    minWidth: 270,
                  },
                  optionContainer: {
                    maxHeight: 140,
                  },
                }}
              />
            </Grid>

            {selectedValues.length > 0 ? (
              <Grid item sm={6} xs={12}>
                <>
                  <Typography>Courses</Typography>

                  {!!selectedValues.length
                    ? selectedValues
                        .filter((course) => course.cat === 'Courses')
                        .map((course, i) => {
                          return (
                            <Container className={classes.table}>
                              <Row key={i} xs={2} md={6} lg={6} className={classes.posInRow}>
                                <span className={classes.cell}>
                                  <img
                                    alt=""
                                    width="50"
                                    height="60"
                                    style={{ borderRadius: '10px', marginRight: '10px' }}
                                    src={course.promo_image}
                                  />
                                </span>
                                <div className={classes.posCells}>
                                  <span className={classes.cell}>{course.key}</span>
                                  <span className={classes.cell}>{course.category.title}</span>
                                  <span className={classes.cell}>€ {course.price}</span>
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    onClick={() =>
                                      setSelectedValues((prev) =>
                                        prev.filter((item) => item.id !== course.id)
                                      )
                                    }
                                  />
                                </div>
                              </Row>
                            </Container>
                          );
                        })
                    : null}
                </>
              </Grid>
            ) : null}
            {selectedValues.length > 0 ? (
              <Grid item sm={6} xs={12}>
                <>
                  <Typography>Bundles</Typography>
                  {!selectedValues.length
                    ? selectedValues
                        .filter((course) => course.cat === 'Bundles')
                        .map((course, i) => {
                          return (
                            <Container className={classes.table} key={i}>
                              <Row key={i} xs={2} md={6} lg={6} className={classes.posInRow}>
                                <span className={classes.cell}>
                                  <img
                                    alt=""
                                    width="50"
                                    height="60"
                                    style={{ borderRadius: '10px' }}
                                    src={course.promo_image}
                                  />
                                </span>
                                <div className={classes.posCells}>
                                  <span className={classes.cell}>{course.key}</span>
                                  <span className={classes.cell}>{course.category.title}</span>
                                  <span className={classes.cell}>€ {course.price}</span>
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    onClick={() =>
                                      setSelectedValues((prev) =>
                                        prev.filter((item) => item.id !== course.id)
                                      )
                                    }
                                  />
                                </div>
                              </Row>
                            </Container>
                          );
                        })
                    : null}
                </>
              </Grid>
            ) : null}

            <Grid item md={12} xs={12} align="right">
              <Grid item lg={12} md={12} xs={12} align="right">
                {props.values.submitError ? (
                  <Typography color="error">{props.value.submitError}</Typography>
                ) : null}
              </Grid>
              <Button className={classes.publishBtn} type="submit">
                Save Promo Code
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};
export default PromoForm;
