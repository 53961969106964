import React from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { logoutAction } from 'redux/actions/auth/logoutAction';
import { useTheme } from '@material-ui/styles';
import {
  makeStyles,
  useMediaQuery,
  Grid,
  Toolbar,
  AppBar,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemText,
  Popover,
  Paper,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 3px 11px #00000012',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    height: 90,
    zIndex: theme.appBar,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'auto',
    height: '100%',
    width: '100%',
    // paddingLeft: 0,
  },
  logo: {
    color: 'black',
    marginLeft: theme.spacing(2),
  },
  adminName: {
    fontWeight: 'normal',
    color: '#4B4B4B', // This has a unique font color (so theme.palette.text.links is not used).
  },
  menuButton: {
    marginLeft: -4,
  },
  burgerButton: {
    color: 'black',
    float: 'right',
  },
  listItems: {
    backgroundColor: 'theme.palette.background.paper',
    display: 'flex',
    maxWidth: 650,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    zIndex: theme.appBar,
  },
  listItemText: {
    fontWeight: 'normal',
    color: '#033342',
    whiteSpace: 'nowrap',
  },
  item: {
    marginLeft: 5,
  },
  highlight: {
    fontWeight: 'bold',
    color: '#033342',
  },
  topBarLinks: {
    height: '100%',
    borderRight: '1px solid #E5E5E5',
  },
  userAvatar: {
    width: 'fit-content',
    padding: 1,
    paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    float: 'right',
  },
  avatar: {
    marginLeft: theme.spacing(1),
    marginTop: 2,
    marginBottom: 2,
  },
  downArrow: {
    color: theme.palette.text.links,
    marginTop: 4,
    marginLeft: 0,
    fontSize: 25,
  },
}));


const TopBar = (props) => {
  const { openSidebar, onToggleSidebar } = props;
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();
  const history = useHistory();
  const dispatch = useDispatch();

  const isCurrentPage = (href) => href === window.location.pathname;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });

  const getUsername = () => {
    try {
      return store.getState().login.account.username;
    } catch (error) {
      history.push('/login');
    }
  };

  const Logo = () => {
    return (
      <Typography variant="h1" className={classes.logo}>
        Logo
      </Typography>
    );
  };

  const UserAvatar = (props) => {
    const { handleClick } = props;
    return (
      <Grid
        onClick={handleClick}
        className={classes.userAvatar}
        container
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="body1" className={classes.adminName}>
            {getUsername()}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar className={classes.avatar} src="/broken-image.jpg" />
        </Grid>
        <Grid item>
          <DownArrow className={classes.downArrow} />
        </Grid>
      </Grid>
    );
  };

  const TopRightMenu = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const logout = () => {
      dispatch(logoutAction());
      history.push('/login');
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        <UserAvatar handleClick={handleClick} />
        <Popover
          open={open}
          disableScrollLock
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper className={classes.avatarMenu}>
            <MenuList>
              <MenuItem onClick={() => logout()}>Logout</MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      </div>
    );
  };

  const ListItems = () => {
    const items = [
      { title: 'Dashboard', href: '/dashboard' },
      { title: 'Content', href: '/content' },
      { title: 'Members', href: '/members' },
      { title: 'Content Creators', href: '/creators' },
      { title: 'Sales', href: '/promo-codes' },
    ];

    const listItemList = items.map((item) => (
      <ListItem key={item.title} className={classes.item} component={NavLink} to={item.href}>
        <ListItemText
          classes={{
            primary: clsx(classes.listItemText, { [classes.highlight]: isCurrentPage(item.href) }),
          }}
          primary={item.title}
        />
      </ListItem>
    ));

    return (
      <List className={classes.listItems} component="div">
        {listItemList}
      </List>
    );
  };

  const desktopMenu = () => {
    return (
      <React.Fragment>
        <Grid className={classes.topBarLinks} item xs={8}>
          <ListItems />
        </Grid>
        <Grid item xs={2}>
          <TopRightMenu />
        </Grid>
      </React.Fragment>
    );
  };

  const tabletMobileMenu = () => {
    return (
      <Grid item xs={10}>
        <IconButton className={classes.burgerButton} aria-label="Menu" onClick={onToggleSidebar}>
          { openSidebar ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" /> }
        </IconButton>
      </Grid>
    );
  };

  return (
    <AppBar position="relative" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid style={{ height: '100%' }} container justify="center" alignItems="center">
          <Grid item xs={2}>
            <Logo />
          </Grid>
          { isDesktop ? desktopMenu() : tabletMobileMenu() }
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
