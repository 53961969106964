import React, { useState } from 'react';
import {
  makeStyles,
  Paper,
  Popover,
  MenuList,
  MenuItem,
  Typography,
  Modal,
  Button,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import * as icons from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import { courseEndpoint } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  itemRoot: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 10px #00000017',
    marginBottom: 6,
    color: '#033342',
    cursor: 'pointer',
    borderRadius: 10,
    padding: theme.spacing(1),
    minHeight: 100,
    height: '90%',
    width: '80%',
    maxHeight: 100,
    maxWidth: 300,
    display: 'grid',
    grid: `
    "top-left top-mid top-right"
    "bottom-left bottom-mid bottom-right"
    `,
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  },
  icon: {
    gridArea: 'bottom-right',
    marginTop: 'auto',
    marginLeft: 'auto',
  },
  selected: {
    border: '4px #087090 solid',
    borderRadius: 20,
  },
  notSelected: {
    border: '4px #FFFFFF solid',
    borderRadius: 20,
  },
  dropdown: {
    gridArea: 'top-right',
    marginLeft: 'auto',
  },
  deleteCategory: {
    color: 'red',
  },
  boxItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  boxButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

const CategoryItem = (props) => {
  const { id, title, icon, selected, onClick } = props;
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClickDropdown = (e) => {
    e.stopPropagation(); // This will work for now.
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCategory = async () => {
    try {
      await courseEndpoint.delete(`category/${id}`);
      window.location.reload();
    } catch (error) {
      console.log('Delete Category API error!');
    }
  };

  const open = Boolean(anchorEl);

  const getSelectedIcon = () => {
    if (icon && icons[icon]) {
      const SelectedIcon = icons[icon];
      return <SelectedIcon className={classes.icon} fontSize="large" />;
    } else {
      return null;
    }
  };

  const itemClassName = classNames({
    [classes.itemRoot]: true,
    [classes.selected]: selected,
    [classes.notSelected]: !selected,
  });

  return (
    <Paper onClick={onClick} className={itemClassName}>
      <MoreVertIcon onClick={(e) => onClickDropdown(e)} className={classes.dropdown} />
      <Typography variant="body1">{title ? title : null}</Typography>
      {getSelectedIcon()}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration="auto"
      >
        <Paper className={classes.avatarMenu}>
          <MenuList>
            {/* <MenuItem>
              <MoreVertIcon /> Edit
            </MenuItem> */}
            <MenuItem className={classes.deleteCategory} onClick={handleOpenModal}>
              <DeleteIcon /> Delete
            </MenuItem>
          </MenuList>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <div className={classes.boxItems}>
                <Typography
                  align="center"
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{ marginBottom: '1em' }}
                >
                  Are you sure?
                </Typography>
                <div className={classes.boxButtons}>
                  <Button onClick={deleteCategory}>Yes</Button>
                  <Button onClick={handleCloseModal}>No</Button>
                </div>
              </div>
            </Box>
          </Modal>
        </Paper>
      </Popover>
    </Paper>
  );
};

export default CategoryItem;
