import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, AppBar, makeStyles, Toolbar } from '@material-ui/core';
import { creatorEndpoint } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 3px 11px #00000012',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    height: 70,
    zIndex: theme.appBar,
  },
}));

const UserActivation = (props) => {
  const classes = useStyles();
  const [token, setToken] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setToken(props.location.search.substring(2, props.location.search.length));
  }, []);

  console.log(token);

  const sendActiveToken = () => {
    try {
      creatorEndpoint.post(`activate/`, { token }).then((response) => {
        if (response.status === 200) {
          setSuccess(true);
        } else {
          setError(true);
        }
      });
    } catch (error) {
      console.log('ERROR', error);
      setError(true);
    }
  };

  return (
    <Grid container>
      <AppBar position="relative" className={classes.root}>
        <Toolbar>
          <Typography variant="h2" color="primary">
            SmartRep
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid xs={12} item>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '5em',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography style={{ marginBottom: '1em' }}>Confirm your Registration below</Typography>
            <Button variant="contained" color="primary" onClick={sendActiveToken}>
              Activate account
            </Button>
            {success && (
              <div style={{ maringTop: '1em' }}>
                <Typography style={{ color: 'green' }}>
                  Account has been successfully activated! <br />
                  You can now login from the mobile app using your credentials.
                </Typography>
              </div>
            )}
            {error && (
              <div style={{ maringTop: '1em' }}>
                <Typography style={{ color: 'red' }}>
                  Something went wrong. Please try again!
                </Typography>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserActivation;
