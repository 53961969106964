import { authEndpoint } from 'utils/api';
import { FP_ERRORS, FORGOT_PASSWORD } from 'redux/types';


export const forgotPassword = (body) => async (dispatch) => {
  try {
      const response = await authEndpoint.post('users/password-reset-request/', body);
        dispatch({
            type: FORGOT_PASSWORD,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: FP_ERRORS,
            payload: true,
            response: error.response
        });
    }
};
