import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Popover,
  Paper,
  MenuList,
  MenuItem,
  Modal,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import placeholder from '../../assets/images/placeholder.png';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    boxShadow: '0px 3px 10px #0000001A;',
    borderRadius: 25,
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    backgroundColor: '#FFFFFF',
    minHeight: 220,
    maxHeight: 220,
  },
  image: {
    height: 185,
    zIndex: 0,
    display: 'block',
  },
  title: {
    textAlign: 'left',
    fontWeight: 'bold',
    display: 'inline-block',
    color: theme.palette.text.primary,
  },
  price: {
    float: 'right',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  description: {
    textAlign: 'left',
    display: 'block',
  },
  reviews: {
    textAlign: 'left',
    display: 'block',
    backgroundColor: '#E6F1F4',
    color: '#087090',
    background: '#E6F1F4 0% 0% no-repeat padding-box',
    borderRadius: 10,
    width: 'fit-content',
    padding: '2px 8px',
  },
  dropdown: {
    gridArea: 'top-right',
    marginLeft: '90%',
    textAlign: 'right',
    float: 'right',
    marginTop: -170,
    zIndex: 2000,
    position: 'absolute',
  },
  container: {
    position: 'relative',
  },
  deleteText: {
    color: '#EE9292',
    fontSize: 16,
    marginLeft: 5,
  },
  editText: {
    fontSize: 16,
    marginLeft: 5,
    color: '#4B4B4B',
  },
  boxItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  boxButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}));

const PreviewWidget = (props) => {
  const { image, title, creator, price, description, reviewCount, onClickReview, draft, edit } =
    props;

  const classes = useStyles();

  // const renderCreator = (creator) => {
  //   if (creator) {
  //     return (
  //       <Grid item sm={12} xs={12}>
  //         <Typography className={classes.description} variant="body2">
  //           {creator}
  //         </Typography>
  //       </Grid>
  //     );
  //   }
  // };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const onClickDropdown = (e) => {
    e.stopPropagation(); // This will work for now.
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className={classes.card} raised={false}>
      <div className={classes.container}>
        <CardMedia
          className={classes.image}
          component="img"
          image={image === null ? placeholder : image}
        />
        <Typography variant="body1"></Typography>
        {draft ? (
          <Button
            style={{ zIndex: '2000', marginTop: '-170px', cursor: 'auto', position: 'absolute' }}
          >
            Draft
          </Button>
        ) : null}
        <MoreVertIcon onClick={(e) => onClickDropdown(e)} className={classes.dropdown} />
        <Popover
          style={{ zIndex: 5 }}
          open={open}
          disableScrollLock
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper className={classes.avatarMenu}>
            <MenuList>
              <MenuItem className={classes.deleteCategory} onClick={edit}>
                <FaRegEdit color="#4B4B4B" />
                <span className={classes.editText}>Edit</span>
              </MenuItem>
              <MenuItem className={classes.deleteCategory} onClick={handleOpenModal}>
                <RiDeleteBin6Line color="#EE9292" />
                <span className={classes.deleteText}> Delete</span>
              </MenuItem>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-titles"
                aria-describedby="modal-modal-descriptions"
                anchorEl={null}
                style={{ zIndex: 9999 }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <div className={classes.boxItems}>
                    <Typography
                      align="center"
                      id="modal-modal-titles"
                      variant="h6"
                      component="h2"
                      style={{ marginBottom: '1em' }}
                    >
                      Are you sure?
                    </Typography>
                    <div className={classes.boxButtons}>
                      <Button onClick={props.delete}>Yes</Button>
                      <Button onClick={handleCloseModal}>No</Button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </MenuList>
          </Paper>
        </Popover>
      </div>

      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item sm={9} xs={12}>
            <Typography className={classes.title} variant="body1" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Typography className={classes.price} variant="body1" gutterBottom>
              {price ? `€${price}` : null}
            </Typography>
          </Grid>
          {/* {renderCreator(creator)} */}
          <Grid item sm={12} xs={12}>
            <Typography className={classes.description} variant="body2">
              {creator}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography className={classes.description} variant="body2">
              {description.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')}
            </Typography>
          </Grid>
          {reviewCount >= 0 ? (
            <Grid item sm={12} xs={12}>
              <Button onClick={onClickReview} className={classes.reviews}>
                {reviewCount} Reviews
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PreviewWidget;
