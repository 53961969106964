import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { useDispatch, useStore } from 'react-redux';
import { loginAction } from 'redux/actions/auth/loginAction';
import { Container, makeStyles, Paper, Typography, Link, Button, Grid } from '@material-ui/core';
import * as validations from 'utils/form/validations';
import { TextInput } from 'utils/form/components';

// import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
// import { authEndpoint } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#F5F7F7',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 320,
    backgroundColor: '#F5F7F7',
    padding: theme.spacing(4),
  },
  form: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  loginButton: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  header: {
    paddingBottom: 10,
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const store = useStore();
  const dispatch = useDispatch();

  let submitError = '';

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = yup.object({
    username: validations.stringIsRequired('Username'),
    password: validations.password,
  });

  const handleSubmit = async (values) => {
    submitError = '';
    await dispatch(loginAction(values));
    if (store.getState().login.validToken) {
      history.push('/dashboard');
    } else {
      submitError = 'Error: please check your login details.';
    }
  };

  // const handleOnClick = (e) => {
  //   e.preventDefault();

  //   window.grecaptcha.ready(() => {
  //     window.grecaptcha
  //       .execute('6LdToqYaAAAAAE9SF7tQ0Hv2rJtR_I8By4u6z4nG', { action: 'submit' })
  //       .then((token) => {
  //         submitData(token);
  //       });
  //   });
  // };

  // const submitData = async (token) => {
  //   // call a backend API to verify reCAPTCHA response
  //   // fetch('http://167.71.60.27:8124/api/login', {
  //   //   method: 'POST',
  //   //   headers: {
  //   //     "Content-Type": "application/json"
  //   //   },
  //   //   body: JSON.stringify({
  //   //     'username': 'admin_123',
  //   //     'password': 'i4MmD3xIFJth',
  //   //     'recaptcha': token
  //   //   })
  //   // }).then(res => res.json()).then(res => {
  //   //   // setLoading(false);
  //   //   setResponse(res);
  //   // });
  //   try {
  //     const initialValues = {
  //       username: 'admin_123',
  //       password: 'i4MmD3xIFJth',
  //       recaptcha: token,
  //     };

  //     const response = await authEndpoint.post('login/', initialValues);
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Container className={classes.container}>
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="h1" className={classes.header}>
          Login
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className={classes.form}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item lg={12} md={12} xs={12}>
                  <Field
                    component={TextInput}
                    placeholder="Insert username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Field
                    placeholder="Insert password"
                    component={TextInput}
                    type="password"
                    autoComplete="password"
                    name="password"
                    label="Password"
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Button className={classes.loginButton} type="submit">
                    Login
                  </Button>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Typography variant="body2" align="center">
                    <Link style={{ color: '#087090' }} href="/reset-password">
                      Forgot Password?
                    </Link>
                  </Typography>{' '}
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  {submitError ? <Typography color="error">{submitError}</Typography> : null}
                  {/* <button onClick={handleOnClick}>{'Submit'}</button> */}
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Paper>
      {/* <GoogleReCaptchaProvider
        reCaptchaKey="6LdToqYaAAAAAE9SF7tQ0Hv2rJtR_I8By4u6z4nG"
        useRecaptchaNet="[optional_boolean_value]"
        scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      > */}
      {/* <GoogleReCaptcha
          onVerify={(token) => {
            setToken(token);
          }}
        /> */}
      {/* </GoogleReCaptchaProvider> */}
    </Container>
  );
};

export default LoginPage;
