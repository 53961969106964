import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Typography, Button, Dialog } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { courseEndpoint } from 'utils/api';

import RegisterCategory from './RegisterCategory';
import ViewAllLink from './shared/ViewAllLink';
import { CategoryItem } from 'components';

import { useDispatch } from 'react-redux';
import { setCategoryAction } from 'redux/actions/categories/setCategoryAction';

import Carousel, { consts } from 'react-elastic-carousel';

const useStyles = makeStyles((theme) => ({
  itemRoot: {
    backgroundColor: '#BCBCBC',
    borderRadius: 10,
    padding: theme.spacing(1),
    minHeight: 100,
    color: '#FFFFFF',
    height: '100%',
    width: '75%',
  },
  icon: {
    cursor: 'pointer',
  },
  formSubtitle: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
  },
  btn: {
    padding: 10,
    float: 'right',
    borderRadius: 10,
    width: 161,
    height: 45,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      marginTop: 5,
    },
  },
  dialog: {
    minHeight: 50,
    minWidth: 50,
    padding: theme.spacing(1),
  },
  arrow: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'rec-pagination': {
    display: 'none',
  },
}));

const CategoryList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    async function fetchData() {
      const categories = await (await courseEndpoint.get('category/')).data.data;
      setCategories(categories);
      dispatch(setCategoryAction(''));
    }
    fetchData();
    setOpen(false);
  };

  const Header = () => {
    return (
      <React.Fragment>
        <Typography className={classes.formSubtitle} variant="h4">
          Choose Category
          <span>
            <Button size="large" className={classes.btn} onClick={handleClickOpen}>
              + New Category
            </Button>
          </span>
        </Typography>
        <Dialog
          style={{ zIndex: 3000 }}
          className={classes.dialog}
          onClose={handleClose}
          open={open}
        >
          <RegisterCategory handleClose={handleClose} />
        </Dialog>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function fetchData() {
      const categories = await (await courseEndpoint.get('category/')).data.data;
      setCategories(categories);
      dispatch(setCategoryAction(''));
    }
    fetchData();
  }, []);

  const onSelectItem = (newCategory) => {
    if (newCategory.title === selectedCategory) {
      setSelectedCategory('');
      dispatch(setCategoryAction(''));
    } else {
      setSelectedCategory(newCategory.title);
      dispatch(setCategoryAction(newCategory));
    }
  };

  const myArrow = ({ type, onClick }) => {
    const pointer = type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;

    return (
      <div className={classes.arrow} onClick={onClick}>
        {pointer}
      </div>
    );
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 300, itemsToShow: 2 },
    { width: 550, itemsToShow: 4 },
    { width: 768, itemsToShow: 6 },
  ];

  return (
    <React.Fragment>
      <Header title="Choose Category" buttonText="+ New Category" />
      <Carousel breakPoints={breakPoints} renderArrow={myArrow} pagination={false}>
        {categories.map((category, i) => (
          <CategoryItem
            key={i}
            id={category.id}
            selected={selectedCategory === category.title}
            onClick={() => onSelectItem(category)}
            title={category.title}
            icon={category.icon}
          />
        ))}
      </Carousel>
      <ViewAllLink link="categories" />
    </React.Fragment>
  );
};

export default CategoryList;
