import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { DashboardRoute } from 'routers';
import UserActivation from 'layouts/UserActivation/UserActivation';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import history from 'utils/history';
import { isUserAdmin } from 'utils/checkUserRole';
import {
  Dashboard,
  Content,
  Bundles,
  Courses,
  Categories,
  CourseForm,
  Creators,
  CreatorsForm,
  Login,
  Members,
  MemberForm,
  ResetPassword,
} from 'pages';
import BundleForm from 'pages/Content/components/RegisterBundle';
import PromoForm from 'pages/Promo/CreatePromo';
import PromoCodes from 'pages/Promo/PromoCodes';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route component={UserActivation} path="/activate" />
          <Route component={Login} path="/login" />
          <Route component={ResetPassword} path="/reset-password" />
          <DashboardRoute component={Dashboard} path="/dashboard" auth={isUserAdmin} />
          <DashboardRoute component={Content} path="/content" auth={isUserAdmin} />
          <DashboardRoute component={Bundles} path="/bundles" auth={isUserAdmin} />
          <DashboardRoute component={Courses} path="/courses" auth={isUserAdmin} />
          <DashboardRoute component={Categories} path="/categories" auth={isUserAdmin} />
          <DashboardRoute component={CourseForm} path="/add-course" auth={isUserAdmin} />
          <DashboardRoute component={Creators} path="/creators" auth={isUserAdmin} />
          <DashboardRoute component={Members} path="/members" auth={isUserAdmin} />
          <DashboardRoute component={MemberForm} path="/register-member" auth={isUserAdmin} />
          <DashboardRoute component={CreatorsForm} path="/register-creator" auth={isUserAdmin} />
          <DashboardRoute component={BundleForm} path="/add-bundle" auth={isUserAdmin} />
          <DashboardRoute component={PromoForm} path="/create-promo" auth={isUserAdmin} />
          <DashboardRoute component={PromoCodes} path="/promo-codes" auth={isUserAdmin} />
          <Redirect to="/login" />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
