import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { PreviewWidget, ReviewsModal } from 'components';
import { courseEndpoint, creatorEndpoint } from 'utils/api';

import Header from './shared/Header';
import ViewAllLink from './shared/ViewAllLink';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { editCourse, editDraftCourse } from 'redux/actions/courses/fetchCourseActions';

const CoursesList = () => {
  const dispatch = useDispatch();

  const [courses, setCourses] = useState([]);
  const [creators, setCreators] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [open, setOpen] = useState(false);
  const selectedCat = useSelector((state) => state.category.category);

  useEffect(() => {
    async function fetchData() {
      const courseDraftData = await (await courseEndpoint.get('course/draft')).data.data;
      const courseData = await (
        await courseEndpoint.get('course/')
      ).data.data.concat(courseDraftData);

      const creatorData = await (await creatorEndpoint.get('content-creator/')).data.data;
      const ratingData = await (await courseEndpoint.get('course-rating/')).data.data;
      setCourses(courseData);
      setCreators(creatorData);
      setRatings(ratingData);
    }
    fetchData();
  }, []);

  // const getCreatorName = (id) => {
  //   for (const creator of creators) {
  //     if (creator.id == id) {
  //       return `${creator.user.first_name} ${creator.user.last_name}`;
  //     }
  //   }
  // };

  const onClickReview = async (course) => {
    const validRatings = [];
    for (const rating of ratings) {
      if (rating.course === course.id) {
        validRatings.push(rating);
      }
    }
    setSelectedRatings(validRatings);
    setSelectedCourse(course);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRatings([]);
    setSelectedCourse([]);
    setOpen(false);
  };

  const getReviewCount = (id) => ratings.filter((rating) => rating.course === id).length;

  const deleteCourse = async (id) => {
    try {
      await courseEndpoint.delete(`course/${id}`);
      window.location.reload();
    } catch (error) {
      console.log('Delete Course API error!');
    }
  };

  const editedCourse = (id) => {
    dispatch(editCourse(id));
  };

  const editedDraftCourse = (id) => {
    dispatch(editDraftCourse(id));
  };

  return (
    <React.Fragment>
      <Header title="Courses" buttonText="+ New Course" to="add-course" />
      <Grid container spacing={2}>
        {courses
          .filter((course) => {
            if (selectedCat) {
              return course.category.title === selectedCat;
            }
            return true;
          })
          .map((course, i) => {
            if (i < 8) {
              return (
                <Grid key={i} item md={3} sm={6} xs={12}>
                  <PreviewWidget
                    key={course.title}
                    title={course.title}
                    // creator={getCreatorName(course.content_creator)}
                    creator={course.content_creator.user.username}
                    description={course.description}
                    price={course.price}
                    image={course.promo_image}
                    reviewCount={getReviewCount(course.id)}
                    onClickReview={() => onClickReview(course)}
                    draft={course.is_draft}
                    delete={() => deleteCourse(course.id)}
                    edit={() =>
                      course.is_draft === true
                        ? editedDraftCourse(course.id)
                        : editedCourse(course.id)
                    }
                  />
                </Grid>
              );
            }
          })}
      </Grid>
      <ViewAllLink link="courses" />
      <ReviewsModal
        open={open}
        reviews={selectedRatings}
        course={selectedCourse}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
};

export default CoursesList;
