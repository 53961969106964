import { FP_ERRORS, FORGOT_PASSWORD } from 'redux/types';


const initialState = {
    status: {},
    error: false
};


export default function (state = initialState, action) {
    switch (action.type) {
        case FORGOT_PASSWORD:
            return {
                ...state,
                status: action.payload,
                error: false
            };
        case FP_ERRORS:
            return {
                ...state,
                error: action.payload,
                response: action.response
            };
        default:
            return state;
    }
}
