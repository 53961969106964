import React, { useState, useEffect } from 'react';
import { courseEndpoint, creatorEndpoint } from 'utils/api';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { IoIosArrowBack } from 'react-icons/io';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CustomTextField from './CustomTextField';
import CustomSelectField from './CustomSelectField';
import useStyles from '../courseFormStyle';
import nopreview from '../../../../assets/images/nopreview.png';
import Radio from '@material-ui/core/Radio';

const Course = (props) => {
  const {
    course,
    handleCourseChange,
    errorTitle,
    errorDescription,
    errorUserGuide,
    errorCategory,
    errorContentCreator,
    errorPrice,
    errorPromoImage,
  } = props;

  const [creators, setCreators] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewVideo, setPreviewVideo] = useState('');
  const [imgName, setImgName] = useState('');
  const [videoName, setVideoName] = useState('');
  const [discount, setDiscount] = useState(0);
  const [price, setPrice] = useState(0);
  const [selectedValue, setSelectedValue] = useState('b');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const inputRef = React.useRef();
  const classes = useStyles();

  const getCreators = async () => {
    await creatorEndpoint
      .get('content-creator/')
      .then((response) => {
        const allCreators = response.data.data;
        setCreators(allCreators);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  const getCategories = async () => {
    await courseEndpoint
      .get('category/')
      .then((response) => {
        const categories = response.data.data;
        setCategories(categories);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  const getBundles = async () => {
    await courseEndpoint
      .get('bundle/')
      .then((response) => {
        const bundles = response.data.data;
        setBundles(bundles);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  const getVideoEdit = () => {
    if (props.courseData.preview_video !== null && props.courseData.preview_video !== '') {
      setVideoName(props.courseData.preview_video.split('course/videos/')[1]);
      setPreviewVideo(props.courseData.preview_video);
      handleCourseChange(
        new File(
          [props.courseData.preview_video],
          props.courseData.preview_video.split('course/videos/')[1],
          {
            type: props.courseData.preview_video.type,
          }
        ),
        'preview_video'
      );
    }
  };

  const getImageEdit = () => {
    if (props.courseData.promo_image !== null && props.courseData.promo_image !== '') {
      setImgName(props.courseData.promo_image.split('course/')[1]);
      setPreviewImage(props.courseData.promo_image);
      handleCourseChange(
        new File([props.courseData.promo_image], props.courseData.promo_image.split('course/')[1], {
          type: props.courseData.promo_image.type,
        }),
        'promo_image'
      );
    }
  };

  console.log('courseData', props.courseData);

  useEffect(() => {
    getCreators();
    getCategories();
    getBundles();
    getVideoEdit();
    getImageEdit();
  }, []);

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setPreviewImage(reader.result);
      setImgName(file.name);
      handleCourseChange(file, 'promo_image');
    };
    reader.readAsDataURL(file);
  };

  const handleVideoChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    console.log(url);
    setPreviewVideo(url);
    setVideoName(file.name);
    handleCourseChange(file, 'preview_video');
  };

  const Heading = () => (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Typography className={classes.goBack} variant="body1" component={Link} to="/content">
          <IoIosArrowBack /> Back to content
        </Typography>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Typography className={classes.formTitle} variant="h1">
          New Course
        </Typography>
        <Typography className={classes.details} variant="body1">
          Course Details
        </Typography>
      </Grid>
    </Grid>
  );

  const CoursePhoto = () => {
    const PreviewImage = () => (
      <img
        alt="preview"
        style={{ width: 'auto', height: '180px', borderRadius: '10px' }}
        src={previewImage}
      />
    );

    const PlaceHolderImage = () => (
      <div className={classes.placeholderImg}>
        <span className={classes.previewText}>Preview Image</span>
      </div>
    );

    return (
      <div className={classes.marginTopGrid}>
        <Typography className={classes.details} variant="body1">
          Course Photo
        </Typography>
        {previewImage !== '' ? (
          <PreviewImage />
        ) : (
          // <img src={nopreview} alt="nopreview" />
          <PlaceHolderImage />
        )}
        <div className={errorPromoImage ? classes.labelWrapperError : classes.labelWrapper}>
          <label className={classes.label}>
            <input type="file" accept="image/*" hidden onChange={handleImageChange} />
            Choose picture
          </label>
          <span className={classes.fileChosen}>
            {imgName !== '' ? imgName : 'No Image Selected'}
          </span>
        </div>
      </div>
    );
  };

  const handleChangeEditorUG = (event, editor) => {
    const data = editor.getData();
    handleCourseChange(data, 'user_guide');
  };

  const handleChangeEditorCD = (event, editor) => {
    const data = editor.getData();
    handleCourseChange(data, 'description');
  };

  const CourseVideo = () => {
    const PreviewVideo = () => (
      <div className={classes.placeholderImg}>
        <video width="auto" height="178px" controls src={previewVideo} />
      </div>
    );

    const PlaceHolderVideoImage = () => (
      <div className={classes.placeholderImg1}>
        <span className={classes.previewText}>Preview Video</span>
      </div>
    );

    return (
      <div className={classes.marginTopGrid1}>
        <Typography className={classes.details} variant="body1">
          Introductory Video
        </Typography>
        {previewVideo !== '' ? <PreviewVideo /> : <PlaceHolderVideoImage />}

        <div className={classes.labelWrapper1}>
          <label className={classes.label}>
            {' '}
            <input
              type="file"
              accept=".mov,.mp4"
              ref={inputRef}
              hidden
              onChange={handleVideoChange}
            />
            Choose Video
          </label>
          <span className={classes.fileChosen}>
            {videoName !== '' ? videoName : 'No Video Selected'}
          </span>
        </div>
      </div>
    );
  };

  console.log('e', errorTitle);

  return (
    <Grid container spacing={2} style={{ marginBottom: 24 }}>
      <Heading />
      <Grid spacing={2} container>
        <Grid item sm={8} xs={12}>
          <CustomTextField
            value={course['title']}
            onChange={(event) => handleCourseChange(event.target.value, 'title')}
            className={classes.customSelectCreatorMargin}
            error={errorTitle}
            placeholder="Input Course Name"
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.marginGridRight}>
          <CustomSelectField
            value={course['content_creator']}
            onChange={(event) => handleCourseChange(event.target.value, 'content_creator')}
            label="Choose Creator"
            className={classes.customSelectCreator}
            error={errorContentCreator}
            options={creators.map((creator) => {
              return { value: creator.id, label: creator.user.username };
            })}
          />
        </Grid>
      </Grid>

      <Grid spacing={2} container>
        <Grid item sm={8} xs={12} style={{ marginTop: '1.3em' }}>
          {errorDescription ? (
            <div>
              <CKEditor
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
                    writer.setStyle('border-color', 'red', editor.editing.view.document.getRoot());
                  });
                }}
                config={{ placeholder: "Add Course Description - What you'll learn" }}
                data={course['description']}
                onChange={handleChangeEditorCD}
              />
            </div>
          ) : (
            <CKEditor
              editor={ClassicEditor}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
                });
              }}
              config={{ placeholder: "Add Course Description - What you'll learn" }}
              data={course['description']}
              onChange={handleChangeEditorCD}
            />
          )}

          {/* <CustomTextField
            value={course['description']}
            onChange={(event) => handleCourseChange(event.target.value, 'description')}
            placeholder="Add Course Description - What you'll learn"
            style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
            multiline
            rows={13}
          /> */}
        </Grid>
        <Grid item sm={4} xs={12}>
          <CoursePhoto />
        </Grid>
      </Grid>

      <Grid spacing={2} container>
        <Grid item sm={8} xs={12} style={{ marginTop: '1.3em' }}>
          {errorUserGuide ? (
            <div>
              <CKEditor
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
                    writer.setStyle('border-color', 'red', editor.editing.view.document.getRoot());
                  });
                }}
                config={{ placeholder: "Add User Guides - What you'll need" }}
                data={course['user_guide']}
                onChange={handleChangeEditorUG}
              />
            </div>
          ) : (
            <CKEditor
              editor={ClassicEditor}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
                });
              }}
              config={{ placeholder: "Add User Guides - What you'll need" }}
              data={course['user_guide']}
              onChange={handleChangeEditorUG}
            />
          )}

          {/* <CustomTextField
            value={course['user_guide']}
            onChange={(event) => handleCourseChange(event.target.value, 'user_guide')}
            placeholder="Add User Guides - What you'll need"
            style={{ width: '100%' }}
            multiline
            rows={13}
          /> */}
        </Grid>
        <Grid item sm={4} xs={12}>
          <CourseVideo />
        </Grid>
      </Grid>

      <Grid spacing={2} container>
        <Grid item sm={4} xs={12} className={classes.marginBottomGrid}>
          <CustomSelectField
            value={course['category']}
            onChange={(event) => handleCourseChange(event.target.value, 'category')}
            label="Choose Category"
            className={classes.customSelect}
            error={errorCategory}
            options={categories.map((category) => {
              return { value: category.id, label: category.title };
            })}
          />
        </Grid>
        <Grid item sm={4} xs={12} className={classes.marginBottomGrid}>
          <CustomSelectField
            value={course['bundle']}
            onChange={(event) => handleCourseChange(event.target.value, 'bundle')}
            label="Choose Bundle (optional)"
            className={classes.customSelect}
            options={bundles.map((bundle) => {
              return { value: bundle.id, label: bundle.title };
            })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: 24, marginTop: 10 }}>
        <Grid item sm={12} xs={12}>
          <Typography className={classes.details} variant="body1">
            Price Details
          </Typography>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Typography className={classes.caption} variant="body1">
            Set a Price
          </Typography>
          <CustomTextField
            type="number"
            value={course['price']}
            onChange={(event) => {
              setPrice(event.target.value);
              handleCourseChange(event.target.value, 'price');
            }}
            error={errorPrice}
            placeholder="Individual Course Price"
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Typography className={classes.labels} variant="body1">
            Add a discount
          </Typography>
          Yes
          <Radio
            checked={selectedValue === 'a'}
            onChange={handleChange}
            value="a"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
            checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
          />
          No
          <Radio
            checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
            checked={selectedValue === 'b'}
            onChange={handleChange}
            value="b"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'B' }}
            icon={<span className={classes.icon} />}
          />
        </Grid>
        {selectedValue === 'a' ? (
          <>
            <Grid item sm={1} xs={12}>
              <Typography className={classes.labels} variant="body1">
                Enter Discount (%)
              </Typography>
              <CustomTextField
                type="number"
                value={course['discount']}
                onChange={(event) => {
                  setDiscount(event.target.value);
                  handleCourseChange(event.target.value, 'discount');
                }}
                placeholder="0"
              />
            </Grid>
            <Grid item sm={1} xs={12}></Grid>
            <Grid item sm={2} xs={12}>
              <Typography className={classes.labels} variant="body1">
                Duration
              </Typography>
              <CustomTextField
                type="date"
                value={course['discount_start_date']}
                onChange={(event) => handleCourseChange(event.target.value, 'discount_start_date')}
                placeholder="From"
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <Typography className={classes.labels} variant="body1">
                Duration
              </Typography>
              <CustomTextField
                type="date"
                value={course['discount_expiry_date']}
                onChange={(event) => handleCourseChange(event.target.value, 'discount_expiry_date')}
                placeholder="To"
              />
            </Grid>{' '}
          </>
        ) : null}
        <Grid item sm={12} xs={12} className={classes.totalPrice}>
          Total Price(after discount) : {(price * (100 - discount)) / 100}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Course;
