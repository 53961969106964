import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    paddingLeft: 80,
    paddingRight: 80,
    maxWidth: '100%',
  },
  formControl1: {
    width: '100%',
    '& .MuiFormControl-outlined:hover': {
      backgroundColor: 'red',
    },
  },
  customSelect: {
    width: '100%',
  },
  customSelectCreator: {
    width: '100%',
  },
  customSelectCreatorMargin: {
    width: '100%',
    marginTop: '10px',
  },
  customSelectCreatorError: {
    width: '100%',
    borderColor: 'red',
  },
  customSelectCreatorMarginError: {
    width: '100%',
    marginTop: '10px',
    borderColor: 'red',
  },
  marginGridRight: {
    marginTop: '22px',
  },
  marginTopGrid: {
    marginTop: '15px',
  },
  marginTopGrid1: {
    marginTop: '12px',
  },
  marginBottomGrid: {
    marginTop: '15px',
  },
  publishBtn: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: 10,
    borderRadius: '10px',
    background: '#033342',
    color: 'white',
    textTransform: 'capitalize',
    paddingLeft: 23,
    paddingRight: 23,
  },
  circular: {
    color: '#033342',
    marginBottom: '-18px',
  },
  saveBtn: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: 10,
    borderRadius: '10px',
    background: '#124E6193',
    marginRight: 10,
    color: 'white',
    textTransform: 'capitalize',
    paddingLeft: 23,
    paddingRight: 23,
  },
  formTitle: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    fontSize: 22,
    color: '#033342',
  },
  customErrorText: {
    color: '#f44336',
    margin: 0,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '400',
    lineHeight: '1.66',
  },
  goBack: {
    fontSize: 13,
    color: '#087090',
    textDecoration: 'none',
  },
  details: {
    color: '#033342',
    whiteSpace: 'nowrap',
  },
  labels: {
    marginBottom: 10,
    color: '#033342',
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
  labels1: {
    marginTop: 10,
    color: '#033342',
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
  inputImage: {
    background: 'white!important',
    borderRadius: 6,
    padding: 8,
    minHeight: 45,
    paddingTop: 10,
    marginTop: 10,
    width: '98%',
  },
  placeholderImg: {
    width: 'auto',
    height: 210,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background: '#BCBCBC',
    borderRadius: 10,
    marginTop: 8,
  },
  placeholderImg1: {
    width: 'auto',
    height: 220,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background: '#BCBCBC',
    borderRadius: 10,
    marginTop: 3,
  },
  placeholderImgBundle: {
    width: 'auto',
    height: 208,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background: '#BCBCBC',
    borderRadius: 10,
    marginTop: -70,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: -73,
    },
  },
  previewImgBundle: {
    width: 'auto',
    height: '180px',
    borderRadius: '10px',
    [theme.breakpoints.up('sm')]: {
      marginTop: -70,
    },
  },
  previewText: {
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold',
    paddingBottom: 80,
  },
  totalPrice: {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingTop: 60,
    marginLeft: '24%',
    marginTop: 30,
    color: '#087090',
  },
  caption: {
    fontSize: '12px',
    color: '#4B4B4B',
  },
  btn: {
    marginTop: 12,
    borderRadius: '10px',
    background: '#087090!important',
    color: 'white',
  },
  addCourseBtn: {
    marginTop: -5,
    borderRadius: '10px',
    background: '#087090',
    color: 'white',
    fontSize: 14,
  },
  courseBtn: {
    backgroundColor: '#08709019',
    marginTop: -5,
    borderRadius: '10px',
    color: 'white',
    fontSize: 14,
  },
  btnImport: {
    marginTop: 12,
    background: '#087090!important',
    color: 'white',
    borderRadius: '10px',
  },
  fileChosen: {
    marginLeft: 15,
    backgroundColor: 'white',
    color: '#73ABBC',
    width: '150px',
    maxWidth: '150px',
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      width: '137px',
      maxWidth: '137px',
      padding: 0,
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-block',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '70px',
    },
  },
  label: {
    backgroundColor: '#F5F7F7',
    color: 'black',
    cursor: 'pointer',
    marginTop: 10,
    padding: 7,
    borderRadius: 6,
  },
  inputSelect: {
    background: 'white',
    borderRadius: 6,
    marginTop: '8px !important',
    boxShadow: '0px 3px 10px #00000017',
    // whiteSpace: 'nowrap',
    // '&::placeholder': {
    //   color:'green',
    //   background:'black'
    // }
  },
  inputSelectCustom: {
    background: 'white',
    borderRadius: 6,

    boxShadow: '0px 3px 10px #00000017',
    // whiteSpace: 'nowrap',
    // '&::placeholder': {
    //   color:'green',
    //   background:'black'
    // }
  },
  inputSelectBundle: {
    marginTop: '8px',
    background: 'white',
    borderRadius: 6,
    boxShadow: '0px 3px 10px #00000017',
  },
  or: {
    color: '#033342',
    fontWeight: 600,
    paddingLeft: 60,
    fontSize: 17,
  },
  MuiInputLabel: {
    outlined: {
      '&$marginDense': {
        transform: 'translate(14px, 17px) scale(1)',
      },
    },
  },
  checkedIcon: {
    // borderRadius: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 10px #00000017',
    borderRadius: '23px',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#087090,#087090 28%)',
      content: '""',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 10px #00000017',
      borderRadius: '23px',
    },
  },
  icon: {
    // boxShadow: '0px 3px 10px #00000017',
    // borderRadius: '50%',
    width: 16,
    height: 16,
    // backgroundColor: 'white',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 10px #00000017',
    borderRadius: '23px',
  },
  labelRoot: {
    color: '#033342',
    marginTop: -7,
    paddingLeft: 10,
    fontSize: 15,
  },
  labelWrapper: {
    border: '20px',
    borderRadius: '6px',
    padding: '12px',
    background: 'white',
    marginTop: '3px',
    boxShadow: '0px 3px 10px #00000017',
  },
  labelWrapperError: {
    border: '1px solid red',
    borderRadius: '6px',
    padding: '12px',
    background: 'white',
    marginTop: '3px',
    boxShadow: '0px 3px 10px #00000017',
  },
  labelWrapper1: {
    border: '20px',
    borderRadius: '6px',
    padding: '12px',
    background: 'white',
    marginTop: '2px',
    boxShadow: '0px 3px 10px #00000017',
  },
  labelWrapper2: {
    border: '20px',
    borderRadius: '6px',
    padding: '12px',
    background: 'white',
    marginTop: '14px',
    boxShadow: '0px 3px 10px #00000017',
  },
  levelListHeadings: {
    marginBottom: 15,
    color: '#B8B9B9',
    width: '100%',
    padding: 35,
  },
  addNewLevelButton: {
    marginTop: 12,
    borderRadius: '10px',
    background: '#087090!important',
    color: 'white',
    textTransform: 'initial',
  },
  addNewLevelInput: {
    display: 'flex',
    alignItems: 'center',
  },
  inputRoot: {
    background: '#FFFFFF',
    borderRadius: 6,
    padding: 12,
    marginTop: 12,
    boxShadow: '0px 3px 10px #00000017',
    whiteSpace: 'nowrap',
  },
  inputRootError: {
    background: '#FFFFFF',
    borderRadius: 6,
    padding: 12,
    marginTop: 12,
    boxShadow: '0px 3px 10px #00000017',
    whiteSpace: 'nowrap',
    border: '1px solid red',
  },
  inputRootCustom: {
    background: '#FFFFFF',
    borderRadius: 6,
    boxShadow: '0px 3px 10px #00000017',
    whiteSpace: 'nowrap',
  },
  inputRootCustomError: {
    background: '#FFFFFF',
    borderRadius: 6,
    boxShadow: '0px 3px 10px #00000017',
    whiteSpace: 'nowrap',
    border: '1px solid red',
  },
  level: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'white',
    padding: 35,
    marginBottom: 25,
    boxShadow: '0px 3px 10px #00000017',
    borderRadius: '10px',
  },
  arrowDown: {
    position: 'absolute',
    right: 35,
    top: 35,
    fontSize: 25,
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: '#4B4B4B',
  },
  delete: {
    padding: 20,
  },
  deleteButton: {
    color: '#033342',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: 45,
    marginTop: 5,
  },
  dragIconContainer: {
    padding: 20,
  },
  posImageDrag: {
    display: 'flex',
  },
  dragIcon: {
    color: '#033342',
    cursor: 'grab',
    fontSize: 35,
    marginTop: 10,
  },
  videoDropZoneContainer: {
    backgroundColor: '#F5F7F7',
    padding: '35px !important',
    borderRadius: 10,
    cursor: 'pointer',
  },
  dropZone: {
    textAlign: 'center',
    padding: 20,
    border: '1px dashed #000000',
    backgroundColor: '#F5F7F7',
    borderRadius: 5,
    color: '#bdbdbd',
    marginBottom: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  form: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(18),
    marginRight: theme.spacing(18),
    [theme.breakpoints.down('sm')]: {
      margin: 30,
    },
  },
}));

export default useStyles;
