import { DELETE_CUSTOMER } from 'redux/types';

export default (state = null, action) => {
  switch (action.type) {
    case DELETE_CUSTOMER:
      return {
        ...state,
        deleteCustomer: action,
      };
    default:
      return state;
  }
};
