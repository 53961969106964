import palette from './palette';

const overrides = {
  MuiButton: {
    root: {
      maxWidth: 250,
      backgroundColor: '#033342',
      fontWeight: 'normal',
      color: 'white',
      '&:hover': {
        backgroundColor: '#033342',
        color: 'white',
      },
    },
  },
  MuiInputBase: {
    // Prevents Chrome forcing its own background color to be used if
    // input is auto-filled. More: https://github.com/mui-org/material-ui/issues/14427
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
  },
  MuiLink: {
    root: {
      color: palette.text.links,
    },
  },
  MuiCssBaseline: {
    // You can set global CSS styles here, inside the '@global' object.
    // But, avoid doing this if you can.
    '@global': {
      // The following three style rules help keep
      // the app at full height of the window.
      html: {
        height: '100%',
        overflowY: 'auto'
      },
      body: {
        height: '100%',
        backgroundColor: '#fff'
      },
      'div#root': {
        height: '100%',
        backgroundColor:'#F5F7F7',
      },
    },
  },
};

export default overrides;
