import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginLeft: 180,
    marginRight: 180,
    [theme.breakpoints.down('sm')]: {
      margin: 30,
    },
  },
  publishBtn: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(2),
    padding: 10,
    borderRadius: '10px',
    background: '#033342',
  },
  saveBtn: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: 10,
    borderRadius: '10px',
    background: '#124E6193',
    marginRight: 10,
  },
  formTitle: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    fontSize: 22,
    color: '#4B4B4B',
  },
  goBack: {
    fontSize: 13,
    color: '#087090',
    textDecoration: 'none',
  },
  details: {
    color: '#033342',
    whiteSpace: 'nowrap',
    fontSize: 17,
  },
  labels: {
    marginBottom: 10,
    color: '#033342',
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
  inputImage: {
    background: 'white!important',
    borderRadius: 6,
    padding: 8,
    minHeight: 45,
    paddingTop: 10,
    marginTop: 10,
    width: '98%',
  },
  placeholderImg: {
    width: 'auto',
    height: 188,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background: '#BCBCBC',
    borderRadius: 10,
    // marginTop: 8,
    marginTop: -180,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: -180,
    },
  },
  placeholderImgPromo: {
    width: 282,
    height: 175,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background: '#BCBCBC',
    borderRadius: 10,
    marginTop: -180,
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px!important',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: -180,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: -200,
      marginBottom: 45,
    },
  },
  previewText: {
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold',
    paddingBottom: 80,
  },
  totalPrice: {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingTop: 60,
    marginLeft: '24%',
    marginTop: 30,
    color: '#087090',
  },
  btn: {
    marginTop: 12,
    borderRadius: '10px',
    background: '#087090!important',
    color: 'white',
  },
  addCourseBtn: {
    marginTop: -5,
    borderRadius: '10px',
    background: '#087090',
    color: 'white',
    fontSize: 14,
  },
  courseBtn: {
    backgroundColor: '#08709019',
    marginTop: -5,
    borderRadius: '10px',
    color: 'white',
    fontSize: 14,
  },
  btnImport: {
    marginTop: 12,
    background: '#087090!important',
    color: 'white',
    borderRadius: '10px',
  },
  fileChosen: {
    marginLeft: 15,
    backgroundColor: 'white',
    color: '#73ABBC',
    width: '110px',
    maxWidth: '110px',
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      width: '140px',
      maxWidth: '140px',
      padding: 0,
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-block',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90px',
    },
  },
  label: {
    backgroundColor: '#F5F7F7',
    color: 'black',
    cursor: 'pointer',
    marginTop: 13,
    padding: 7,
    borderRadius: 6,
  },
  or: {
    color: '#033342',
    fontWeight: 600,
    paddingLeft: 60,
    fontSize: 17,
    paddingTop: 40,
  },
  MuiInputLabel: {
    outlined: {
      '&$marginDense': {
        transform: 'translate(14px, 17px) scale(1)',
      },
    },
  },
  labelRoot: {
    color: '#033342',
    marginTop: -7,
    paddingLeft: 10,
    fontSize: 15,
  },
  caption: {
    fontSize: 10,
    paddingTop: 40,
  },
  generateCoupon: {
    color: '#087090',
    fontSize: 16,
    float: 'right',
    background: '#F5F7F7',
    marginTop: -8,
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
      marginTop: -3,
    },
  },
  caption2: {
    fontSize: 14,
  },
  labelWrapper: {
    border: '20px',
    borderRadius: '6px',
    padding: '12px',
    background: 'white',
    marginTop: '38px',
    boxShadow: '0px 3px 10px #00000017',
    whiteSpace: 'nowrap',
  },
  icon: {
    borderRadius: 5,
    borderColor: 'blue',
    backgroundColor: 'white',
    boxShadow: '0px 3px 10px #00000017',
    color: 'blue',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      boxShadow: '0px 3px 10px #00000017',
      content: '""',
    },
  },
  checkedIcon: {
    backgroundColor: 'white',
    boxShadow: '0px 3px 10px #00000017',
    color: 'blue',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      boxShadow: '0px 3px 10px #00000017',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%red'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  inputSelect: {
    background: '#FFFFFF',
    borderRadius: 6,
    padding: 7,
    // paddingLeft: 14,
    marginTop: '8px !important',
    boxShadow: '0px 3px 10px #00000017',
    // whiteSpace: 'nowrap',
    borderStyle: 'none!important',
    borderColor: 'white!important',
    '& .react-select__menu': {
      marginLeft: '-7px',
    },
  },
  table: {
    // height: 250,
    width: '100%',
    background: 'white!important',
    boxShadow: '0px 3px 10px #00000016',
    // borderRadius: '15px',
    padding: 20,
  },
  cell: {
    // width: 90,
    minWidth: 160,
    maxWidth: 160,
    marginRight: 15,
  },
  posCells: {
    marginLeft: -60,
  },
  posInRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  deleteIcon: {
    width: 30,
    minWidth: 40,
    maxWidth: 40,
    marginLeft: 35,
    color: '#033342',
    fontWeight: 'bold',
  },
  row: {
    borderBottom: '1px solid #F5F7F7',
  },
}));

export default useStyles;
