import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { PreviewWidget } from 'components';
import { courseEndpoint } from 'utils/api';
import { editBundle, editDraftBundle } from 'redux/actions/bundles/fetchBundleActions';
import { useDispatch } from 'react-redux';

const BundlesList = () => {
  const dispatch = useDispatch();
  const [bundles, setBundles] = React.useState([]);

  useEffect(() => {
    async function fetchData() {
      const draftBundles = await (await courseEndpoint.get('bundle/draft')).data.data;
      const response = await (await courseEndpoint.get('bundle/')).data.data.concat(draftBundles);
      setBundles(response);
    }
    fetchData();
  }, []);

  const deleteBundle = async (id) => {
    try {
      await courseEndpoint.delete(`bundle/${id}/`);
      window.location.reload();
    } catch (error) {
      console.log('Delete Course API error!');
    }
  };

  const editedBundle = (id) => {
    dispatch(editBundle(id));
  };

  const editedDraftBundle = (id) => {
    dispatch(editDraftBundle(id));
  };


  return (
    <React.Fragment>
      <Grid container spacing={2}>
      {bundles.map((bundle, i) => (
          <Grid key={i} item md={3} sm={6} xs={12}>
            <PreviewWidget
              key={i}
              title={bundle.title}
              description={bundle.description}
              price={bundle.price}
              image={bundle.promo_image}
              delete={() => deleteBundle(bundle.id)}
              edit={() =>
                bundle.is_draft === true
                  ? editedDraftBundle(bundle.id)
                  : editedBundle(bundle.id)
              }
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default BundlesList;
