import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Dropzone from 'react-dropzone';
import useStyles from '../courseFormStyle';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import CustomTextField from './CustomTextField';
import { courseEndpoint, levelEndpoint } from 'utils/api';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const Content = ({
  levels,
  level,
  content,
  handleContentChange,
  edit,
  setContent,
  setChangedReps,
  reps,
}) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    checkedPublish: true,
  });

  // const [open, setOpen] = useState(false);

  const handlePublishChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // id: uuidv4(),
  // levelId: 3,
  // preview: url,
  // name: file[0].name,
  // duration: Math.round(video.duration * 100) / 100,

  const [videoFile, setVideoFile] = useState([]);
  const [bet, setBet] = useState([]);

  useEffect(() => {
    // if (!edit) {
    //   if (content.length > 0) {
    //     const response = content?.filter((item) => item.newLevelId === level.ids);
    //     console.log('==>', response);
    //     setBet(response);
    //   }
    // } else {
    if (content.length > 0) {
      const betNew = getBetContents();
      console.log('BETNEW', betNew);
      betNew.map((item) => {
        // const video1 = document.createElement('video');
        // video1.src = item.video;

        setVideoFile((prev) => [...prev, item.video]);
      });
      setBet(betNew);
    }
    // }
  }, [content]);
  //}

  const handleDrop = (files) => {
    let newContent = [];
    let count = 0;
    // Next sequence number
    let seq_num_i = content.length + 1;

    files.forEach((f) => {
      const url = URL.createObjectURL(f);
      // For each file, we create an arbitrary video element...
      const video = document.createElement('video');
      video.src = url;
      setVideoFile((prev) => [...prev, url]);

      // When it loads, it executes this listener.
      video.addEventListener('loadeddata', () => {
        // This returns the data of the video.
        const newFile = {
          uuid: uuidv4(),
          levelId: level.id ? level.id : null,
          newLevelId: level.ids ? level.ids : null,
          isUploaded: false,
          title: f.name,
          short_description: 'dhot',
          repetitions: -1,
          video: f,
          duration: Math.round(video.duration * 100) / 100,
          sequence_number: seq_num_i,
        };
        seq_num_i++;

        // push the info to the array.
        newContent = [...newContent, newFile];
        count++;

        // Now, once the listener has been executed as many times as there are videos,
        // update the state.
        if (count == files.length) handleContentChange(newContent);
      });
    });

    //setAddedFiles([...addedFiles, { ...files[0], levelId: level.id }]);
  };

  const handleDeleteVideoEdit = async (e, cont) => {
    e.preventDefault();

    // If an item exists, don't execute a delete req.
    console.log('cont', cont);

    if (cont.id) {
      await levelEndpoint.delete(`content/${cont.id}/`).then(() => {
        setContent(content.filter((item) => item.id !== cont.id));
        setBet(bet.filter((item) => item.id !== cont.id));
      });
    }

    if (cont.levelId && cont.levelId !== null && cont.newLevelId === null) {
      setContent(
        content.filter((item) =>
          item.title === cont.title && item.levelId === cont.levelId
            ? item.title !== cont.title && item.levelId !== cont.levelId
            : { ...item }
        )
      );
      setBet(
        bet.filter((item) =>
          item.title === cont.title && item.levelId === cont.levelId
            ? item.title !== cont.title && item.levelId !== cont.levelId
            : { ...item }
        )
      );
    }

    if (cont.newLevelId && cont.newLevelId !== null && cont.levelId === null) {
      setContent(
        content.filter((item) =>
          item.title === cont.title && item.newLevelId === cont.newLevelId
            ? item.title !== cont.title && item.newLevelId !== cont.newLevelId
            : { ...item }
        )
      );
      setBet(
        content.filter((item) =>
          item.title === cont.title && item.newLevelId === cont.newLevelId
            ? item.title !== cont.title && item.newLevelId !== cont.newLevelId
            : { ...item }
        )
      );
    }

    // if(value)

    // console.log('CO', content);
  };

  const handleDeleteVideoPost = (e, cont) => {
    e.preventDefault();
    console.log('cont', cont);
    setContent(
      content.filter((item) =>
        item.title === cont.title && item.newLevelId === cont.newLevelId
          ? item.title !== cont.title && item.newLevelId !== cont.newLevelId
          : { ...item }
      )
    );
    setBet(
      content.filter((item) =>
        item.title === cont.title && item.newLevelId === cont.newLevelId
          ? item.title !== cont.title && item.newLevelId !== cont.newLevelId
          : { ...item }
      )
    );
  };

  // useEffect(() => {
  //   content.map((item) => (item.repetitions = reps));
  //   handleContentChange(content[0]);
  // }, [reps]);

  const VideoDropzone = () => {
    return (
      <Dropzone onDrop={handleDrop} accept="video/*" multiple>
        {({ getRootProps, getInputProps }) => {
          return (
            <div
              {...getRootProps({
                className: classes.dropZone,
              })}
            >
              <input {...getInputProps()} />
              <p style={{ fontWeight: 'bold', color: '#4B4B4B' }}>Drag New Video Files Here</p>
            </div>
          );
        }}
      </Dropzone>
    );
  };

  const itemExists = (i) => Boolean(!(i.levelId || i.newLevelId));
  const levelExists = (l) => Boolean(l.id && !l.ids);

  // Might be needed later.
  // Updates the bet, based on the content.
  console.log('LEVEL???=?', level);
  const getBetContents = () => {
    // When loading content, filter the content as follows:
    const f = content.filter((item) => {
      // Check that i belongs to this level
      if (levelExists(level)) {
        if (itemExists(item)) {
          return item.level == level.id;
        } else {
          return item.levelId == level.id;
        }
      } else return item.newLevelId == level.ids;
    });
    return f;
  };

  const sortContentCallback = (a, b) => (a.sequence_number < b.sequence_number ? -1 : 1);

  const handleOnDragEnd = ({ source, destination }) => {
    console.log('source', source);
    console.log('destination', destination);

    let oldContent = content;
    // Re-order levels
    // Copy the old content to a new array.
    let filteredContent;
    if (levelExists(level)) {
      filteredContent = oldContent
        .filter((item) => item.level === level.id || item.levelId === level.id)
        .sort((a, b) => sortContentCallback(a, b));
    } else {
      filteredContent = oldContent
        .filter((item) => item.newLevelId === level.ids)
        .sort((a, b) => sortContentCallback(a, b));
    }

    // Remove source item
    const [temps] = filteredContent.splice(source.index, 1);

    filteredContent.splice(destination.index, 0, temps);

    // Update sequence_number
    filteredContent.forEach((content, index) => {
      content.sequence_number = index + 1; // We can't start from zero.
    });

    //Loop through the old content...
    for (let i = 0; i < oldContent.length; i++) {
      // FIND THE CONTENT WITHIN oldContent
      let filter = filteredContent.find(
        (c) => c.title == oldContent[i].title && c.duration == oldContent[i].duration
      );

      // Update oldContent...
      if (filter !== undefined) oldContent[i] = filter;
    }

    // This DOES NOT cause useEffect to run, as content is hosted elsewhere.
    setContent(oldContent);
    setBet(getBetContents());
  };

  // const handleReps = (value, row) => {
  //   console.log('ROW', row);
  //   const update = bet.map((item) => {
  //     // If the title doesn't match, we're not interested...
  //     if (item.title !== row.title) return item;
  const handleReps = (value, content_item) => {
    for (const c of bet) {
      if (c.title !== content_item.title) continue;

      if (levelExists(level)) {
        // // If the item doesn't exist, check level ids
        // if (!itemExists(item) && !itemExists(row) && item.levelId === row.levelId) {
        //   item.repetitions = value;
        //   return { ...item };
        // }
        if (!itemExists(c) && !itemExists(content_item) && c.levelId === content_item.levelId) {
          reps[content_item.uuid] = value;
          break;
        }

        // if (itemExists(item) && itemExists(row) && item.id === row.id) {
        //   item.repetitions = value;
        //   setChangedReps((prev) => [...prev, item]);
        //   return { ...item };
        // }
        if (itemExists(c) && itemExists(content_item) && c.id === content_item.id) {
          reps[content_item.id] = value;
          break;
        }
      }

      // if (item.newLevelId === row.newLevelId) {
      //   item.repetitions = value;
      //   return { ...item };
      // }
      if (c.newLevelId === content_item.newLevelId) {
        reps[content_item.uuid] = value;
        break;
      }

      // });
      // setBet(update);
      // setContent(update);
      // setBet(getBetContents());
    }
  };

  console.log('CONTENT', content);
  console.log('Bet', bet);

  /**
   * Gets the CURRENT (ie not saved) repetitions from CourseForm.
   * @param {Object} video 
   * @returns Repetitions or null if the video isn't found.
   */
  const getCurrentRepsFromState = (video) => {
    if (itemExists(video)){
      if (reps.hasOwnProperty(video.id))
        return reps[video.id];
    } else {
      if (reps.hasOwnProperty(video.uuid))
        return reps[video.uuid];
    }
    return null;
  }

  // Memo the sorted bet, solves problem with rerendering (some of the problems at least...)
  const sortedBet = useMemo(() => bet?.sort((a, b) => sortContentCallback(a, b)), [bet]);

  const Videos = () => {
    return (
      <Table className={classes.table} aria-label="video table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell sm={8} className={classes.tableCell}></TableCell>
            <TableCell sm={8} className={classes.tableCell}>
              Content Title
            </TableCell>
            <TableCell sm={2} className={classes.tableCell}>
              Duration
            </TableCell>
            <TableCell sm={2} className={classes.tableCell}>
              Current Repetitions
            </TableCell>
            <TableCell sm={2} className={classes.tableCell}>
              Published
            </TableCell>
            <TableCell sm={2} className={classes.tableCell}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>

        {edit ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId={level.id ? String(level.id) : level.ids} direction="vertical">
              {(provided) => {
                return (
                  <TableBody
                    className={level.id ? String(level.id) : level.ids}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {sortedBet
                      .map((item, i) => (
                        <Draggable
                          draggableId={String(bet.indexOf(item))}
                          index={i}
                          key={Number(i)}
                        >
                          {(provided, snapshot) => {
                            return (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={classes.tableBodyRow}
                                key={i}
                              >
                                <TableCell
                                  style={{
                                    borderTopRightRadius: 10,
                                    borderBottomRightRadius: 10,
                                  }}
                                  sm={2}
                                  className={classes.bodyTableCell}
                                  component="td"
                                  scope="row"
                                >
                                  <div className={classes.posImageDrag}>
                                    <div {...provided.dragHandleProps}>
                                      <DragIndicatorIcon className={classes.dragIcon} />
                                    </div>

                                    {/* {videoFile.length > 0 &&
                                      videoFile.map((vf, k) => (
                                        <div>
                                          {k === i && (
                                            <video width="75" height="53" controls>
                                              <source src={vf} />
                                            </video>
                                          )}
                                        </div>
                                      ))} */}
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderTopLeftRadius: 10,
                                    borderBottomLeftRadius: 10,
                                  }}
                                  sm={8}
                                  className={classes.bodyTableCell}
                                  component="td"
                                  scope="row"
                                >
                                  {item.title}
                                </TableCell>
                                <TableCell
                                  sm={2}
                                  className={classes.bodyTableCell}
                                  component="td"
                                  scope="row"
                                >
                                  {item.duration}
                                </TableCell>
                                <TableCell>
                                  <CustomTextField
                                    defaultValue={getCurrentRepsFromState(item)}
                                    onChange={(e) => handleReps(e.target.value, item)}
                                    placeholder={item.repetitions}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormGroup>
                                    <Typography component="div">
                                      <Grid
                                        component="label"
                                        container
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        <Grid item>
                                          <AntSwitch
                                            checked={state.checkedPublish}
                                            onChange={handlePublishChange}
                                            name="checkedPublish"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Typography>
                                  </FormGroup>
                                </TableCell>
                                <TableCell
                                  style={{
                                    borderTopLeftRadius: 10,
                                    borderBottomLeftRadius: 10,
                                  }}
                                  sm={8}
                                  className={classes.bodyTableCell}
                                  component="td"
                                  scope="row"
                                >
                                  <DeleteIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => handleDeleteVideoEdit(e, item)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
        ) : (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-roots" direction="vertical">
              {(provided) => {
                return (
                  <TableBody
                    className="droppable-roots"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {sortedBet
                      .map((row, i) =>
                        videoFile.length > 0 ? (
                          <Draggable draggableId={row.title} index={i} key={Number(i)}>
                            {(provided, snapshot) => {
                              return (
                                <TableRow
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className={classes.tableBodyRow}
                                  key={i}
                                >
                                  <TableCell
                                    style={{
                                      borderTopRightRadius: 10,
                                      borderBottomRightRadius: 10,
                                    }}
                                    sm={2}
                                    className={classes.bodyTableCell}
                                    component="td"
                                    scope="row"
                                  >
                                    <div className={classes.posImageDrag}>
                                      <div {...provided.dragHandleProps}>
                                        <DragIndicatorIcon className={classes.dragIcon} />
                                      </div>
                                      {/* 
                                      {videoFile.length > 0 &&
                                        videoFile.map((vf, k) => (
                                          <div>
                                            {k === i && (
                                              <video width="75" height="53" controls>
                                                <source src={vf} />
                                              </video>
                                            )}
                                          </div>
                                        ))} */}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      borderTopLeftRadius: 10,
                                      borderBottomLeftRadius: 10,
                                    }}
                                    sm={8}
                                    className={classes.bodyTableCell}
                                    component="td"
                                    scope="row"
                                  >
                                    {row.title}
                                  </TableCell>
                                  <TableCell
                                    sm={2}
                                    className={classes.bodyTableCell}
                                    component="td"
                                    scope="row"
                                  >
                                    {row.duration}
                                  </TableCell>
                                  <TableCell>
                                    <CustomTextField
                                      //value={row.repetitions}
                                      onChange={(e) => handleReps(e.target.value, row)}
                                      placeholder="Insert New Repetitions"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormGroup>
                                      <Typography component="div">
                                        <Grid
                                          component="label"
                                          container
                                          alignItems="center"
                                          spacing={1}
                                        >
                                          <Grid item>
                                            <AntSwitch
                                              checked={state.checkedPublish}
                                              onChange={handlePublishChange}
                                              name="checkedPublish"
                                            />
                                          </Grid>
                                        </Grid>
                                      </Typography>
                                    </FormGroup>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      borderTopLeftRadius: 10,
                                      borderBottomLeftRadius: 10,
                                    }}
                                    sm={8}
                                    className={classes.bodyTableCell}
                                    component="td"
                                    scope="row"
                                  >
                                    <DeleteIcon
                                      style={{ cursor: 'pointer' }}
                                      onClick={(e) => handleDeleteVideoPost(e, row)}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }}
                          </Draggable>
                        ) : null
                      )}{' '}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
        )}
      </Table>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid className={classes.videoDropZoneContainer} item sm={12} xs={12}>
        <VideoDropzone />
      </Grid>

      <Grid item sm={12} xs={12}>
        <Videos />
      </Grid>
    </Grid>
  );
};

export default Content;
