import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { NavLink } from 'react-router-dom';
import { logoutAction } from 'redux/actions/auth/logoutAction';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DescriptionIcon from '@material-ui/icons/Description';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Typography } from '@material-ui/core';
import theme from 'theme';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  logo: {
    color: 'black',
    textAlign: 'center',
    padding: theme.spacing(1),
  },
});


const Sidebar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { open, onClose } = props;

  const logout = () => {
    dispatch(logoutAction());
    history.push('/login');
  };

  const items = [
    { title: 'Dashboard', href: '/dashboard', icon: <DashboardIcon /> },
    { title: 'Content', href: '/content', icon: <DescriptionIcon />},
    { title: 'Members', href: '/members', icon: <PeopleIcon />},
    { title: 'Content Creators', href: '/creators', icon: <PeopleIcon />},
    { title: 'Sales', href: '/promo-codes', icon: <DescriptionIcon />},
  ];

  const listItemList = items.map((item) => (
    <ListItem button key={item.title} component={NavLink} to={item.href}>
        <ListItemIcon>
          {item.icon}
        </ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  ));

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div className={classes.list} role="presentation">
        <List>
          <Typography variant="h1" className={classes.logo}>
            Logo
          </Typography>
          <Divider />
          {listItemList}
          <Divider />
          <ListItem button onClick={logout} key="Logout">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;
