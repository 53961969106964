import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import TopBar from './components/TopBar';
import Sidebar from './components/Sidebar';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: theme.palette.background.default,
  },
  main: {
    height: 'auto',
    // padding: theme.spacing(3),
    paddingTop: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: 1650,
    marginLeft: 'auto',
    marginRight: 'auto',
    // padding: theme.spacing(0, 2),
  },
}));


function Dashboard({ children }) {
  const classes = useStyles();
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const handleToggleSidebar = () => setOpenSidebar(!openSidebar);

  return (
    <div className={classes.root}>
      <TopBar openSidebar={openSidebar} onToggleSidebar={handleToggleSidebar} />
      <Sidebar
        onClose={handleToggleSidebar}
        open={openSidebar}
        variant='temporary'
      />
      <main className={classes.main}>
        <Paper className={classes.paper} elevation={0}>
          {children}
        </Paper>
      </main>
    </div>
  );
}

export default Dashboard;
