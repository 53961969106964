import React, { useState } from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from '../courseFormStyle';
import LevelDetails from './LevelDetails';

const Level = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState('');

  const {
    id,
    ids,
    index,
    title,
    price,
    show,
    levels,
    content,
    setContent,
    setLevels,
    edit,
    handleDeleteLevel,
    handleDetailsChange,
    handleContentChange,
    onExpand,
    isExpanded,
    errorInpDuration,
    errorShDescription,
    errorLevelPrice,
    setChangedReps,
    reps,
  } = props;
  const classes = useStyles();

  const toggleLevelShow = (value) => {
    console.log('PROPS', value);

    if (edit) {
      // // TODO CHANGE VAR NAMES!!!!!!!
      const updatedEditLevels = levels.map((level) => {
        return level.id === value
          ? { ...level, show: !open }
          : level.ids === value
          ? { ...level, show: !open }
          : { ...level, show: !open };
        //return props.title === level.title ? { ...level, show: true } : level;
      });
      setOpen(!open);
      setLevels(updatedEditLevels);
      //setSelectedLevel(levels.find((m) => (m.id ? m.id === value : m.ids === value)));
    } else {
      const updatedLevels = levels.map((level) => {
        return level.ids === value ? { ...level, show: true } : { ...level, show: true };
        //return props.title === level.title ? { ...level, show: !level.show } : level;
      });
      console.log('updated', updatedLevels);
      setOpen(!open);
      setLevels(updatedLevels);
      //setSelectedLevel(levels.find((m) => m.ids === value));
    }
  };

  const level = levels.find((m) => (m.id ? m.id === id : m.ids === ids));

  console.log('LEVEL?', level);
  // const level = edit
  //   ? levels.find((level) => level.id === id)
  //   : levels.find((level) => level.ids === ids);
  const finalLevel = !edit && content.filter((item) => item.newLevelId === ids);

  const finalCreate =
    edit &&
    content.filter((item) =>
      //ids ? item.newLevelId === ids : item.levelId ? item.levelId === id : item.level === id
      ids ? item.newLevelId === ids : item.levelId ? item.levelId === id : item.level === id
    );
  console.log('FINALCREATE', finalCreate);
  console.log('FINALLEVEL', finalLevel);

  return (
    <Draggable
      draggableId={ids ? ids : String(id)}
      index={index}
      key={level.ids ? level.ids : level.id}
    >
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Grid container>
              <Grid item sm={1} className={classes.dragIconContainer}>
                <div {...provided.dragHandleProps}>
                  <DragIndicatorIcon className={classes.dragIcon} />
                </div>
              </Grid>
              <Grid item sm={10}>
                <Paper className={classes.level}>
                  <Grid
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleLevelShow(id ? id : ids)}
                    container
                    spacing={2}
                  >
                    <Grid item sm={4} xs={12}>
                      <Typography>{title}</Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography>{edit ? finalCreate.length : finalLevel.length} files</Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography>€ {price}</Typography>
                    </Grid>
                    <ArrowDropDownIcon className={classes.arrowDown} />
                  </Grid>
                  {open ? (
                    <LevelDetails
                      reps={reps}
                      edit={edit}
                      levels={levels}
                      level={level}
                      open={open}
                      content={content}
                      setContent={setContent}
                      handleDetailsChange={handleDetailsChange}
                      handleContentChange={handleContentChange}
                      errorInpDuration={errorInpDuration}
                      errorShDescription={errorShDescription}
                      errorLevelPrice={errorLevelPrice}
                      setChangedReps={setChangedReps}
                    />
                  ) : null}
                </Paper>
              </Grid>
              <Grid item sm={1} className={classes.delete}>
                <button className={classes.deleteButton} onClick={() => handleDeleteLevel(level)}>
                  <DeleteIcon />
                </button>
              </Grid>
            </Grid>
          </div>
        );
      }}
    </Draggable>
  );
};

export default Level;
