import axios from 'axios';

const rootEndpoint =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://back.sichameleon.com/api'
    : 'http://207.154.214.95:8124/api';

export const authEndpoint = axios.create({ baseURL: `${rootEndpoint}` });
authEndpoint.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

//export const acitvateEndpoint = axios.create({ baseURL: `${rootEndpoint}` });

export const customerEndpoint = axios.create({ baseURL: `${rootEndpoint}/users/` });
customerEndpoint.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const adminEndpoint = axios.create({ baseURL: `${rootEndpoint}/users/` });
adminEndpoint.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const creatorEndpoint = axios.create({ baseURL: `${rootEndpoint}/users/` });
creatorEndpoint.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const courseEndpoint = axios.create({ baseURL: `${rootEndpoint}/courses/` });
courseEndpoint.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const levelEndpoint = axios.create({ baseURL: `${rootEndpoint}/courses/` });
levelEndpoint.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const contentEndpoint = axios.create({ baseURL: `${rootEndpoint}/courses/` });
contentEndpoint.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
