import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { courseEndpoint, creatorEndpoint } from 'utils/api';

import discount from '../../assets/discount.svg';
import membersIcon from '../../assets/members.svg';
import idea from '../../assets/idea.svg';
import cap from '../../assets/mortarboard.svg';
import dots from '../../assets/dots.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 210,
    background: 'white',
    minHeight: 180,
    borderRadius: 10,
    boxShadow: '0px 3px 10px #00000017',
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 12,
  },
  number: {
    fontWeight: 'bold',
    fontSize: '44px',
    color: '#4B4B4B',
  },
  promoText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 18,
    maxWidth: 20,
    whiteSpace: 'nowrap',
  },
  noPromoCode: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 18,
    maxWidth: 20,
    whiteSpace: 'wrap',
  },
  promoCard: {
    background:
      'transparent linear-gradient(61deg, #033342 0%, #087090 100%) 0% 0% no-repeat padding-box',
    borderRadius: 10,
    minWidth: 225,
    marginLeft: 25,
    minHeight: 160,
    maxHeight: 160,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
    },
  },
  add: {
    float: 'right',
    color: 'white',
    paddingTop: 5,
    paddingRight: 30,
    background: 'transparent',
  },
  icon: {
    marginBottom: -20,
  },
}));

export default function SimpleCard() {
  const classes = useStyles();

  const [creators, setCreators] = useState([]);
  const [members, setMembers] = useState([]);
  const [categories, setCategories] = React.useState([]);
  const [bundles, setBundles] = useState([]);
  const [courses, setCourses] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const responseCat = await (await courseEndpoint.get('category/')).data.data;
      setCategories(responseCat);
      const responseMem = await (await creatorEndpoint.get('customer/')).data.data;
      setMembers(responseMem);
      const responseBun = await (await courseEndpoint.get('bundle/')).data.data;
      setBundles(responseBun);
      const responseCreators = await (await creatorEndpoint.get('content-creator/')).data.data;
      setCreators(responseCreators);
      const responseCourses = await (await courseEndpoint.get('course/')).data.data;
      setCourses(responseCourses);
      const responsePromos = await (await courseEndpoint.get('promo-code/')).data.data;
      setPromoCodes(responsePromos);
    }
    fetchData();
  }, []);

  return (
    <>
      <Grid container direction="row" justify="space-around" alignItems="center">
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Courses
            </Typography>
            <Typography variant="h3" className={classes.number}>
              {courses.length}
            </Typography>
            <Typography align="right">
              <img className={classes.icon} src={cap} alt="" />
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Categories
            </Typography>
            <Typography variant="h3" className={classes.number}>
              {categories.length}
            </Typography>
            <Typography align="right">
              <img className={classes.icon} src={dots} alt="" />
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Members
            </Typography>
            <Typography variant="h3" className={classes.number}>
              {members.length}
            </Typography>
            <Typography align="right">
              <img className={classes.icon} src={membersIcon} alt="" />
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Creators
            </Typography>
            <Typography variant="h3" className={classes.number}>
              {creators.length}
            </Typography>
            <Typography align="right">
              <img className={classes.icon} src={idea} alt="" />
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.promoCard} elevation={0}>
          <CardContent>
            <Typography className={classes.title} gutterBottom style={{ color: 'white' }}>
              Active Promo Codes
            </Typography>
            {promoCodes.length > 0 ? (
              promoCodes.slice(0, 2).map((promo, i) => (
                <Typography key={i} className={classes.promoText}>
                  {promo.title}
                </Typography>
              ))
            ) : (
              <Typography className={classes.noPromoCode}>No available promo codes</Typography>
            )}

            <Typography className={classes.title} color="textSecondary" gutterBottom>
              <img style={{ marginTop: '-100px', marginLeft: '165px' }} src={discount} alt="" />
            </Typography>
            <Typography>
              <Button className={classes.add} component={Link} to="/create-promo">
                + Add New
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
