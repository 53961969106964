import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { PreviewWidget, ReviewsModal } from 'components';
import { courseEndpoint, creatorEndpoint } from 'utils/api';

import { editCourse, editDraftCourse } from 'redux/actions/courses/fetchCourseActions';
import { useDispatch } from 'react-redux';


const CoursesList = () => {
  const dispatch = useDispatch();
  
  const [courses, setCourses] = React.useState([]);
  const [creators, setCreators] = React.useState([]);
  const [ratings, setRatings] = React.useState([]);

  const [selectedRatings, setSelectedRatings] = React.useState([]);
  const [selectedCourse, setSelectedCourse] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const deleteCourse = async (id) => {
    try {
      await courseEndpoint.delete(`course/${id}`);
      window.location.reload();
    } catch (error) {
      console.log('Delete Course API error!');
    }
  };

  const editedCourse = (id, is_draft) => {
    if (is_draft)
      dispatch(editDraftCourse(id));
    else
      dispatch(editCourse(id));
  };

  useEffect(() => {
    async function fetchData() {
      const draftCourses = await (await courseEndpoint.get('course/draft')).data.data;
      const courseData = await (await courseEndpoint.get('course/')).data.data.concat(draftCourses);
      const creatorData = await (await creatorEndpoint.get('content-creator/')).data.data;
      const ratingData = await (await courseEndpoint.get('course-rating/')).data.data;

      setCourses(courseData);
      setCreators(creatorData);
      setRatings(ratingData);
    }
    fetchData();
  }, []);

  const getCreatorName = (id) => {
    for (const creator of creators) {
      if (creator.id === id) {
        return `${creator.user.first_name} ${creator.user.last_name}`;
      }
    }
  };

  const onClickReview = async (course) => {
    const validRatings = [];
    for (const rating of ratings) {
      if (rating.course === course.id) {
        validRatings.push(rating);
      }
    }
    setSelectedRatings(validRatings);
    setSelectedCourse(course);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRatings([]);
    setSelectedCourse([]);
    setOpen(false);
  };

  const getReviewCount = (id) => ratings.filter((rating) => rating.course === id).length;

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {courses.map((course, i) => (
          <Grid key={i} item md={3} sm={6} xs={12}>
            <PreviewWidget
              key={i}
              title={course.title}
              creator={getCreatorName(course.content_creator)}
              description={course.description}
              price={course.price}
              image={course.promo_image}
              reviewCount={getReviewCount(course.id)}
              onClickReview={() => onClickReview(course)}
              delete={() => deleteCourse(course.id)}
              edit={() => editedCourse(course.id, course.is_draft)}
              draft={course.is_draft}
            />
          </Grid>
        ))}
      </Grid>
      <ReviewsModal
        open={open}
        reviews={selectedRatings}
        course={selectedCourse}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
};

export default CoursesList;
