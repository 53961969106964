import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Button, Typography, CircularProgress, Modal, Divider } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { contentEndpoint, courseEndpoint, levelEndpoint } from 'utils/api';
import Box from '@material-ui/core/Box';
import Course from './components/Course';
import AddNewLevelInput from './components/AddNewLevelInput';
import LevelsHeading from './components/LevelsHeading';
import LevelList from './components/LevelList';
import useStyles from './courseFormStyle';
import { indexOf } from 'lodash';
import Alert from '@material-ui/lab/Alert';

const CourseForm = (props) => {
  const initialCourseData = {
    title: props.location.prePopulate ? props.location.prePopulate.title : '',
    description: props.location.prePopulate ? props.location.prePopulate.description : '',
    promo_image: props.location.prePopulate ? props.location.prePopulate.promo_image : '',
    preview_video: props.location.prePopulate ? props.location.prePopulate.preview_video : '',
    content_creator: props.location.prePopulate
      ? props.location.prePopulate.content_creator.id
      : '',
    category: props.location.prePopulate ? props.location.prePopulate.category.id : '',
    bundle: props.location.prePopulate ? props.location.prePopulate.bundle : '',
    user_guide: props.location.prePopulate ? props.location.prePopulate.user_guide : '',
    price: props.location.prePopulate ? props.location.prePopulate.price : '',
    discount: props.location.prePopulate ? props.location.prePopulate.discount : 0,
  };

  const [course, setCourse] = useState(initialCourseData);
  const [levels, setLevels] = useState(
    props.location.prePopulate ? props.location.prePopulate.level : []
  );
  const [content, setContent] = useState([]);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newLevelTitle, setNewLevelTitle] = useState('');
  const [sequenceNumber, setSequenceNumber] = useState(1);
  const [failedStuff, setFailedStuff] = useState([]);
  const [isDraft, setIsDraft] = useState('');
  const [draft, setDraft] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFail, setAlertFail] = useState(false);
  const [alertReject, setAlertReject] = useState(false);
  const [addedFiles, setAddedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorUserGuide, setErrorUserGuide] = useState(false);
  const [errorCategory, setErrorCategory] = useState(false);
  const [errorContentCreator, setErrorContentCreator] = useState(false);
  const [errorPromoImage, setErrorPromoImage] = useState(false);
  const [errorInpDuration, setErrorInpDuration] = useState(false);
  const [errorShDescription, setErrorShDescription] = useState(false);
  const [errorLevelPrice, setErrorLevelPrice] = useState(false);
  const [errorLevel, setErrorLevel] = useState(false);
  const [changedReps, setChangedReps] = useState([]);
  const [reps, setReps] = useState({});

  const classes = useStyles();
  console.log("prepopulate", props.location.prePopulate);

  useEffect(() => {
    console.log(course.promo_image);

    if (props.location.prePopulate) {
      setEdit(true);

      if (props.location.prePopulate.is_draft === true) {
        setIsDraft(true);
      }

      for (const level of props.location.prePopulate.level) {
        const payload = {
          ...level,
          course: course.id,
          discount: level.discount || 0, // Avoid null
        };
      }
      const contentList = props.location?.prePopulate?.level?.reduce((contents, currentLevel) => {
        contents = [...contents, ...currentLevel.content];
        return contents;
      }, []);
      setContent((prev) => (!!contentList ? [...prev, ...contentList] : []));
    }
  }, []);

  const addFailed = (item, err) => setFailedStuff((prev) => [...prev, { item: item, error: err }]);

  console.log('FAILED', failedStuff);

  const updateIsUploaded = (item, newVal) => {
    let c = content.slice();

    for (let i = 0; i < content.length; i++) {
      // For already existing level
      if (item.levelId !== null) {
        if (content[i].levelId == item.levelId && content[i].title === item.title) {
          c[i].isUploaded = newVal;
          setContent(c);
          return;
        }
      } else {
        // For new level
        if (content[i].newLevelId == item.newLevelId && content[i].title === item.title) {
          c[i].isUploaded = newVal;
          setContent(c);
          return;
        }
      }
    }
  };

  console.log('REPS', reps);

  const addNewLevel = (title) => {
    const newLevel = {
      ids: uuidv4(),
      show: false,
      title: title,
      description: '',
      price: null,
      discount: null,
      sequence_number: levels.length + 1,
      duration: '',
    };

    // if (props.courseData.level.length > 0) {
    //   setLevels(props.courseData.level.map((level) => { return {
    //     id: uuidv4(), show: true, title : level.title, price: level.price
    //   }} ));
    //   console.log(props.courseData.level,'levels')
    // }

    if (newLevelTitle) {
      setLevels((prevLevels) => [...prevLevels, newLevel]);
      setNewLevelTitle('');
      setSequenceNumber((prevNumber) => prevNumber + 1);
    }
  };

  const handleDeleteLevel = (level) => {
    if (level.ids) {
      setLevels(levels.filter((l) => l.ids !== level.ids));
    } else {
      setLevels(levels.filter((l) => l.id !== level.id));
      courseEndpoint.delete(`level/${level.id}/`);
    }
  };

  const handleDetailsChange = (value, key, selectedLevel) => {
    const update = levels.map((level) => {
      if (level.ids) {
        return level.ids === selectedLevel.ids ? { ...level, [key]: value } : level;
      } else {
        return level.id === selectedLevel.id ? { ...level, [key]: value } : level;
      }
    });
    setLevels(update);
  };

  const handleCourseChange = (value, key) => {
    setCourse({ ...course, [key]: value });
  };

  const handleContentChange = (newFiles) => {
    setContent((prev) => prev.concat(newFiles));
  };

  const createOrUpdateDraftCourse = async () => {
    const formData = new FormData();

    formData.append('title', course.title);
    formData.append('description', course.description);
    formData.append('price', course.price);
    formData.append('discount', course.discount);
    formData.append('user_guide', course.user_guide);
    formData.append('content_creator', course.content_creator);
    formData.append('category', course.category);
    if (course.promo_image !== null) {
      formData.append('promo_image', course.promo_image);
    }
    if (course.preview_video !== null) {
      formData.append('preview_video', course.preview_video);
    }


    if (props.location.prePopulate) {
      if (course.promo_image !== null)
        if (props.location.prePopulate.promo_image?.substring(40) === course.promo_image.name)
          formData.delete("promo_image");
      if (course.preview_video !== null)
        if (props.location.prePopulate.preview_video?.substring(47) === course.preview_video.name)
          formData.delete("preview_video");
    }

    if (course.bundle) {
      formData.append('bundle', course.bundle);
    }
    // THE DRAFT COURSE IS EDITED
    if (props.location.prePopulate) {
      const response = await courseEndpoint.patch(
        `course/${props.location.prePopulate.id}/`,
        formData
      );

      course.id = response.data.data.id;
      return course.id;
    } else {
      try {
        const response = await courseEndpoint.post('course/draft/save/', formData);

        console.log('RESPONSE', response);
        course.id = response.data.data.id;
        return course.id;
      } catch (error) {
        console.log(`${error}`);
      }
    }
  };

  // const createOrUpdateDraftLevel = async (id) => {
  //   try {
  //     for (const level of levels) {
  //       const payload = {
  //         ...level,
  //         course: id,
  //         discount: level.discount || 0, // Avoid null
  //       };

  //       const response2 = await levelEndpoint.post('level/draft/save/', payload);
  //       level.id = response2.data.data.id;
  //       //return level.id
  //       console.log('LEVELID', payload);
  //     }
  //   } catch (error) {
  //     console.log(`${error}`);
  //   }
  // };

  console.log('COURSE', course);
  const createOrUpdateCourse = async () => {
    const formData = new FormData();
    formData.append('title', course.title);
    formData.append('description', course.description);
    formData.append('user_guide', course.user_guide);
    formData.append('price', course.price);
    formData.append('discount', course.discount);
    formData.append('content_creator', course.content_creator);
    formData.append('category', course.category);
    if (course.promo_image !== null && course.promo_image instanceof File) {
      formData.append('promo_image', course.promo_image);
    }
    if (course.preview_video !== null && course.preview_video instanceof File) {
      formData.append('preview_video', course.preview_video);
    }

    if (course.bundle) {
      formData.append('bundle', course.bundle);
    }

    if (props.location.prePopulate) {
      //EDIT
      // If prePopulate.promo_image isn't set and if course.promo_image isn't set,
      // Display modal for user to add image.
      // Stop saving.
      
      if (course.promo_image !== null)
        if (props.location.prePopulate.promo_image?.substring(40) === course.promo_image.name)
          formData.delete("promo_image");
      if (course.preview_video !== null)
        if (props.location.prePopulate.preview_video?.substring(47) === course.preview_video.name)
          formData.delete("preview_video");

      // Final Step:
      // If the video isn't a draft, patch it
      if (props.location.prePopulate.id && !props.location.prePopulate.is_draft) {
        const response = await courseEndpoint.patch(
          `course/${props.location.prePopulate.id}/`,
          formData
        );
        course.id = response.data.data.id;
        return course.id;
      }

      // If the video is a draft, convert it to a live one.
      if (props.location.prePopulate.id && props.location.prePopulate.is_draft) {
        const response = await courseEndpoint.patch(
          `course/${props.location.prePopulate.id}/draft/convert/`,
          formData
        );
        course.id = response.data.data.id;
        console.log('RESPOSNE', response);
      }
    } else {
      // CREATE
      try {
        const response = await courseEndpoint.post('course/', formData);
        course.id = response.data.data.id;
        return course.id;
      } catch (error) {
        console.log(`${error}`);
      }
    }
  };

  const createOrUpdateLevels = async (id) => {
    // TO DO: Implement patching here.
    // Ask back end devs to implement uuid for each level.
    // What if we never post but login and then try to edit level directly?
    // Then you can filter by course and then by uuid. If level found then you're
    // editing, so patch. Otherwise (so if not found) use post.

    console.log('LEVELS', levels);

    // For every level...
    for (const level of levels) {
      // create its details...
      const payload = {
        ...level,
        course: id,
        discount: level.discount || 0, // Avoid null
      };

      // Now check if there's an id.
      // If there is, it means the level is a pre-existing one.
      if (level.id) {
        await levelEndpoint.patch(`level/${level.id}/`, payload);
      } else {
        const response = await levelEndpoint.post('level/', payload);
        level.id = response.data.data.id;
      }
    }
  };

  console.log('CHANGED', changedReps);

  // Create or update the content for a specific level
  const createOrUpdateContent = async (id, ids) => {
    console.log('IDCONSOLE', id);
    console.log('IDSCONSOLE', ids);
    // TO DO: Implement patching here.
    // Ask back end devs to implement uuid for each level.
    // What if we never post but login and then try to edit level directly?
    // Then you can filter by course and then by uuid. If level found then you're
    // editing, so patch. Otherwise (so if not found) use post.
    if (props.location.prePopulate) {
      // Change order for already existing content
      // Find which levels pre-existed (id exists)

      const preexistingLevels = levels.filter((item) => item.id);
      for (const l of preexistingLevels) {
        if (l.content === undefined) continue;

        if (l.content.length > 0) {
          l.content.forEach(async (c) => {
            await courseEndpoint.patch(`content/${c.id}/`, { sequence_number: c.sequence_number });
          });
        }
      }

      // Send the repetitions for the already existing content.
      // for (const item of changedReps) {
      //   if (item.id) {
      //     const patchedFile = {
      //       repetitions: item.repetitions,
      //     };
      //     const formData = new FormData();

      //     formData.append('repetitions', patchedFile.repetitions);

      //     await levelEndpoint.patch(`content/${item.id}/`, formData);
      //     // if (content.indexOf(item) === content.length - 1) {
      //     //   setIsLoading(false);
      //     // }
      //   }
      // }
    }

    // All new content has a levelId or a newLevelId field. Filter by that.
    // This is the content to be uploaded.
    const finalContent = content.filter((c) => {
      if (c.hasOwnProperty('levelId') && c.levelId !== null) {
        if (c.levelId == id) return true;
      }

      if (c.hasOwnProperty('newLevelId') && c.newLevelId !== null) {
        if (c.newLevelId == ids) return true;
      }

      return false;
    });

    console.log('FINAL', finalContent);
    // if (finalContent.length === 0) {
    //   setIsLoading(false);
    //   setOpen(true);
    // }

    for (const video of finalContent) {
      if (video.isUploaded) return;
      console.log('VIDEO', video);
      if (video.uuid && reps[video.uuid]) {
        video.repetitions = reps[video.uuid];
      }

      const finalFile = {
        title: video.title,
        short_description: video.short_description,
        video: video.video,
        level: id,
        repetitions: video.repetitions,
        duration: video.duration,
        sequence_number: video.sequence_number,
      };
      const formData = new FormData();
      formData.append('title', finalFile.title);
      formData.append('short_description', finalFile.short_description);
      formData.append('video', finalFile.video);
      formData.append('level', finalFile.level);
      formData.append('repetitions', finalFile.repetitions);
      formData.append('duration', finalFile.duration);
      formData.append('sequence_number', finalFile.sequence_number);

      try {
        await levelEndpoint.post('content/', formData);
        updateIsUploaded(video, true);
      } catch (err) {
        addFailed(video, err);
      }
    }
  };

  const validate = () => {
    // For example to check if title does not exist in course:
    if (!course.title) {
      // here return some error, show text on screen
      // you can use old concorde code as reference
    }
    // same for levels
    // if(!level.duration)
    // but first filter the right level
  };

  const onClick = async () => {
    if (
      course.title === '' ||
      course.description === '' ||
      course.user_guide === '' ||
      course.price === '' ||
      course.content_creator === '' ||
      course.category === '' ||
      course.promo_image === '' ||
      errorLevel !==
        levels
          .map((lev) => {
            if (lev.description === '' || lev.duration === '' || lev.price === null) {
              return true;
            } else {
              return false;
            }
          })
          .every((v) => v === true)
    ) {
      if (course.title === '') {
        setErrorTitle(true);
      }
      if (course.description === '') {
        setErrorDescription(true);
      }
      if (course.user_guide === '') {
        setErrorUserGuide(true);
      }
      if (course.price === '') {
        setErrorPrice(true);
      }
      if (course.content_creator === '') {
        setErrorContentCreator(true);
      }
      if (course.category === '') {
        setErrorCategory(true);
      }
      if (course.promo_image === '') {
        setErrorPromoImage(true);
      }
      levels.map((lev) => {
        if (lev.description === '' || lev.duration === '' || lev.price === null) {
          if (lev.description === '') {
            setErrorShDescription(true);
          }
          if (lev.duration === '') {
            setErrorInpDuration(true);
          }
          if (lev.price === null) {
            setErrorLevelPrice(true);
          }
        }
      });
    } else {
      setIsLoading(true);
      setFailedStuff([]);
      // Some notes:
      // * Make sure to get course id before submitting to levels and content.
      // * API endpoints: Course component values are for course endpoint,
      // Level for level endpoint, and Content for content endpoint.

      // Add validation / error checking here.
      // validate();

      await createOrUpdateCourse();
      if (course.id) {
        await createOrUpdateLevels(course.id);
        // After this function exits, ALL levels have ID
        let promises = levels.map((level) => {
          return createOrUpdateContent(level.id, level.ids);
        });
        // Promise.allSettled(promises).then((results) => {
        //   console.log('ALL UPLOAD PROMISES COMPLETE');
        //   if (course.is_draft) setIsDraft(false);
        //   setIsLoading(false);
        //   setOpen(true);
        //   if (failedStuff.length === 0) setAlertSuccess(true);
        //   else setAlertFail(true);
        // });
        await Promise.allSettled(promises);

        const contentBeforeRepsUpdate = content.map((c) => {
          if (c.hasOwnProperty('uuid')) {
            if (reps.hasOwnProperty(c.uuid)) c.repetitions = reps[c.uuid];
            return { ...c };
          } else {
            if (reps.hasOwnProperty(c.id)) {
              c.repetitions = reps[c.id];
              return { ...c };
            }
          }
          return c;
        });
        setContent(contentBeforeRepsUpdate);

        let repsPromises = [];
        for (const c of contentBeforeRepsUpdate) {
          if (c.hasOwnProperty('id') && reps.hasOwnProperty(c.id)) {
            const cData = new FormData();
            cData.append('repetitions', Number(reps[c.id]));
            repsPromises.push(contentEndpoint.patch(`content/${c.id}/`, cData));
          }
        }

        await Promise.all(repsPromises);

        console.log('ALL UPLOAD PROMISES COMPLETE');
        if (course.is_draft) setIsDraft(false);
        setIsLoading(false);
        setOpen(true);
        if (failedStuff.length === 0) setAlertSuccess(true);
        else setAlertFail(true);
      } else {
        console.log('Error: course ID not assigned!');
        setIsLoading(false);
        setOpen(true);
        setAlertReject(true);
      }
    }
  };

  const onSaveDraft = async () => {
    setIsLoading(true);
    setFailedStuff([]);

    setDraft(true);
    await createOrUpdateDraftCourse();
    if (course.id) {
      await createOrUpdateLevels(course.id);
      let promises = levels.map((level) => {
        return createOrUpdateContent(level.id, level.ids);
      });
      await Promise.allSettled(promises);

      const contentBeforeRepsUpdate = content.map((c) => {
        if (c.hasOwnProperty('uuid')) {
          if (reps.hasOwnProperty(c.uuid)) c.repetitions = reps[c.uuid];
          return { ...c };
        } else {
          if (reps.hasOwnProperty(c.id)) {
            c.repetitions = reps[c.id];
            return { ...c };
          }
        }
        return c;
      });
      setContent(contentBeforeRepsUpdate);

      let repsPromises = [];
      for (const c of contentBeforeRepsUpdate) {
        if (c.hasOwnProperty('id') && reps.hasOwnProperty(c.id)) {
          const cData = new FormData();
          cData.append('repetitions', Number(reps[c.id]));
          repsPromises.push(contentEndpoint.patch(`content/${c.id}/`, cData));
        }
      }

      await Promise.all(repsPromises);
      setIsLoading(false);
      setOpen(true);
      if (failedStuff.length === 0) setAlertSuccess(true);
      else setAlertFail(true);

      // .then((results) => {
      //   setIsLoading(false);
      //   setOpen(true);
      //   if (failedStuff.length === 0) setAlertSuccess(true);
      //   else setAlertFail(true);
      // });
    } else {
      console.log('Error: course ID not assigned!');
      setIsLoading(false);
      setOpen(true);
      setAlertReject(true);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className={classes.boxItems}>
            <Typography
              align="center"
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ marginBottom: '1em' }}
            >
              {alertSuccess ? (
                <Alert severity="success">
                  {draft
                    ? edit
                      ? 'Draft was edited'
                      : 'Draft was saved'
                    : edit
                    ? 'Course was edited'
                    : 'Course was created'}
                </Alert>
              ) : null}
              {alertFail ? (
                <Alert severity="error">
                  {failedStuff.map((j) => (
                    <>
                      <div>{j.item.title} failed to be uploaded!!</div>
                      <Divider />
                    </>
                  ))}
                </Alert>
              ) : null}
              {alertReject ? <Alert severity="error">Error: Course id not assigned!</Alert> : null}
            </Typography>
            <div className={classes.boxButtons}>
              <Button onClick={closeModal}>Done</Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Course
        course={course}
        handleCourseChange={handleCourseChange}
        courseData={initialCourseData}
        errorTitle={errorTitle}
        errorDescription={errorDescription}
        errorCategory={errorCategory}
        errorContentCreator={errorContentCreator}
        errorPrice={errorPrice}
        errorPromoImage={errorPromoImage}
        errorUserGuide={errorUserGuide}
      />
      <Grid container spacing={2} style={{ marginBottom: 8 }}>
        <Grid item sm={12}>
          <Typography className={classes.details} variant="body1">
            Course Structure
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography className={classes.caption} variant="body1">
            Add new levels or Import Course structure from another course
          </Typography>
        </Grid>
      </Grid>

      <AddNewLevelInput
        newLevelTitle={newLevelTitle}
        setNewLevelTitle={setNewLevelTitle}
        addNewLevel={addNewLevel}
      />
      {levels.length && !edit ? (
        <LevelsHeading />
      ) : edit && props.location.prePopulate.level.length ? (
        <LevelsHeading />
      ) : null}
      <LevelList
        setAddedFiles={setAddedFiles}
        levels={levels}
        setLevels={setLevels}
        setContent={setContent}
        content={content}
        edit={edit}
        handleDetailsChange={handleDetailsChange}
        handleDeleteLevel={handleDeleteLevel}
        handleContentChange={handleContentChange}
        errorInpDuration={errorInpDuration}
        errorShDescription={errorShDescription}
        errorLevelPrice={errorLevelPrice}
        setChangedReps={setChangedReps}
        reps={reps}
      />

      <Grid item sm={12} xs={12} align="right">
        {(!edit || (edit && isDraft)) && (
          <Button className={classes.saveBtn} onClick={onSaveDraft}>
            {isDraft ? 'Edit Draft' : 'Save Draft'}
          </Button>
        )}
        {isLoading ? (
          <CircularProgress size={45} thickness={4} disableShrink className={classes.circular} />
        ) : (
          <Button className={classes.publishBtn} onClick={onClick} type="submit">
            {edit ? 'Save Changes' : 'Publish Course'}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CourseForm;
