import { common, red } from '@material-ui/core/colors';


const palette = {
  common: {
    white: common['white'],
    black: common['black'],
  },
  primary: {
    main: '#223',
    light: '#484848',
    dark: '#000000'
  },
  background: {
    paper: '#F5F7F7',
    default: '#F5F7F7',
  },
  text: {
    primary: '#4B4B4B',
    secondary: '#484848',
    links: '#707070'
  },
  error: {
    main: red[500],
    dark: red[700],
    light: red[300],
  },
};

export default palette;
