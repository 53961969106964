import React from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import useStyles from '../courseFormStyle';

const CustomSelectField = (props) => {
  // TODO: Implement placeholder.
  // quick but not elegant idea:
  // 1 add custom id
  // 2 inject some vanilla js which get element by id
  // and then set attribute for placeholder.
  const classes = useStyles();
  const { options } = props;
  return (
    <FormControl variant="filled" className={classes.formControl1}>
      <InputLabel id="whatever">{props.label}</InputLabel>
      <Select
        labelId="whatever"
        className={classes.inputSelectCustom}
        disableUnderline
        inputProps={{
          classes: {
            root: props.error ? classes.inputRootCustomError : classes.inputRootCustom,
          },
        }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectField;
