import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SimpleCard from './Card';
import Reviews from './Reviews';
import Earnings from './Earnings';
import Featured from './Featured';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50,
    height: 'auto',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    marginLeft: 0,
    marginRight: 0,
  },
  formTitle: {
    marginBottom: theme.spacing(4),
    fontSize: '20px',
    marginLeft: 40,
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      marginLeft: 35,
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const store = useStore();
  const history = useHistory();

  const getUsername = () => {
    try {
      return store.getState().login.account.username;
    } catch (error) {
      history.push('/login');
    }
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.formTitle} variant="h1">
        Welcome {getUsername()}
      </Typography>
      <SimpleCard />
      <Grid container direction="row">
        <Grid container>
          <Grid item sm={12} md={4} lg={4} xs={12}>
            <Reviews />
          </Grid>
          <Grid item sm={12} md={3} lg={3} xs={12}>
            <Earnings />
          </Grid>
          <Grid item sm={12}  md={5} lg={5} xs={12}>
            <Featured />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
