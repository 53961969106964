import { SET_CURRENT_USER, UPDATE_CURRENT_USER } from 'redux/types';


const initialState = {
  validToken: false,
  user: {},
};


export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        validToken: Boolean(action.payload),
        user: action.payload,
        account: action.account,
        tokenExp: action.tokenExp,
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        validToken: Boolean(action.payload),
        user: action.payload,
        tokenExp: action.tokenExp,
      };
    default:
      return state;
  }
}
