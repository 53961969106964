import { EDIT_BUNDLE } from 'redux/types';

export default (state = null, action) => {
  switch (action.type) {
    case EDIT_BUNDLE:
      return {
        ...state,
        bundle: action,
      };
    default:
      return state;
  }
};
