// General
export const GET_ERRORS = 'GET_ERRORS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FP_ERRORS = 'FP_ERRORS';

// Example
export const INCREMENT = 'INCREMENT';
export const DECREASE = 'DECREASE';

// User
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

// Admin
export const FETCH_ADMINS_START = 'FETCH_ADMINS_START';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const ADMIN_SELECTED = 'ADMIN_SELECTED';
export const DELETE_ADMIN = 'DELETE_ADMIN';

// Customer
export const FETCH_CUSTOMERS_START = 'FETCH_CUSTOMERS_START';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

// Creator
export const FETCH_CREATORS_START = 'FETCH_CREATORS_START';
export const FETCH_CREATORS_SUCCESS = 'FETCH_CREATORS_SUCCESS';
export const FETCH_CREATORS_FAILURE = 'FETCH_CREATORS_FAILURE';
export const EDIT_CREATOR = 'EDIT_CREATOR';
export const CREATOR_SELECTED = 'CREATOR_SELECTED';
export const DELETE_CREATOR = 'DELETE_CREATOR';

// Course

export const FETCH_COURSES_START = 'FETCH_COURSES_START';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';
export const EDIT_COURSE = 'EDIT_COURSE';
export const COURSE_SELECTED = 'COURSE_SELECTED';
export const DELETE_COURSE = 'DELETE_COURSE';

// Course

export const FETCH_BUNDLES_START = 'FETCH_BUNDLES_START';
export const FETCH_BUNDLES_SUCCESS = 'FETCH_BUNDLES_SUCCESS';
export const FETCH_BUNDLES_FAILURE = 'FETCH_BUNDLES_FAILURE';
export const EDIT_BUNDLE = 'EDIT_BUNDLE';
export const BUNDLE_SELECTED = 'BUNDLE_SELECTED';
export const DELETE_BUNDLE = 'DELETE_BUNDLE';

//Promos
export const FETCH_PROMOS_START = 'FETCH_PROMOS_START';
export const FETCH_PROMOS_SUCCESS = 'FETCH_PROMOS_SUCCESS';
export const FETCH_PROMOS_FAILURE = 'FETCH_PROMOS_FAILURE';
export const EDIT_PROMO = 'EDIT_PROMO';
export const PROMO_SELECTED = 'PROMO_SELECTED';
export const DELETE_PROMO = 'DELETE_PROMO';


// Categories
export const SELECT_CATEGORY = 'SELECT_CATEGORY';

// Modal
export const SHOW_MODAL = 'SHOW MODAL';
export const HIDE_MODAL = 'HIDE MODAL';
