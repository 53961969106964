import React from 'react';
import { Formik, Field } from 'formik';
import {
  makeStyles,
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { TextInput, SearchInput } from 'utils/form/components';
import { CategoryItem } from 'components';
import { courseEndpoint } from 'utils/api';
import SearchIcons from './SearchIcons';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 23,
  },
  preview: {
    marginLeft: theme.spacing(1),
    marginBottom: 30,
    color: '#033342',
  },
  btn: {
    marginTop: theme.spacing(3),
    padding: 10,
    float: 'right',
    borderRadius: 10,
    width: 161,
    height: 45,
    marginBottom: 40,
  },
  inputRoot: {
    marginBottom: 40,
  }
}));

const CategoryForm = (props) => {
  const classes = useStyles();
  const { handleClose } = props;

  const handleSubmit = async (values) => {
    try {
      await courseEndpoint.post('category/', values);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    title: '',
    icon: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} className={classes.form}>
          <DialogTitle disableTypography>
            <Typography className={classes.title} variant="h2">
              New Category
            </Typography>
          </DialogTitle>
          <DialogContent>

            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <Field component={TextInput} placeholder="Title" name="title" className={classes.inputRoot}/>
                <Field component={SearchInput} placeholder="Icon" name="icon" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography className={classes.preview}>Preview</Typography>
                <CategoryItem icon={props.values.icon} title={props.values.title} />
              </Grid>
            </Grid>

            <Grid style={{ marginTop: 35 }} container spacing={1}>
              <Grid item sm={12} xs={12}>
                <SearchIcons
                  search={props.values.icon}
                  setIconName={(v) => props.setFieldValue('icon', v.key)}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Button  size="large" className={classes.btn}  type="submit">
                  Save Category
                </Button>
              </Grid>
            </Grid>

          </DialogContent>
        </form>
      )}
    </Formik>
  );
};

export default CategoryForm;
