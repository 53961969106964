import { combineReducers } from 'redux';
import addReducer from './example/exampleReducer';
import setCategoryReducer from './categories/setCategoryReducer';
import loginReducer from './auth/loginReducer';
import forgotPasswordReducer from './auth/forgotPasswordReducer';

import fetchAdminsReducer from './admins/fetchAdminsReducer';
import editAdminReducer from './admins/editAdminReducer';
import deleteAdminReducer from './admins/deleteAdminReducer';
import selectAdminReducer from './admins/selectAdminReducer';

import fetchCustomersReducer from './customers/fetchCustomersReducer';
import editCustomerReducer from './customers/editCustomerReducer';
import deleteCustomerReducer from './customers/deleteCustomerReducer';
import selectCustomerReducer from './customers/selectCustomerReducer';

import toggleModalReducer from './UI/toggleModalReducer';

import fetchCreatorsReducer from './creators/fetchCreatorsReducer';
import editCreatorReducer from './creators/editCreatorReducer';
import deleteCreatorReducer from './creators/deleteCreatorReducer';
import selectCreatorReducer from './creators/selectCreatorReducer';

import fetchCoursesReducer from './courses/fetchCoursesReducer';
import editCourseReducer from './courses/editCourseReducer';
import deleteCourseReducer from './courses/deleteCourseReducer';
import selectCourseReducer from './courses/selectCourseReducer';
import editPromoReducer from './promo/editPromoReducer';
import fetchPromosReducer from './promo/fetchPromosReducer';
import deletePromoReducer from './promo/deletePromoReducer';
import selectPromoReducer from './promo/selectPromoReducer';
import fetchBundlesReducer from './bundles/fetchBundlesReducer';
import editBundleReducer from './bundles/editBundleReducer';
import deleteBundleReducer from './bundles/deleteBundleReducer';
import selectBundleReducer from './bundles/selectBundleReducer';


const rootReducer = combineReducers({
  login: loginReducer,
  example: addReducer,
  forgotPassword: forgotPasswordReducer,
  category: setCategoryReducer,

  admins: fetchAdminsReducer,
  editAdmin: editAdminReducer,
  deleteAdmin: deleteAdminReducer,
  selectAdmin: selectAdminReducer,

  customers: fetchCustomersReducer,
  editCustomer: editCustomerReducer,
  deleteCustomer: deleteCustomerReducer,
  selectCustomer: selectCustomerReducer,

  creators: fetchCreatorsReducer,
  editCreator: editCreatorReducer,
  deleteCreator: deleteCreatorReducer,
  selectCreator: selectCreatorReducer,

  courses: fetchCoursesReducer,
  editCourse: editCourseReducer,
  deleteCourse: deleteCourseReducer,
  selectCourse: selectCourseReducer,

  bundles: fetchBundlesReducer,
  editBundle: editBundleReducer,
  deleteBundle: deleteBundleReducer,
  selectBundle: selectBundleReducer,

  promos: fetchPromosReducer,
  editPromo: editPromoReducer,
  deleteProm: deletePromoReducer,
  selectPromo: selectPromoReducer,

  toggleModal: toggleModalReducer,

});

export default rootReducer;
