import React from 'react';
import { Container, makeStyles, Paper, Typography, Link, Button, Grid } from '@material-ui/core';
import { forgotPassword } from 'redux/actions/auth/forgotPasswordAction';
import { useDispatch, useStore } from 'react-redux';
import * as validations from 'utils/form/validations';
import { TextInput } from 'utils/form/components';
import { Formik, Field } from 'formik';
import * as yup from 'yup';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 500,
    backgroundColor: 'transparent',
    padding: theme.spacing(4),
  },
  emailField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  backToLogin: {
    marginTop: theme.spacing(2),
  }
}));


const ForgotPassword = () => {
  const classes = useStyles();
  const store = useStore();
  const dispatch = useDispatch();

  let submitMessage = '';
  const error = store.getState().forgotPassword.error;

  const initialValues = {
    email: '',
  };

  const validationSchema = yup.object({
    email: validations.email,
  });

  const getErrorMessage = () => {
    try {
      return store.getState().forgotPassword.response.data.data.detail;
    } catch (error) {
      return 'Error: please check your email.';
    }
  };

  const handleSubmit = async (values) => {
    submitMessage = '';
    await dispatch(forgotPassword(values));

    if (!error) {
      submitMessage = 'Please check email for reset link.';
    } else {
      submitMessage = getErrorMessage();
    }
  };

  return (
    <Container className={classes.container}>
      <Paper  elevation={0}  className={classes.paper} >
        <Typography className={classes.heading} align='center' gutterBottom variant="h2">
          Forgot your password ?
        </Typography>
        <Typography align='center' gutterBottom>
          Enter your email address to reset your password.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className={classes.form}>
              <Grid container alignItems="center" spacing={3} direction="column" justify="center">
                <Grid className={classes.emailField} item lg={12} md={12} xs={12}>
                  <Field component={TextInput} name="email" autoComplete="email" fullWidth />
                </Grid>
                <Button fullWidth type="submit">
                  Submit
                </Button>
                <Grid item lg={12} md={12} xs={12}>
                  {submitMessage ?
                    <Typography color={error ? 'error' : 'inherit'}>{submitMessage}</Typography>
                    : null}
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <Grid className={classes.backToLogin} item lg={12} md={12} xs={12}>
          <Typography variant="body2" align="center">
            <Link color="inherit" href="/login">
              Go Back to Login
            </Link>
          </Typography>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
