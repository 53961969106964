import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { customerEndpoint } from 'utils/api';
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { GrTransaction } from 'react-icons/gr';
import { deleteCustomer, editCustomer } from 'redux/actions/customers/fetchCustomerActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Dialog, makeStyles } from '@material-ui/core';
import DeleteConfirmation from 'components/Modals/ConfirmModal';
import CustomerTransactionsModal from './CustomerTransactionsModal';

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          boxShadow: '0px 3px 10px #00000017',
          borderRadius: '20px',
          paddingLeft: 20,
          paddingRight: 10,
        },
      },
      MuiToolbar: {
        regular: {
          // backgroundColor: '#F5F7F7'
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: '1px solid #ECEFEF',
          padding: 8,
        },
        body: {
          whiteSpace: 'nowrap',
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          display: 'none',
        },
        fixedLeft: {
          display: 'none',
        },
      },
      MUIDataTableHeadCell: {
        data: {
          color: '#4B4B4B',
          opacity: 0.65,
          fontSize: 14,
          fontWeight: 'bold',
          fontFamily: 'Poppins',
        },
        fixedHeader: {
          border: 'none',
        },
      },
    },
  });

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: 150,
    minWidth: 150,
    padding: theme.spacing(5),
  },
}));

export default function MembersList() {
  const classes = useStyles();
  const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const getMembers = async () => {
    await customerEndpoint
      .get('customer/')
      .then((response) => {
        const allMembers = response.data.data;
        setMembers(allMembers);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  useEffect(() => {
    getMembers();
  }, []);

  const columns = ['ID', 'Username', 'First Name', 'Last Name', 'Email', 'Actions'];

  const options = {
    filter: true,
    filterType: 'dropdown',
  };

  const editedCustomer = (id) => {
    dispatch(editCustomer(id));
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [id, setId] = useState('');

  const submitDelete = () => {
    dispatch(deleteCustomer(id));
    setDisplayConfirmationModal(false);
    history.push('/Dashboard');
  };
  const showDeleteModal = (id) => {
    setId(id);
    setDisplayConfirmationModal(true);
  };
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const openTransactions = (id, user) => {
    setId(id);
    setCustomerName(user);
    setOpen(true);
  };

  const handleClose = () => {
    async function fetchData() {
      // const categories = await (await courseEndpoint.get('category/')).data.data;
      // setCategories(categories);
      // dispatch(setCategoryAction(''));
    }
    fetchData();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          members={members}
          data={members.map((member) => {
            const { id, user } = member;
            return [
              id,
              user.username,
              user.first_name,
              user.last_name,
              user.email,
              <>
                <Button>
                  <GrTransaction
                    color="#087090"
                    style={{ color: '#087090' }}
                    size={18}
                    onClick={() => openTransactions(id, user.username)}
                  />
                </Button>
                <Button>
                  <FaEdit
                    color="#087090"
                    size={18}
                    onClick={() => {
                      editedCustomer(id);
                    }}
                  />
                </Button>
                <Button>
                  <RiDeleteBin6Fill
                    // style={{ marginLeft: -30 }}
                    color="#EE9292"
                    size={18}
                    onClick={() => showDeleteModal(id)}
                  />
                </Button>
              </>,
            ];
          })}
          columns={columns}
          options={options}
        />
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
        />
        <Dialog className={classes.dialog} onClose={handleClose} open={open}>
          <CustomerTransactionsModal handleClose={handleClose} id={id} name={customerName} />
        </Dialog>
      </MuiThemeProvider>
    </React.Fragment>
  );
}
