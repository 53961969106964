import {
  FETCH_BUNDLES_START,
  FETCH_BUNDLES_SUCCESS,
  FETCH_BUNDLES_FAILURE,
  EDIT_BUNDLE,
  BUNDLE_SELECTED,
  DELETE_BUNDLE,
} from 'redux/types';
import { courseEndpoint } from 'utils/api';
import history from '../../../utils/history';

const fetchBundlesStart = () => {
  return {
    type: FETCH_BUNDLES_START,
  };
};

const fetchBundlesSuccess = (bundles) => {
  return {
    type: FETCH_BUNDLES_SUCCESS,
    bundles,
  };
};

const fetchBundleDetails = () => {
  const response = courseEndpoint.get('bundle/');
  console.log(response)
  return response;
};

const fetchBundlesFailure = (error) => {
  return {
    type: FETCH_BUNDLES_FAILURE,
    error,
  };
};

export const fetchBundles = () => (dispatch) => {
  dispatch(fetchBundlesStart());
  return fetchBundleDetails().then(
    (response) => dispatch(fetchBundlesSuccess(response.data)),
    (error) => dispatch(fetchBundlesFailure(error))
  );
};

export const selectBundle = (id) => {
  return {
    type: BUNDLE_SELECTED,
    id: id,
  };
};

export const deleteBundle = (id) => {
  return async (dispatch) => {
    const response = await courseEndpoint.delete(`bundle/${id}/`);
    dispatch({ type: DELETE_BUNDLE, payload: response.data });
    history.push('/');
  };
};

export const editBundle = (id) => {
  return async (dispatch) => {
    const response = await courseEndpoint.get(`bundle/${id}/`);
    dispatch({ type: EDIT_BUNDLE, payload: response.data });
    history.push({
      pathname: 'add-bundle',
      prePopulate: response.data.data,
      populate: true,
    });
  };
};


export const editDraftBundle = (id) => {
  return async (dispatch) => {
    const response = await courseEndpoint.get(`bundle/${id}/draft`);
    dispatch({ type: EDIT_BUNDLE, payload: response.data });
    history.push({
      pathname: '/add-bundle',
      prePopulate: response.data.data,
      populate: true,
    });
  };
};
