import React from 'react';
import { Grid, Button } from '@material-ui/core';
import CustomTextField from './CustomTextField';
import useStyles from '../courseFormStyle';


const AddNewLevelInput = (props) => {
  const classes = useStyles();
  const { newLevelTitle, setNewLevelTitle, addNewLevel } = props;

  return (
    <Grid container spacing={2} style={{ marginBottom: 8 }}>
      <Grid className={classes.addNewLevelInput} item sm={3} xs={12}>
        <CustomTextField
          value={newLevelTitle}
          onChange={(event) => setNewLevelTitle(event.target.value)}
          placeholder="Input Level Name"
        />
      </Grid>
      <Grid item sm={9} xs={12}>
        <Button
          size="large"
          className={classes.addNewLevelButton}
          onClick={() => addNewLevel(newLevelTitle)}
        >
          Add Level
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddNewLevelInput;
