// Reference: https://github.com/mui-org/material-ui/tree/master/docs/src/pages/components/material-icons


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import FlexSearch from 'flexsearch';
import * as mui from '@material-ui/icons';
import synonyms from './synonyms';


function selectNode(node) {
  // Clear any current selection
  const selection = window.getSelection();
  selection.removeAllRanges();

  // Select code
  const range = document.createRange();
  range.selectNodeContents(node);
  selection.addRange(range);
}


let Icons = (props) => {
  const { icons, classes, handleClickOpen } = props;

  const handleClick = (event) => {
    selectNode(event.currentTarget);
  };

  return (
    <div>
      {icons.map((icon) => {
        return (
          <span key={icon.key} className={clsx('markdown-body', classes.icon)}>
            <icon.Icon
              tabIndex={-1}
              onClick={handleClickOpen}
              title={icon.key}
              className={classes.iconSvg}
            />
            <p onClick={handleClick}>{icon.key}</p>
          </span>
        );
      })}
    </div>
  );
};


Icons = React.memo(Icons);


const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'scroll',
    height: 140,
  },
  form: {
    margin: theme.spacing(2, 0),
  },
  paper: {
    position: 'sticky',
    top: 80,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  icon: {
    display: 'inline-block',
    width: 86,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '0 4px',
    fontSize: 12,
    '& p': {
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  iconSvg: {
    boxSizing: 'content-box',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      duration: theme.transitions.duration.shortest,
    }),
    fontSize: 40,
    padding: theme.spacing(2),
    margin: theme.spacing(0.5, 0),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
    },
  },
  results: {
    marginBottom: theme.spacing(1),
  },
}));

const searchIndex = FlexSearch.create({
  async: true,
  tokenize: 'full',
});

const allIconsMap = {};
const allIcons = Object.keys(mui)
  .sort()
  .map((key) => {
    let tag;
    if (key.indexOf('Outlined') !== -1) {
      tag = 'Outlined';
    } else if (key.indexOf('TwoTone') !== -1) {
      tag = 'Two tone';
    } else if (key.indexOf('Rounded') !== -1) {
      tag = 'Rounded';
    } else if (key.indexOf('Sharp') !== -1) {
      tag = 'Sharp';
    } else {
      tag = 'Filled';
    }

    let searchable = key.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, '');
    if (synonyms[searchable]) {
      searchable += ` ${synonyms[searchable]}`;
    }
    searchIndex.add(key, searchable);

    const icon = {
      key,
      tag,
      Icon: mui[key],
    };
    allIconsMap[key] = icon;
    return icon;
  });

export default function SearchIcons(props) {
  const classes = useStyles();
  const [tag, setTag] = React.useState('Filled');
  const [keys, setKeys] = React.useState(null);
  const isMounted = React.useRef(false);

  const { search, setIconName } = props;

  const handleClickOpen = React.useCallback((event) => {
    setIconName(allIconsMap[event.currentTarget.getAttribute('title')]);
  }, []);

  React.useEffect(() => {
    handleChange(search);
  }, [search]);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChange = React.useMemo(
    () =>
      debounce((value) => {
        if (!isMounted.current) {
          return;
        }

        if (value === '') {
          setKeys(null);
        } else {
          searchIndex.search(value).then((results) => {
            setKeys(results);
          });
        }
      }, 220),
    [],
  );

  const icons = React.useMemo(() =>
      (keys === null ? allIcons : keys.map((key) => allIconsMap[key])).filter(
        (icon) => tag === icon.tag,
      ),
    [tag, keys],
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12}>
        <Typography className={classes.results}>{`${icons.length} matching results`}</Typography>
        <Icons icons={icons} classes={classes} handleClickOpen={handleClickOpen} />
      </Grid>
    </Grid>
  );
}
