import React from 'react';
import { TextField } from '@material-ui/core';
import useStyles from '../courseFormStyle';

const CustomTextField = (props) => {
  // Although this page is doable with the components that we already have and
  // there are more elegant, in the interest of getting this done now,
  // I'm going to implement a custom text field component.

  const classes = useStyles();
  console.log('asdre', props);
  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        classes: {
          root: props.error ? classes.inputRootError : classes.inputRoot,
        },
      }}
      {...props}
    />
  );
};

export default CustomTextField;
