import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { courseEndpoint } from 'utils/api';
import {CategoryItem} from 'components';

const CategoriesList = () => {
  const [categories, setCategories] = React.useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await (await courseEndpoint.get('category/')).data.data;
      setCategories(response);
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={1}>
      {categories.map((category, i) => (
          <Grid key={i} item md={3} sm={6} xs={12}>
            <CategoryItem
              key={i}
              id={category.id}
              title={category.title}
              icon={category.icon}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default CategoriesList;
