import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import arrow from '../../assets/Group.svg';
import money from '../../assets/money.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 40,
    // paddingLeft: -70,
  },
  card: {
    textAlign: 'left',
    background:
      'transparent linear-gradient(61deg, #033342 0%, #087090 100%) 0% 0% no-repeat padding-box;',
    boxShadow: '0px 3px 10px #00000017',
    marginTop: 10,
    minHeight: 300,
    borderRadius: 10,
  },
  title: {
    fontSize: 17,
    color: '#033342',
    marginRight: 86,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      marginLeft: 35,
    },
  },
  headers: {
    fontSize: 15,
    color: 'white',
    marginRight: 86,
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      marginLeft: 35,
    },
  },
  monthAmount: {
    fontSize: 40,
    color: 'white',
    marginTop: 10,
  },
  totalAmount: {
    fontSize: 40,
    color: 'white',
    marginTop: 10,
    marginLeft: -5,
  },
  img: {
    marginTop: '-110px',
    marginLeft: '50px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: -40,
    },
    imgArrow: {
      paddingLeft: 70,
    },
  },
}));

export default function SimpleCard() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h3" className={classes.title}>
          Earnings
        </Typography>
        <Card className={classes.card} elevation={0}>
          <CardContent>
            <Typography className={classes.headers} color="textSecondary" gutterBottom>
              Current Month
            </Typography>
            <Typography variant="h5" className={classes.monthAmount}>
              3,456€
              <img src={arrow} className={classes.imgArrow} alt="" />
            </Typography>
            <hr style={{ color: '#bfcbdb', maxWidth: '180px', marginLeft: '-5px' }} />
            <Typography className={classes.headers} color="textSecondary">
              Total Lifetime
            </Typography>
            <Typography variant="h5" className={classes.totalAmount}>
              9,056€
            </Typography>
          </CardContent>
        </Card>
        <img className={classes.img} src={money} alt="" />
      </div>
    </>
  );
}
