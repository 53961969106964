import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { courseEndpoint } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  mobileType: {
    color: '#B8B8B8',
  },
  modalWrapper: {
    padding: 30,
    minWidth: 500,
  },
  status: {
    color: '',
  },
  redCircle: {
    color: '#d12626',
  },
  orangeCircle: {
    color: '#E6AC25',
  },
  greenCircle: {
    color: '#2FA10E',
  },
  greyCircle: {
    color: '#A7A7A7',
  },
  yellowCircle: {
    color: '#ffcc00',
  },
  date: {
    color: '#989898',
    fontSize: 15,
  },
  price: {
    float: 'right',
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 10,
  },
  courseTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 14,
  },
  course: {
    fontSize: 14,
    color: '#949494',
    minWidth: 129,
    paddingLeft: 5,
  },
  priceCourse: {
    fontSize: 14,
    color: '#087090',
    opacity: 0.44,
    textDecoration: 'line-through',
    marginRight: 20,
  },
  paidPrice: {
    color: '#087090',
    fontSize: 14,
  },
  header: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  id: {
    textDecoration: 'underline',
    marginRight: 7,
  },
}));

const CustomerTranscations = (props) => {
  const classes = useStyles();
  // const { handleClose } = props;

  const [transactions, setTransactions] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const transactions = await (
        await courseEndpoint.get(`transaction/customer/${props.id}`)
      ).data.data;
      console.log(transactions);
      setTransactions(transactions);

      const responseCourses = await (await courseEndpoint.get('course/')).data.data;
      setCourses(responseCourses);
    }
    fetchData();
  }, []);

  return (
    <div className={classes.modalWrapper}>
      <Typography variant="body1" className={classes.header}>
        Customer Transactions{' '}
      </Typography>
      <Typography variant="body1">
        {props.name} - ID:{props.id}{' '}
      </Typography>
      {transactions.length === 0 ? <Typography>No trasactions</Typography> : null}
      {transactions.length > 0 ? (
        <>
          <hr color="#f0f1f2" />
          {transactions.map((transaction, i) => (
            <div key={i}>
              <Typography className={classes.mobileType} variant="body2">
                {transaction.system.charAt(0).toUpperCase() +
                  transaction.system.slice(1).toLowerCase()}
              </Typography>
              <Typography variant="body1">
                Transaction <span className={classes.id}> #{transaction.id} </span>
                <span className={classes.date}>
                  {' '}
                  {transaction.purchased_date.replaceAll('-', '/').split('T', 1)}
                </span>
                <span className={classes.price}>Total €{transaction.final_price_paid} </span>
              </Typography>
              <Typography variant="body2" className={classes.status}>
                Status:{' '}
                <span
                  className={` ${transaction.status === 'ACCEPTED' ? classes.greenCircle : ''} ${
                    transaction.status === 'PENDING_PAYMENT' ? classes.orangeCircle : ''
                  } ${transaction.status === 'CANCELLED' ? classes.redCircle : ''}
                  ${transaction.status === 'PENDING_RESPONSE' ? classes.yellowCircle : ''}`}
                >
                  {transaction.status.charAt(0).toUpperCase() +
                    transaction.status.slice(1).toLowerCase()}
                  {/* {transaction.status.replace('_', ' ')} */}
                </span>
              </Typography>
              <br />

              {transaction.purchased_courses.map((course, i) => {
                return (
                  <Grid container key={i}>
                    <Grid item sm={2}>
                      <img
                        className={classes.image}
                        alt="course_img"
                        src={course.course.promo_image}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.courseTitle}>
                        {course.course.title}{' '}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.course}> Course </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.text}>
                        {course.course.category.title}{' '}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.text}>
                        {course.redeemed_promo_code === null
                          ? 'NO PROMO CODE'
                          : course.redeemed_promo_code}
                      </Typography>
                    </Grid>

                    {transaction.redeemed_promo_code !== 'null' ? (
                      <Grid item sm={1}>
                        <Typography className={classes.paidPrice}>FREE</Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid item sm={1}>
                          <Typography className={classes.priceCourse}>
                            {course.original_price}{' '}
                          </Typography>
                        </Grid>
                        <Grid item sm={1}>
                          <Typography className={classes.paidPrice}>
                            {course.paid_price}{' '}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                );
              })}
              {transaction.purchased_bundles.map((bundle, i) => {
                return (
                  <Grid container key={i}>
                    <Grid item sm={2}>
                      <img
                        className={classes.image}
                        alt="bundle_img"
                        src={bundle.bundle.promo_image}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.courseTitle}>
                        {bundle.bundle.title}{' '}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.course}> Bundle </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.text}>
                        {bundle.bundle.category.title}{' '}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.text}>
                        {bundle.redeemed_promo_code !== null
                          ? 'NO PROMO CODE'
                          : bundle.redeemed_promo_code}{' '}
                      </Typography>
                    </Grid>

                    {transaction.redeemed_promo_code !== null ? (
                      <Grid item sm={1}>
                        <Typography className={classes.paidPrice}>FREE</Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid item sm={1}>
                          <Typography className={classes.priceCourse}>
                            {bundle.original_price}{' '}
                          </Typography>
                        </Grid>
                        <Grid item sm={1}>
                          <Typography className={classes.paidPrice}>
                            {bundle.paid_price}{' '}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                );
              })}

              <hr color="#f0f1f2" />
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default CustomerTranscations;
