import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Paper, Grid, Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import { customerEndpoint } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    marginBottom: theme.spacing(1),
    fontSize: 23,
    background: 'white',
  },
  title: {
    display: 'inline',
  },
  subtitle: {
    color: '#033342',
    fontSize: 16,
  },
  dialogContent: {
    backgroundColor: '#F5F7F7',
  },
  reviews: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 16,
    color: '#033342',
  },
  promoImage: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 75,
    height: 50,
    borderRadius: 10,
  },
  avgRating: {
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    fontSize: 15,
    display: 'inline',
    backgroundColor: '#E6F1F4',
    color: '#087090',
    background: '#E6F1F4 0% 0% no-repeat padding-box',
    borderRadius: 10,
    width: 'fit-content',
    padding: '2px 8px',
  },
  starIcon: {
    fontSize: 12,
  },
  reviewTextContainer: {
    backgroundColor: 'white',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: 15,
  },
  star: {
    color: '#087090',
  }
}));

const ReviewsModal = (props) => {
  const classes = useStyles();
  const [customer, setCustomer] = useState('');
  const { reviews, course, open, handleClose } = props;

  useEffect(() => {
    if (open === false) {
      return;
    }
    async function fetchData() {
      const customer = await customerEndpoint.get(`customer/${course.content_creator}`);
      setCustomer(customer.data.data.user.username);
    }
    fetchData();
  }, [open]);

  const UserReview = (props) => {
    const {
      review: { text, rating },
    } = props;
    console.log('p', props, rating);

    return (
      <Paper className={classes.reviewTextContainer}>
        <Grid container>
          <Grid item xs={12}>
            {text}
          </Grid>
          <Grid item xs={12}>
            <Rating
              classes={{root: classes.star}}
              size="small"
              value={rating}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Grid container>
          <Grid item xs={2}>
            <img src={course.promo_image} alt="promo_img" className={classes.promoImage} />
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.title} variant="h2">
              {course.title}
            </Typography>
            <div className={classes.avgRating}>
              <StarIcon className={classes.starIcon} />
              {course.avg_rating}
            </div>
            <Typography className={classes.subtitle} variant="h3">
              {customer}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.reviews} variant="h3">
          Reviews ({reviews.length})
        </Typography>
        {reviews.map((review) => (
          <UserReview key={review.id} review={review} />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default ReviewsModal;
