import { EDIT_COURSE } from 'redux/types';

export default (state = null, action) => {
  switch (action.type) {
    case EDIT_COURSE:
      return {
        ...state,
        course: action,
      };
    default:
      return state;
  }
};
