import { authEndpoint } from 'utils/api';
import { GET_ERRORS, SET_CURRENT_USER } from 'redux/types';
import jwt_decode from 'jwt-decode';


export const loginAction = (body) => async (dispatch) => {
  try {
    const response = await authEndpoint.post('login/', body);

    const accessToken = response.data.data.access;
    const refreshToken = response.data.data.refresh;

    localStorage.setItem('jwtToken', accessToken);
    localStorage.setItem('jwtRefresh', refreshToken);

    const payload = jwt_decode(accessToken);

    dispatch({
      type: SET_CURRENT_USER,
      payload: payload,
      account: body,
      tokenExp: payload.exp,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
