import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import BundlesList from './BundlesList';


const useStyles = makeStyles((theme) => ({
  formTitle: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    fontSize: 23,
    display: 'inline-block'
  },
  formSubtitle: {
    fontSize: 16,
    marginBottom: 30,
  },
  btn: {
    padding: 10,
    float: 'right',
    borderRadius: 10,
    width: 161,
    height: 45,
    marginBottom: 30,
  },
  main: {
    height: 'auto',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    marginLeft: 60,
    marginRight: 60,
  },
  goBack: {
    textDecoration: 'none',
    color: '#087090',
  },
  paper: {
    backgroundColor: '#eee',
    marginLeft: '30%',
    marginRight: '30%',
  },
}));


const Bundles = () => {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <Typography variant="body2" align="left">
        <Link className={classes.goBack} color="inherit" to="/content">
          {'< Back to Content'}
        </Link>
      </Typography>
      <Typography className={classes.formTitle} variant="h2">
        Bundles
      </Typography>
      <Button size="large" className={classes.btn} component={Link} to="/register-bundle">
        + New Bundle
      </Button>
      <BundlesList />
    </main>
  );
};

export default Bundles;
