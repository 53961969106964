import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';

// Integrates Chrome extension "Redux DevTools Console".
import { composeWithDevTools } from 'redux-devtools-extension';

// Enables Redux actions to be async functions.
import thunk from 'redux-thunk';

// Redux persist lets us save Redux store in local storage.
// So we don't lose it on refresh or when closing the browser.
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';


const middleware = [
  thunk
];

const store = createStore(
  persistReducer({ key: 'root', storage }, rootReducer),
  composeWithDevTools(
    applyMiddleware(...middleware)
  )
);

const persistor = persistStore(store);

export { store, persistor };
