import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { courseEndpoint } from 'utils/api';
import { FaEdit } from 'react-icons/fa';
import { BiCopy } from 'react-icons/bi';
import { GiPlainCircle } from 'react-icons/gi';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, makeStyles } from '@material-ui/core';
import DeleteConfirmation from 'components/Modals/ConfirmModal';
import { deletePromo, editPromo } from 'redux/actions/promos/fetchPromoActions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  codeCell: {
    background: '#e6f3ff',
    padding: 5,
    borderRadius: 7,
    minWidth: 100,
    maxWidth: 100,
    marginRight: 10,
  },
  redCircle: {
    color: '#d12626',
  },
  orangeCircle: {
    color: '#E6AC25',
  },
  greenCircle: {
    color: '#2FA10E',
  },
  greyCircle: {
    color: '#A7A7A7',
  },
  yellowCircle: {
    color: '#ffff33',
  },
  dates: {
    fontSize: 14,
    color: '#4B4B4B',
    paddingLeft: 7,
    opacity: 0.5,
  },
}));

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          boxShadow: '0px 3px 10px #00000017',
          borderRadius: '20px',
          paddingLeft: 20,
          paddingRight: 10,
        },
      },
      MuiToolbar: {
        regular: {},
      },
      MuiTableCell: {
        root: {
          borderBottom: '1px solid #ECEFEF',
          padding: 8,
          fontSize: 16,
        },
        body: {
          whiteSpace: 'nowrap',
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          display: 'none',
        },
        fixedLeft: {
          display: 'none',
        },
      },
      MUIDataTableHeadCell: {
        data: {
          color: '#4B4B4B',
          opacity: 0.65,
          fontSize: 18,
          fontFamily: 'Poppins',
        },
        fixedHeader: {
          border: 'none',
        },
      },
    },
  });

export default function PromoCodesList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const getMembers = async () => {
    await courseEndpoint
      .get('promo-code/')
      .then((response) => {
        const promos = response.data.data;
        setPromos(promos);
        console.log(promos);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  useEffect(() => {
    getMembers();
  }, []);

  const columns = ['Name', 'Coupon Code', 'Duration', 'Uses', 'Status', 'Actions'];

  const options = {
    filter: true,
    filterType: 'dropdown',
  };

  const editedPromo = (id) => {
    dispatch(editPromo(id));
  };

  const [promos, setPromos] = useState([]);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [id, setId] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const submitDelete = () => {
    dispatch(deletePromo(id));
    setDisplayConfirmationModal(false);
    history.push('/Dashboard');
  };
  const showDeleteModal = (id) => {
    setId(id);
    setDisplayConfirmationModal(true);
  };
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const onCopyCode = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  console.log('PROMO', promos);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          // tableBodyMaxHeight='200px'
          promos={promos}
          data={promos.map((promo, i) => {
            const {
              id,
              title,
              code,
              duration,
              uses,
              status,
              start_date,
              end_date,
              redemption_limit,
            } = promo;
            return [
              title,
              <>
                <span className={classes.codeCell}>{code} </span>
                <Button>
                  <CopyToClipboard text={code} onCopy={onCopyCode}>
                    <BiCopy color="#087090" />
                  </CopyToClipboard>
                  {isCopied ? <p className={classes.greenCircle}> Copied!</p> : null}
                </Button>
              </>,
              <div key={i}>
                {duration}
                <span className={classes.dates}>
                  {moment(start_date).format('LL')} -{' '}
                  {end_date !== null ? moment(end_date).format('LL') : 'No end date'}
                </span>
              </div>,
              uses,
              <>
                <GiPlainCircle
                  size={8}
                  className={` ${status === 'ACTIVE' ? classes.greenCircle : ''} ${
                    status === 'INACTIVE' ? classes.greyCircle : ''
                  } ${status === 'SCHEDULED' ? classes.orangeCircle : ''} ${
                    status === 'EXPIRED' ? classes.redCircle : ''
                  }
                  ${status === 'REACHED_REDEMPTION_LIMIT' ? classes.yellowCircle : ''}`}
                />{' '}
                {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
              </>,
              <div>
                <FaEdit
                  style={{ cursor: 'pointer' }}
                  color="#087090"
                  size={18}
                  onClick={() => {
                    editedPromo(id);
                  }}
                />

                <RiDeleteBin6Fill
                  style={{ marginLeft: '2em', cursor: 'pointer' }}
                  color="#EE9292"
                  size={18}
                  onClick={() => showDeleteModal(id)}
                />
              </div>,
            ];
          })}
          columns={columns}
          options={options}
        />
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
}
