import React, { useCallback, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Level from './Level';

const LevelList = (props) => {
  const {
    levels,
    setLevels,
    setContent,
    content,
    edit,
    handleDetailsChange,
    handleDeleteLevel,
    handleContentChange,
    errorInpDuration,
    errorShDescription,
    errorLevelPrice,
    setChangedReps,
    reps,
  } = props;

  const [expandedLevel, setExpandedLevel] = useState(null);

  const onExpandCallback = (lid) => setExpandedLevel(lid);

  const handleDragEnd = ({ source, destination }) => {
    setLevels((oldLevels) => {
      // Re-order levels
      const newLevels = oldLevels.slice();

      const [temp] = newLevels.splice(source.index, 1);

      newLevels.splice(destination.index, 0, temp);

      // Update sequence_number
      newLevels.forEach((level, index) => {
        level.sequence_number = index + 1; // We can't start from zero.
      });

      return newLevels;
    });
  };

  const sortLevelCallback = (a, b) => (a.sequence_number > b.sequence_number ? 1 : -1);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-root">
        {(provided) => {
          return (
            <div
              className="droppable-root"
              style={{ width: '100%' }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {levels
                .sort((a, b) => sortLevelCallback(a, b))
                .map((level, index) => (
                  <Level
                    reps={reps}
                    key={level.id}
                    {...level}
                    index={index}
                    levels={levels}
                    setContent={setContent}
                    content={content}
                    edit={edit}
                    setLevels={setLevels}
                    isExpanded={expandedLevel === (level.id ? level.id : level.ids)}
                    handleDetailsChange={handleDetailsChange}
                    handleDeleteLevel={handleDeleteLevel}
                    handleContentChange={handleContentChange}
                    onExpand={onExpandCallback}
                    errorInpDuration={errorInpDuration}
                    errorShDescription={errorShDescription}
                    errorLevelPrice={errorLevelPrice}
                    setChangedReps={setChangedReps}
                  />
                ))}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default LevelList;
