import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DashboardLayout } from 'layouts';


const DashboardRoute = props => {
  const {  component: Component, auth, ...rest } = props;

  if (!auth) {
      return (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
  } else {
    return (
      <Route exact {...rest} render={matchProps => (
        <DashboardLayout>
          <Component {...matchProps} />
        </DashboardLayout>
      )}
      />
    );
  }
};


export default DashboardRoute;
