import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { courseEndpoint } from 'utils/api';
import { Formik } from 'formik';
import Select from 'react-select';
import deleteIcon from '../../assets/deleteIcon.png';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderStyle: 'none',
    boxShadow: 'none',
    minWidth: 200,
    background: 'transparent',
    padding: 7,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#087090',
    fontSize: 13,
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: 10,
    background: 'white',
    color: 'black',
  }),
  menuList: (provided, state) => ({
    ...provided,
    background: 'white',
    borderRadius: 10,
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    background: 'red',
    borderRadius: 50,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: 'white',

    borderRadius: 50,
  }),
};

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,

    padding: theme.spacing(0, 3),
    marginTop: 40,
  },
  paper: {
    maxWidth: 213,
    minWidth: 213,
    // margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: 20,
    boxShadow: '0px 3px 10px #00000017',
    maxHeight: 80,
    minHeight: 80,
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 30,
      maxWidth: 220,
      minWidth: 220,
    },
    [theme.breakpoints.down('1150px')]: {
      // marginLeft: 30,
      maxWidth: 180,
      minWidth: 180,
    },
  },
  paperAdd: {
    width: '280px',
    padding: theme.spacing(1),
    backgroundColor: '#396F801A',
    borderRadius: 20,
    boxShadow: '0px 3px 10px #00000017',
    maxHeight: 89,
    marginTop: 11,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 30,
    },
    [theme.breakpoints.down('1150px')]: {
      marginLeft: 30,
    },
  },
  title: {
    fontSize: 17,
    color: '#033342',
    paddingBottom: 10,
  },
  card: {
    // maxWidth: 90,
    backgroundColor: '#BCBCBC',
    marginTop: 0,
    maxHeight: 55,
    borderRadius: 10,
  },
  courseTitle: {
    color: '#4B4B4B',
    fontSize: 12,
    minWidth: 190,
    maxWidth: 190,
    fontWeight: 'bold',
  },
  category: {
    fontSize: 11,
    color: '#535353',
    marginTop: -12,
  },
  textEdit: {
    float: 'right',
    textDecoration: 'underline',
    color: '#7D7D7D',
    fontWeight: 'bold',
  },

  plusIcon: {
    fontSize: 30,
    paddingRight: 20,
  },
  price: {
    color: '#087090',
    fontSize: 14,
    marginTop: -8,
  },
  image: {
    width: 95,
    height: 65,
    borderRadius: 10,
  },
  inputRoot: {
    color: 'transparent',
    background: 'transparent',
    maxWidth: 10,
  },
  addBtn: {
    backgroundColor: 'transparent',
    width: '280px',
    color: '#087090',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'transparent',
    },
  },
  boxItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  boxButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}));

export default function Featured() {
  const classes = useStyles();

  const [featuredCourses, setFeaturedCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [featuredBundles, setFeaturedBundles] = useState([]);
  const [selectedOptionCourse, setSelectedOptionCourse] = useState(null);
  const [selectedOptionBundle, setSelectedOptionBundle] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const handleChangeCourseOptions = async (selectedOption1) => {
    setSelectedOptionCourse(selectedOption1.value);

    try {
      const coursePatch = {
        is_featured: true,
      };
      await courseEndpoint.patch(`course/${selectedOption1.value}/`, coursePatch);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeBundleOptions = async (selectedOption2) => {
    setSelectedOptionBundle(selectedOption2.value);

    try {
      const coursePatch = {
        is_featured: true,
      };
      await courseEndpoint.patch(`bundle/${selectedOption2.value}/`, coursePatch);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const ftCourses = await (await courseEndpoint.get('course/featured')).data.data;
      setFeaturedCourses(ftCourses);
      const featuredBundles = await (await courseEndpoint.get('bundle/featured')).data.data;
      setFeaturedBundles(featuredBundles);
      const courses = await (await courseEndpoint.get('course/')).data.data;
      setCourses(courses);
      const bundles = await (await courseEndpoint.get('bundle/')).data.data;
      setBundles(bundles);
    }
    fetchData();
  }, []);

  const removeFeaturedCourse = async (id) => {
    try {
      const coursePatch = {
        is_featured: false,
      };
      await courseEndpoint.patch(`course/${id}/`, coursePatch);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const removeFeaturedBundle = async (id) => {
    try {
      const bundlePatch = {
        is_featured: false,
      };
      await courseEndpoint.patch(`bundle/${id}/`, bundlePatch);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  /*() => {
    removeFeaturedCourse(course.id);
  }*/

  return (
    <Formik>
      <div className={classes.root}>
        <Typography variant="h3" className={classes.title}>
          Featured Courses and Bundles
        </Typography>
        <Grid container>
          <Grid item sm={6} xs={12} md={6} style={{ marginBottom: '30px' }}>
            {featuredCourses.length > 0 ? (
              featuredCourses.slice(0, 4).map((course, i) => (
                <Paper key={i} className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <img className={classes.image} alt="bundle_img" src={course.promo_image} />
                    </Grid>
                    <Grid container style={{ marginTop: '10px' }}>
                      <Grid item sm={6}>
                        <Typography className={classes.courseTitle}>{course.title} </Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <Typography className={classes.category}>
                          {course.category.title}
                        </Typography>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography className={classes.price}>€ {course.price}</Typography>
                          <img
                            style={{
                              marginRight: '30px',
                              marginTop: '-4px',
                              marginBottom: '5px',
                            }}
                            alt=""
                            src={deleteIcon}
                            onClick={handleOpenModal}
                          />
                          <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                              }}
                            >
                              <div className={classes.boxItems}>
                                <Typography
                                  align="center"
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                  style={{ marginBottom: '1em' }}
                                >
                                  Are you sure?
                                </Typography>
                                <div className={classes.boxButtons}>
                                  <Button
                                    onClick={() => {
                                      removeFeaturedCourse(course.id);
                                    }}
                                  >
                                    Yes
                                  </Button>
                                  <Button onClick={handleCloseModal}>No</Button>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              ))
            ) : (
              <>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container className={classes.pointer} wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            )}
            {featuredCourses.length === 1 ? (
              <>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : null}
            {featuredCourses.length === 2 ? (
              <>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+&#10; Add Featured Courses"
                          maxMenuHeight={100}
                          menuPlacement="auto"
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : null}
            {featuredCourses.length === 3 ? (
              <>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          classNamePrefix="react-select"
                          styles={customStyles}
                          value={selectedOptionCourse}
                          placeholder="+ Add Featured Courses"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={courses
                            .filter((course) => course.is_featured !== true)
                            .map((course) => {
                              return { value: course.id, label: course.title };
                            })}
                          onChange={handleChangeCourseOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : null}
          </Grid>
          <Grid item sm={6} xs={12} md={6}>
            {featuredBundles.length > 0 ? (
              featuredBundles.slice(0, 4).map((bundle, i) => (
                <Paper key={i} className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <img className={classes.image} alt="bundle_img" src={bundle.promo_image} />
                    </Grid>
                    <Grid container style={{ marginTop: '10px' }}>
                      <Grid item sm={6}>
                        <Typography className={classes.courseTitle}>{bundle.title} </Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <Typography className={classes.category}>
                          {bundle.category.title}
                        </Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography className={classes.price}>€ {bundle.price}</Typography>
                          <img
                            style={{ marginRight: '30px', marginTop: '-4px', marginBottom: '5px' }}
                            alt=""
                            src={deleteIcon}
                            onClick={handleOpenModal2}
                          />
                          <Modal
                            open={openModal2}
                            onClose={handleCloseModal2}
                            aria-labelledby="modal-modal-titles"
                            aria-describedby="modal-modal-descriptions"
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                              }}
                            >
                              <div className={classes.boxItems}>
                                <Typography
                                  align="center"
                                  id="modal-modal-titles"
                                  variant="h6"
                                  component="h2"
                                  style={{ marginBottom: '1em' }}
                                >
                                  Are you sure?
                                </Typography>
                                <div className={classes.boxButtons}>
                                  <Button
                                    onClick={() => {
                                      removeFeaturedBundle(bundle.id);
                                    }}
                                  >
                                    Yes
                                  </Button>
                                  <Button onClick={handleCloseModal2}>No</Button>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              ))
            ) : (
              <>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            )}
            {featuredBundles.length === 1 ? (
              <>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : null}
            {featuredBundles.length === 2 ? (
              <>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          styles={customStyles}
                          value={selectedOptionBundle}
                          placeholder="+ Add Featured Bundles"
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : null}
            {featuredBundles.length === 3 ? (
              <>
                <Paper className={classes.paperAdd} elevation={0}>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                      <Button disableRipple className={classes.addBtn}>
                        <Select
                          className={classes.inputSelect}
                          classNamePrefix="react-select"
                          styles={customStyles}
                          value={selectedOptionBundle}
                          menuPlacement="auto"
                          maxMenuHeight={100}
                          placeholder="+ Add Featured Bundles"
                          options={bundles
                            .filter((bundle) => bundle.is_featured !== true)
                            .map((bundle) => {
                              return { value: bundle.id, label: bundle.title };
                            })}
                          onChange={handleChangeBundleOptions}
                          closeMenuOnSelect={false}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Formik>
  );
}
