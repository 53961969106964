import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import { TextInput, NumberInput1, NumberInput2, DateInput } from 'utils/form/components';
import * as validations from 'utils/form/validations';
import * as Yup from 'yup';
import { courseEndpoint } from 'utils/api';
import Alert from '@material-ui/lab/Alert';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import useStyles from '../CourseForm/courseFormStyle';
import Radio from '@material-ui/core/Radio';
import { Select as SelectCategory } from 'material-ui-formik-components/Select';
import Select, { components } from 'react-select';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import nopreview from '../../../assets/images/nopreview.png';
import { useHistory } from 'react-router-dom';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderStyle: 'none',
    boxShadow: 'none',
    padding: 4,
  }),
  // css-18fprs2-control {
};
const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiInputLabel: {
        formControl: {
          paddingTop: 2,
          paddingBottom: 3,
        },
        animated: {
          paddingLeft: 5,
          color: 'black',
          paddingBottom: 10,
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'white',
            paddingLeft: 10,
          },
          paddingLeft: 10,
        },
      },
    },
  });

const BundleForm = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [alert, setAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [imgName, setImgName] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [file, setFile] = useState('');
  const [selectedValue, setSelectedValue] = useState('b');
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [draft, setDraft] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [error, setError] = useState(false);
  const [isDraft, setIsDraft] = useState('');
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImgName(file.name);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeCourseOptions = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log(typeof selectedOption);
  };

  const initialValues = {
    title: props.location.prePopulate ? props.location.prePopulate.title : '',
    description: props.location.prePopulate ? props.location.prePopulate.description : '',
    price: props.location.prePopulate ? props.location.prePopulate.price : 0,
    category: props.location.prePopulate ? props.location.prePopulate.category.id : '',
    discount: props.location.prePopulate ? props.location.prePopulate.discount : 0,
    promo_image: props.location.prePopulate ? props.location.prePopulate.promo_image : null,
    course_ids: props.location.prePopulate ? props.location.prePopulate.courses : [],
    discount_expiry_date: props.location.prePopulate
      ? props.location.prePopulate.discount_expiry_date
      : '',
    discount_start_date: props.location.prePopulate
      ? props.location.prePopulate.discount_start_date
      : '',
  };

  const MultiValueLabel = (props) => {
    return (
      <components.MultiValueLabel
        {...props}
        innerProps={{
          ...props.innerProps,
          onClick: (e) => {
            e.stopPropagation(); // doesn't do anything, sadly
            e.preventDefault(); // doesn't do anything, sadly
            // still unsure how to preven the menu from opening
            alert(props.data.label);
          },
        }}
      />
    );
  };

  // const MultiValueContainer = (props) => {
  //   return (
  //     // <Tooltip content={'Customise your multi-value container!'}>
  //     <components.MultiValueContainer {...props} />
  //     // </Tooltip>
  //   );
  // };

  const getCategories = async () => {
    await courseEndpoint
      .get('category/')
      .then((response) => {
        const categories = response.data.data;
        setCategories(categories);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };
  const getCourses = async () => {
    await courseEndpoint
      .get('course/')
      .then((response) => {
        const courses = response.data.data;
        setCourses(courses);
      })
      .catch((error) => console.error(`Error : ${error}`));
  };

  const getCoursesEdit = () => {
    if (props.location.prePopulate) {
      if (props.location.prePopulate.courses) {
        props.location.prePopulate.courses.map(
          (course) =>
            setSelectedOption({ ...selectedOption, value: course.id, label: course.title }),
          console.log(props.location.prePopulate.courses)
        );
      }
    }
  };

  const getImageEdit = () => {
    if (props.location.prePopulate) {
      if (
        props.location.prePopulate.promo_image !== null &&
        props.location.prePopulate.promo_image !== ''
      ) {
        setImgName(props.location.prePopulate.promo_image.split('bundle/')[1]);
        // handleImageChange(
        //   new File(
        //     [props.location.prePopulate.promo_image],
        //     props.location.prePopulate.promo_image.split('bundle/')[1],
        //     {
        //       type: props.location.prePopulate.promo_image.type,
        //     }
        //   ),
        //   'promo_image'
        // );
      }
    }
  };

  useEffect(() => {
    getCategories();
    getCourses();
    getCoursesEdit();
    getImageEdit();
  }, []);

  useEffect(() => {
    if (props.location.prePopulate) {
      setIsEditing(true);
      console.log(props.location.prePopulate);
      if (props.location.prePopulate.is_draft === true) {
        setIsDraft(true);
      }
    }
  }, [props.location.prePopulate]);

  const validationSchema = Yup.object({
    title: validations.stringIsRequired('Title'),
    description: validations.stringIsRequired('Description'),
  });

  const handleSubmit = async (values) => {
    if (props.location.prePopulate) {
      console.log(selectedOption);
      try {
        const formData = new FormData();
        if (selectedOption !== null) {
          selectedOption.map((option) => {
            formData.append('course_ids', [option.value]);
          });
        }
        formData.append('promo_image', file);
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('price', values.price);
        formData.append('discount', values.discount);
        formData.append('category', values.category);
        await courseEndpoint
          .patch(`bundle/${props.location.prePopulate.id}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              setAlert(true);
              history.push('/content');
            }
          });
      } catch (error) {
        console.log(error);
        setErrorMsg(error.response.statusText);
        setError(true);
      }
    } else {
      try {
        const formData = new FormData();
        if (selectedOption !== null) {
          selectedOption.map((option) => {
            formData.append('course_ids', [option.value]);
          });
        }
        formData.append('promo_image', file);
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('price', values.price);
        formData.append('discount', values.discount);
        formData.append('category', values.category);
        await courseEndpoint.post('bundle/', formData).then((res) => {
          if (res.status === 201) {
            setAlert(true);
            history.push('/content');
          }
        });
      } catch (error) {
        console.log(error);
        setErrorMsg(error.response.statusText);
        setError(true);
      }
    }
  };

  const saveDraft = async (values) => {
    try {
      const formData = new FormData();
      if (selectedOption !== null) {
        selectedOption.map((option) => {
          formData.append('course_ids', option.value);
        });
      }
      if (values.promo_image !== null) {
        formData.append('promo_image', file);
      }
      if (values.title) {
        formData.append('title', values.title);
      }
      if (values.description) {
        formData.append('description', values.description);
      }
      if (values.price) {
        formData.append('price', values.price);
      }
      if (values.discount) {
        formData.append('discount', values.discount);
      }
      if (values.discount_start_date) {
        formData.append('discount_start_date', values.discount_start_date);
      }
      if (values.discount_expiry_date) {
        formData.append('discount_exp', values.discount);
      }
      if (values.category) {
        formData.append('category', values.category);
      }
      await courseEndpoint.post('bundle/draft/save/', formData).then((res) => {
        if (res.status === 200) {
          setDraft(true);
          setAlert(true);
        }
      });
    } catch (error) {
      // setErrorMsg(error.response.statusText);
      // setError(true);
      console.log(error);
    }
  };

  console.log('THE COURSES', courses);
  console.log('PROPS', props.values);

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={classes.form}>
            {error ? <Alert severity="error">{errorMsg}</Alert> : null}
            {alert ? (
              <Alert severity="success">
                {draft
                  ? 'Draft Saved'
                  : !isEditing
                  ? 'Bundle created successfully'
                  : 'Bundle edited successfully'}
              </Alert>
            ) : null}
            <Typography className={classes.goBack} variant="body1" component={Link} to="/content">
              <IoIosArrowBack /> Back to content
            </Typography>
            <Typography className={classes.formTitle} variant="h1">
              {isEditing ? 'Edit Bundle' : 'Create New Bundle'}
            </Typography>
            <Typography className={classes.details} variant="body1">
              General Info
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <Field name="title" component={TextInput} placeholder="Input Bundle Name" />
              </Grid>
              <Grid item sm={4} xs={12}>
                <Field
                  InputProps={{
                    disableUnderline: true,
                  }}
                  options={categories.map((category) => {
                    return { value: category.id, label: category.title };
                  })}
                  // }}
                  className={classes.inputSelectBundle}
                  label="Choose Category"
                  name="category"
                  component={SelectCategory}
                />
              </Grid>
              <Grid item sm={8} xs={12}>
                <Field
                  name="description"
                  component={TextInput}
                  placeholder="Description - What you'll learn"
                  multiline
                  rows={9}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="imgPreview">
                  {imagePreviewUrl ? (
                    <img alt="preview" className={classes.previewImgBundle} src={imagePreviewUrl} />
                  ) : (
                    <Grid item sm={1} xs={12}>
                      <img
                        src={initialValues.promo_image ? initialValues.promo_image : nopreview}
                        alt="placeholder"
                        className={classes.placeholderImgBundle}
                      />
                    </Grid>
                  )}
                  <div className={classes.labelWrapper2}>
                    <label className={classes.label}>
                      <input
                        type="file"
                        id="actual-btn"
                        hidden
                        onChange={(e) => handleImageChange(e)}
                      />
                      Choose picture
                    </label>
                    <span className={classes.fileChosen}>
                      {imgName !== '' ? imgName : 'No Image Selected'}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item sm={8} xs={12}>
                <Typography className={classes.details} variant="body1">
                  Courses
                </Typography>
                <Select
                  className={classes.inputSelect}
                  styles={customStyles}
                  value={selectedOption}
                  placeholder="Add Courses"
                  components={{ MultiValueLabel }}
                  options={courses
                    .filter((course) => course.id)
                    .map((course) => {
                      return { value: course.id, label: course.title };
                    })}
                  onChange={handleChangeCourseOptions}
                  closeMenuOnSelect={false}
                  isMulti
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Typography className={classes.details} variant="body1">
                  Price details
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography className={classes.labels} variant="body1">
                  Set a price
                </Typography>
                <Field
                  component={NumberInput1}
                  placeholder="Individual Course Price"
                  name="price"
                  setprice={setPrice}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <Typography className={classes.labels} variant="body1">
                  Add a discount
                </Typography>
                Yes
                <Radio
                  checked={selectedValue === 'a'}
                  onChange={handleChange}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'A' }}
                  checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                />
                No
                <Radio
                  checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
                  checked={selectedValue === 'b'}
                  onChange={handleChange}
                  value="b"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'B' }}
                  icon={<span className={classes.icon} />}
                />
              </Grid>
              {selectedValue === 'a' ? (
                <>
                  <Grid item sm={1} xs={12}>
                    <Typography className={classes.labels} variant="body1">
                      Enter Discount (%)
                    </Typography>
                    <Field
                      component={NumberInput2}
                      placeholder="0"
                      name="discount"
                      setdiscount={setDiscount}
                    />
                  </Grid>
                  <Grid item sm={1} xs={12}></Grid>
                  <Grid item sm={2} xs={12}>
                    <Typography className={classes.labels} variant="body1">
                      Duration
                    </Typography>
                    <Field
                      component={DateInput}
                      placeholder="From Date"
                      name="discount_start_date"
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Typography className={classes.labels} variant="body1">
                      Duration
                    </Typography>
                    <Field
                      component={DateInput}
                      placeholder="From Data"
                      name="discount_expiry_date"
                    />
                  </Grid>{' '}
                </>
              ) : null}
              <Grid item sm={12} xs={12} className={classes.totalPrice}>
                Total Price(after discount) : {(price * (100 - discount)) / 100}
              </Grid>
              <Grid item sm={12} xs={12} align="right">
                <Grid item lg={12} md={12} xs={12} align="right">
                  {props.values.submitError ? (
                    <Typography color="error">{props.value.submitError}</Typography>
                  ) : null}
                </Grid>
                <Button className={classes.saveBtn} onClick={() => saveDraft(props.values)}>
                  {isDraft ? 'Edit Draft' : 'Save Draft'}
                </Button>
                <Button className={classes.publishBtn} type="submit">
                  {/* {isDraft && isEditing ? 'Save Bundle' : ''} */}
                  {/* {isEditing && !isDraft ? 'Edit Bundle' : 'Save Budle'} */}
                  Save Bundle
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </MuiThemeProvider>
  );
};
export default BundleForm;
