import { EDIT_CREATOR } from 'redux/types';

export default (state = null, action) => {
  switch (action.type) {
    case EDIT_CREATOR:
      return {
        ...state,
        creator: action,
      };
    default:
      return state;
  }
};
