import {
  FETCH_CUSTOMERS_START,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  EDIT_CUSTOMER,
  CUSTOMER_SELECTED,
  DELETE_CUSTOMER,
} from 'redux/types';
import { customerEndpoint } from 'utils/api';
import history from '../../../utils/history';

const fetchCustomersStart = () => {
  return {
    type: FETCH_CUSTOMERS_START,
  };
};

const fetchCustomersSuccess = (customers) => {
  return {
    type: FETCH_CUSTOMERS_SUCCESS,
    customers,
  };
};

const fetchCustomersFailure = (error) => {
  return {
    type: FETCH_CUSTOMERS_FAILURE,
    error,
  };
};

const fetchCustomerDetails = async () => {
  const response = await customerEndpoint.post();
  return response;
};

export const fetchCustomers = () => (dispatch) => {
  dispatch(fetchCustomersStart());
  return fetchCustomerDetails().then(
    (response) => dispatch(fetchCustomersSuccess(response.data)),
    (error) => dispatch(fetchCustomersFailure(error))
  );
};

export const selectCustomer = (id) => {
  return {
    type: CUSTOMER_SELECTED,
    id: id,
  };
};

export const deleteCustomer = (id) => {
  return async (dispatch) => {
    const response = await customerEndpoint.delete(`customer/${id}/`);
    dispatch({ type: DELETE_CUSTOMER, payload: response.data });
    history.push('/members');
  };
};

export const editCustomer = (id) => {
  return async (dispatch) => {
    const response = await customerEndpoint.get(`customer/${id}/`);
    dispatch({ type: EDIT_CUSTOMER, payload: response.data });
    history.push({
    pathname: '/register-member',
      prePopulate: response.data.data,
      populate: true,
    });
  };
};
