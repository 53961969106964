import {
  FETCH_COURSES_START,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAILURE,
  EDIT_COURSE,
  COURSE_SELECTED,
  DELETE_COURSE,
} from 'redux/types';
import { courseEndpoint } from 'utils/api';
import history from '../../../utils/history';

const fetchCoursesStart = () => {
  return {
    type: FETCH_COURSES_START,
  };
};

const fetchCoursesSuccess = (courses) => {
  return {
    type: FETCH_COURSES_SUCCESS,
    courses,
  };
};

const fetchCourseDetails = () => {
  const response = courseEndpoint.get();
  return response;
};

const fetchCoursesFailure = (error) => {
  return {
    type: FETCH_COURSES_FAILURE,
    error,
  };
};

export const fetchCourses = () => (dispatch) => {
  dispatch(fetchCoursesStart());
  return fetchCourseDetails().then(
    (response) => dispatch(fetchCoursesSuccess(response.data)),
    (error) => dispatch(fetchCoursesFailure(error))
  );
};

export const selectCourse = (id) => {
  return {
    type: COURSE_SELECTED,
    id: id,
  };
};

export const deleteCourse = (id) => {
  return async (dispatch) => {
    const response = await courseEndpoint.delete(`course/${id}/`);
    dispatch({ type: DELETE_COURSE, payload: response.data });
    history.push('/');
  };
};

export const editCourse = (id) => {
  return async (dispatch) => {
    const response = await courseEndpoint.get(`course/${id}/`);
    dispatch({ type: EDIT_COURSE, payload: response.data });
    history.push({
      pathname: 'add-course',
      prePopulate: response.data.data,
      populate: true,
    });
  };
};

export const editDraftCourse = (id) => {
  return async (dispatch) => {
    const response = await courseEndpoint.get(`course/${id}/draft`);
    dispatch({ type: EDIT_COURSE, payload: response.data });
    history.push({
      pathname: '/add-course',
      prePopulate: response.data.data,
      populate: true,
    });
  };
};
