import {
    FETCH_CREATORS_START,
    FETCH_CREATORS_SUCCESS,
    FETCH_CREATORS_FAILURE,
    EDIT_CREATOR,
    CREATOR_SELECTED,
    DELETE_CREATOR,
} from 'redux/types';
import { creatorEndpoint } from 'utils/api';
import history from '../../../utils/history';


const fetchCreatorsStart = () => {
    return {
        type: FETCH_CREATORS_START,
    };
};

const fetchCreatorsSuccess = (creators) => {
    return {
        type: FETCH_CREATORS_SUCCESS,
        creators,
    };
};

const fetchCreatorDetails = () => {
    const response = creatorEndpoint.get('content-creator/');
    return response;
};

const fetchCreatorsFailure = (error) => {
  return {
    type: FETCH_CREATORS_FAILURE,
    error,
  };
};


export const fetchCreators = () => (dispatch) => {
    dispatch(fetchCreatorsStart());
    return fetchCreatorDetails().then(
        (response) => dispatch(fetchCreatorsSuccess(response.data)),
        (error) => dispatch(fetchCreatorsFailure(error))
    );
};

export const selectCreator = (id) => {
    return {
      type: CREATOR_SELECTED,
      id: id,
    };
  };

  export const deleteCreator = (id) => {
    return async (dispatch) => {
      const response = await creatorEndpoint.delete(`content-creator/${id}/`);
      dispatch({ type: DELETE_CREATOR, payload: response.data });
      history.push('/Dashboard');
    };
  };

  export const editCreator= (id) => {
    return async (dispatch) => {
      const response = await creatorEndpoint.get(`content-creator/${id}/`);
      dispatch({ type: EDIT_CREATOR, payload: response.data });
      history.push({
        pathname: 'register-creator',
        prePopulate: response.data.data,
        populate: true,
      });
    };
  };
