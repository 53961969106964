import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PromoCodesList from './PromoCodesList';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.background.default,
    marginLeft: theme.spacing(18),
    marginRight: theme.spacing(18),
    [theme.breakpoints.down('sm')]: {
      margin: 30,
    },
  },
  formTitle: {
    marginBottom: theme.spacing(3),
  },
  formSubtitle: {
    fontSize: 16,
    marginBottom: 30,
    color: '#033342',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 85,
    },
  },
  btn: {
    padding: 10,
    float: 'right',
    borderRadius: 10,
    width: 161,
    height: 45,
    marginTop: -15,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      marginTop: 30,
    },
  },
}));

const PromoCodes = () => {
  const classes = useStyles();

  return (
    <>
      <main className={classes.main}>
        <Typography className={classes.formTitle} variant="h2">
          Promo Code Coupons
        </Typography>
        <Typography className={classes.formSubtitle} variant="h4">
          View and manage your promo codes coupons
          <span>
            <Button size="large" className={classes.btn} component={Link} to="/create-promo">
              + New Promo Code
            </Button>
          </span>
        </Typography>
        <PromoCodesList />
      </main>
    </>
  );
};

export default PromoCodes;
