import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  connectFormikAndMaterialUI,
  connectFormikAndMaterialUI1,
  connectFormikAndMaterialUI2,
  commonStyleAttributes,
  numberTypeFix,
} from './helper';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SearchIcon from '@material-ui/icons/Search';

import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    background: '#FFFFFF',
    borderRadius: 6,
    padding: 7,
    marginTop: '24px !important',
    boxShadow: '0px 3px 10px #00000017',
    whiteSpace: 'nowrap',
    '& input::placeholder': {
      fontSize: '16px',
      marginTop: '30px',
    },
  },
  inputRoot1: {
    background: '#FFFFFF',
    borderRadius: 6,
    padding: 7,
    marginTop: '24px !important',
    boxShadow: '0px 3px 10px #00000017',
    whiteSpace: 'nowrap',
    '& .MuiInputBase-input': {
      color: '#CECECE',
    },
  },
  labelRoot: {
    marginBottom: theme.spacing(4),
    fontWeight: 'bold',
    '&::placeholder': {
      fontSize: 16,
    },
  },
}));

export const TextInput = ({ field, form, ...props }) => {
  const classes = useStyles();
  if (props.type === 'number') {
    console.log('Please use <NumberInput /> for number-only input fields.');
  }
  return (
    <TextField
      {...field} // These two insert additional (and optional)
      {...props} // attributes from parent.
      {...connectFormikAndMaterialUI(props, form, field)}
      {...commonStyleAttributes}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
        },
      }}
    />
  );
};

const handleChange = (event, editor) => {
  const data = editor.getData();
};

// export const TextInputDetails = ({ field, form, ...props }) => {
//   //const classes = useStyles();
//   if (props.type === 'number') {
//     console.log('Please use <NumberInput /> for number-only input fields.');
//   }
//   return (
//     <CKEditor
//       editor={ClassicEditor}
//       onReady={(editor) => {
//         editor.editing.view.change((writer) => {
//           writer.setStyle('height', '100px', editor.editing.view.document.getRoot());
//         });
//       }}
//       config={{ placeholder: 'Details' }}
//       data={field.value}
//       onChange={handleChange}
//     />
//   );
// };

export const SelectInput = ({ field, form, ...props }) => {
  const classes = useStyles();
  if (props.type === 'number') {
    console.log('Please use <NumberInput /> for number-only input fields.');
  }
  return (
    <TextField
      id="standard-select-currency"
      select
      label="Select"
      // value={...props}
      // onChange={handleChange}
      helperText="Please select your currency"
      // placeholder="Enter Transshipment Ports"
      {...field}
      {...props}
      {...connectFormikAndMaterialUI(props, form, field)}
      {...commonStyleAttributes}
    >
      {props.categories.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const NumberInput = ({ field, form, ...props }) => {
  const classes = useStyles();
  if (props.type === 'text') {
    console.log("Please don't use <NumberInput /> for text input fields.");
  }
  return (
    <TextField
      placeholder="Enter Transshipment Ports"
      {...field}
      {...props}
      {...connectFormikAndMaterialUI(props, form, field)}
      {...commonStyleAttributes}
      type="number"
      onKeyDown={(event) => numberTypeFix(event)}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
        },
      }}
    />
  );
};

export const NumberInput1 = ({ setprice, field, form, ...props }) => {
  const classes = useStyles();
  if (props.type === 'text') {
    console.log("Please don't use <NumberInput /> for text input fields.");
  }
  return (
    <TextField
      placeholder="Enter Transshipment Ports"
      {...field}
      {...props}
      {...connectFormikAndMaterialUI1(props, form, field, setprice)}
      {...commonStyleAttributes}
      type="number"
      onKeyDown={(event) => numberTypeFix(event)}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
        },
      }}
    />
  );
};

export const NumberInput2 = ({ setdiscount, field, form, ...props }) => {
  const classes = useStyles();
  if (props.type === 'text') {
    console.log("Please don't use <NumberInput /> for text input fields.");
  }
  return (
    <TextField
      placeholder="Enter Transshipment Ports"
      {...field}
      {...props}
      {...connectFormikAndMaterialUI2(props, form, field, setdiscount)}
      {...commonStyleAttributes}
      type="number"
      onKeyDown={(event) => numberTypeFix(event)}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
        },
      }}
    />
  );
};

export const PercentageInput = ({ field, form, ...props }) => {
  const classes = useStyles();
  const { handleChange } = props;
  return (
    <TextField
      classes={{ root: classes.placeholder }}
      {...field}
      {...props}
      {...connectFormikAndMaterialUI(props, form, field)}
      {...commonStyleAttributes}
      onChange={handleChange}
      onKeyDown={(event) => numberTypeFix(event)}
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
        },
      }}
    />
  );
};

export const SearchInput = ({ field, form, ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      classes={{ root: classes.placeholder }}
      {...field}
      {...props}
      {...connectFormikAndMaterialUI(props, form, field)}
      {...commonStyleAttributes}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
        },
      }}
    />
  );
};

export const FileInput = ({ field, form, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      type="file"
      {...field} // These two insert additional (and optional)
      {...props} // attributes from parent.
      {...connectFormikAndMaterialUI(props, form, field)}
      {...commonStyleAttributes}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
        },
      }}
    />
  );
};

export const DateInput = ({ field, form, ...props }) => {
  const classes = useStyles();
  if (props.type === 'number') {
    console.log('Please use <NumberInput /> for number-only input fields.');
  }
  return (
    <TextField
      type="date"
      {...field} // These two insert additional (and optional)
      {...props} // attributes from parent.
      {...connectFormikAndMaterialUI(props, form, field)}
      {...commonStyleAttributes}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot1,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
        },
      }}
    />
  );
};
