import jwt_decode from 'jwt-decode';


export const isUserLoggedIn = () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const tokenPayload = jwt_decode(token);
    return Boolean(tokenPayload.user_id);
  } catch (error) {
    return false;
  }
};

export const getCurrentUserRole = () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const tokenPayload = jwt_decode(token);
    return tokenPayload.user_type;
  } catch (error) {
    return false;
  }
};

// export const isUserAdmin = () => true;
export const isUserAdmin = () => 'ADMINISTRATOR' === getCurrentUserRole();
