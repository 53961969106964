// Helper functions for form/components.js
// This file was created so that components.js is less busy.

// import * as lodash from 'lodash';

export const numberTypeFix = (event) => {
  // Setting type="number" behaves the way we want mostly. However, it counts
  // e as a number. It is a number, though not a number that we want the user to enter.
  if (event.key === 'e') {
    event.preventDefault();
  }
};

export const connectFormikAndMaterialUI = (props, form, field) => {
  // These props need to pass from Formik to Material-UI to
  // connect the two libraries.
  const name = field.name;

  return {
    label: props.label,
    // || lodash.capitalize(name),
    value: form.values[name],
    onChange: form.handleChange,
    error: form.touched[name] && Boolean(form.errors[name]),
    helperText: form.touched[name] && form.errors[name],
    id: name,
  };
};

export const connectFormikAndMaterialUI1 = (props, form, field, setprice) => {
  // These props need to pass from Formik to Material-UI to
  // connect the two libraries.
  const name = field.name;

  return {
    label: props.label,
    // || lodash.capitalize(name),
    value: setprice(form.values[name]),
    onChange: form.handleChange,
    error: form.touched[name] && Boolean(form.errors[name]),
    helperText: form.touched[name] && form.errors[name],
    id: name,
  };
};

export const connectFormikAndMaterialUI2 = (props, form, field, setdiscount) => {
  // These props need to pass from Formik to Material-UI to
  // connect the two libraries.
  const name = field.name;

  return {
    label: props.label,
    // || lodash.capitalize(name),
    value: setdiscount(form.values[name]),
    onChange: form.handleChange,
    error: form.touched[name] && Boolean(form.errors[name]),
    helperText: form.touched[name] && form.errors[name],
    id: name,
  };
};

export const commonStyleAttributes = {
  // These are subject to change when changing the design of the
  // app. For example, maybe you don't want the inputs to be outlined.
  style: { minHeight: 45, marginTop: -15 },
  fullWidth: true,
};
