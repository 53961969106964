import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { makeStyles, Button, Grid, Typography } from '@material-ui/core';
import { TextInput, TextInputDetails } from 'utils/form/components';
import * as validations from 'utils/form/validations';
import * as Yup from 'yup';
import { customerEndpoint } from 'utils/api';
import Alert from '@material-ui/lab/Alert';
import avatar from '../../assets/images/avatar.jpeg';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(18),
    marginRight: theme.spacing(18),
    [theme.breakpoints.down('sm')]: {
      margin: 30,
    },
  },
  register: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: 12,
    borderRadius: 10,
  },
  formTitle: {
    fontSize: 22,
    marginBottom: theme.spacing(3),
    color: '#033342',
  },
  placeholderImg: {
    width: 'auto',
    height: 170,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background: '#BCBCBC',
    borderRadius: 10,
    marginTop: 8,
  },
  label: {
    backgroundColor: '#F5F7F7',
    color: 'black',
    cursor: 'pointer',
    marginTop: 10,
    padding: 7,
    borderRadius: 6,
    maxWidth: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileChosen: {
    marginLeft: 15,
    backgroundColor: 'white',
    color: '#73ABBC',
    width: '110px',
    maxWidth: '110px',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      width: '90px',
      maxWidth: '90px',
      padding: 0,
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-block',
    },
    [theme.breakpoints.down('md')]: {
      width: '70px',
      maxWidth: '90px',
    },
  },
  saveBtn: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    padding: 13,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: '10px',
    background: '#087090',
    marginRight: 10,
    whiteSpace: 'nowrap',
    width: 160,
  },
  avatar: {
    borderRadius: '50%',
    width: '78px',
    height: '80px',
    marginBottom: '-17px',
  },
  wrapLabelImg: {
    border: '20px',
    borderRadius: '6px',
    padding: '14px',
    background: 'white',
    marginTop: '10px',
    boxShadow: '0px 3px 10px #00000017',
  },
  captionImg: {
    paddingTop: 10,
    [theme.breakpoints.down('md')]: {
      marginLeft: 30,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 80,
    },
  },
  imgPreview: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const CreatorForm = (props) => {
  const classes = useStyles();

  const [alert, setAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [imgName, setImgName] = useState('');
  const [file, setFile] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [error, setError] = useState(false);

  const initialValues = {
    username: props.location.prePopulate ? props.location.prePopulate.user.username : '',
    profession: props.location.prePopulate ? props.location.prePopulate.profession : '',
    firstName: props.location.prePopulate ? props.location.prePopulate.user.first_name : '',
    lastName: props.location.prePopulate ? props.location.prePopulate.user.last_name : '',
    email: props.location.prePopulate ? props.location.prePopulate.user.email : '',
    logo: props.location.prePopulate ? props.location.prePopulate.logo : '',
    details: props.location.prePopulate ? props.location.prePopulate.details : '',
  };

  const validationSchema = Yup.object({
    username: validations.stringIsRequired('Username'),
    firstName: validations.stringIsRequired('First name'),
    lastName: validations.stringIsRequired('Last name'),
    email: validations.email,
  });

  useEffect(() => {
    if (props.location.prePopulate) {
      setIsEditing(true);
      if (props.location.prePopulate.logo !== null && props.location.prePopulate.logo !== '')
        setImgName(props.location.prePopulate.logo.split('logo/')[1]);
    } else {
    }
  }, [props.location.prePopulate]);

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImgName(file.name);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('logo', file);
      formData.append('first_name', values.firstName);
      formData.append('last_name', values.lastName);
      formData.append('email', values.email);
      formData.append('username', values.username);
      formData.append('profession', values.profession);
      formData.append('details', values.details);
      if (props.location.prePopulate) {
        await customerEndpoint
          .patch(`content-creator/${props.location.prePopulate.id}/`, formData)
          .then((res) => {
            if (res.status === 200) {
              setAlert(true);
            }
          });
      } else {
        await customerEndpoint.post('content-creator/', formData).then((res) => {
          if (res.status === 201) {
            setAlert(true);
          }
          if (res.status === 400) {
            setAlert(true);
            setErrorMsg(res.statusText);
          }
        });
      }
    } catch (error) {
      console.log(error);
      setErrorMsg(error.response.statusText);
      setError(true);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} className={classes.form}>
          {error ? <Alert severity="error">{errorMsg}</Alert> : null}
          {alert ? (
            <Alert severity="success">
              {isEditing
                ? 'Content Creator edited successfully'
                : 'Content creator registered successfully'}
            </Alert>
          ) : null}
          <Typography className={classes.formTitle} variant="h1">
            {isEditing ? 'Edit Content Creator' : 'New Content Creator'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Field component={TextInput} name="username" placeholder="Username" />
            </Grid>
            <Grid item sm={2} xs={12}>
              <Field component={TextInput} name="firstName" placeholder="First Name" />
            </Grid>
            <Grid item sm={2} xs={12}>
              <Field component={TextInput} name="lastName" placeholder="Last Name" />
            </Grid>
            <Grid item sm={3} xs={12}>
              <div className={classes.imgPreview}>
                {imagePreviewUrl ? (
                  <img alt="preview" className={classes.avatar} src={imagePreviewUrl} />
                ) : (
                  <Grid item sm={1} xs={2}>
                    <img
                      src={initialValues.logo ? initialValues.logo : avatar}
                      alt="placeholder"
                      className={classes.avatar}
                    />
                  </Grid>
                )}
                <Typography variant="body2" className={classes.captionImg}>
                  Recommended photo size 120 x 120 px. Maximum 10 MB file size.
                </Typography>
              </div>
            </Grid>

            <Grid item sm={4} xs={12}>
              <Field component={TextInput} name="profession" placeholder="Profession Field" />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Field component={TextInput} name="email" placeholder="Email" />
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className={classes.wrapLabelImg}>
                <label className={classes.label}>
                  <input
                    type="file"
                    id="actual-btn"
                    accept="image/*"
                    hidden
                    onChange={(e) => handleImageChange(e)}
                  />
                  Choose picture
                </label>
                <span className={classes.fileChosen} title={imgName}>
                  {imgName !== '' ? imgName : 'No Image Selected'}
                </span>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Field
                name="details"
                render={({ field, form }) => {
                  return (
                    <>
                      <CKEditor
                        editor={ClassicEditor}
                        data={field.value}
                        onChange={(event, editor) => {
                          form.setFieldValue(field.name, editor.getData());
                        }}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              'height',
                              '100px',
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        config={{ placeholder: 'Details' }}
                      />
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} align="right">
              <Grid item lg={12} md={12} xs={12}>
                {props.values.submitError ? (
                  <Typography color="error">{props.value.submitError}</Typography>
                ) : null}
              </Grid>
              <Button className={classes.register} type="submit">
                {!isEditing ? 'Save Content Creator' : 'Edit Content Creator'}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreatorForm;
