import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import CategoryList from './components/CategoryList';
import CoursesList from './components/CoursesList';
import BundlesList from './components/BundlesList';


const useStyles = makeStyles((theme) => ({
  formTitle: {
    marginBottom: theme.spacing(4),
    fontSize: 25,
  },
  main: {
    height: 'auto',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    marginLeft: 60,
    marginRight: 60,
  },
}));


const Content = () => {
  const classes = useStyles();
  return (
      <main className={classes.main}>
        <Typography className={classes.formTitle} variant="h2">
          Content
        </Typography>
        <CategoryList />
        <CoursesList />
        <BundlesList />
      </main>
  );
};


export default Content;
