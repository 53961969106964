import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { PreviewWidget } from 'components';
import { courseEndpoint } from 'utils/api';

import Header from './shared/Header';
import ViewAllLink from './shared/ViewAllLink';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { editBundle, editDraftBundle } from 'redux/actions/bundles/fetchBundleActions';

const BundlesList = () => {
  const [bundles, setBundles] = React.useState([]);
  const selectedCat = useSelector((state) => state.category.category);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const draftBundles = await (await courseEndpoint.get('bundle/draft')).data.data;
      const response = await (await courseEndpoint.get('bundle/')).data.data.concat(draftBundles);
      setBundles(response);
    }
    fetchData();
  }, []);

  const deleteBundle = async (id) => {
    try {
      await courseEndpoint.delete(`bundle/${id}/`);
      window.location.reload();
    } catch (error) {
      console.log('Delete Course API error!');
    }
  };

  const editedBundle = (id) => {
    dispatch(editBundle(id));
  };

  const editedDraftBundle = (id) => {
    dispatch(editDraftBundle(id));
  };

  return (
    <React.Fragment>
      <Header title="Bundles" buttonText="+ New Bundle" to="add-bundle" />
      <Grid container spacing={2}>
        {bundles
          .filter((bundle) => {
            if (selectedCat) {
              return bundle.category.title === selectedCat;
            }
            return true;
          })
          .map((bundle, i) => {
            if (i < 8) {
              return (
                <Grid key={i} item md={3} sm={6} xs={12}>
                  <PreviewWidget
                    key={i}
                    title={bundle.title}
                    description={bundle.description}
                    price={bundle.price}
                    image={bundle.promo_image}
                    draft={bundle.is_draft}
                    delete={() => deleteBundle(bundle.id)}
                    edit={() =>
                      bundle.is_draft === true
                        ? editedDraftBundle(bundle.id)
                        : editedBundle(bundle.id)
                    }
                  />
                </Grid>
              );
            }
          })}
      </Grid>
      <ViewAllLink link="bundles" />
    </React.Fragment>
  );
};

export default BundlesList;
